import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {
  Button,
  Card,
  CardBody,
  CardHead,
  CardMenu,
  Confirm,
  Icons,
  Text,
  Title,
  Wrapper
} from 'components';
import {TemplateEdit, TemplateEditValues} from '../components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useIntegrationId} from './hooks';

interface Template {
  index: number;
  template: AppEnv.Template;
}

const Template = ({index, template}: Template) => {
  const {setNotification} = useAppContext();
  const {setUser} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  const {
    fetchTemplatesDelete,
    fetchTemplatesFileDelete,
    fetchTemplatesFiles,
    postFilesLoadData,
    postTemplatesFileAdd,
    postTemplatesUpdate
  } = useRequest();

  const integrationId = useIntegrationId(template);
  const {t} = useTranslation();

  const deleteTemplate = async () => {
    const res = await fetchTemplatesDelete(integrationId, template.id);
    if (!res?.success) return true;

    setUser(prevValue => upd(prevValue, {templates: {$splice: [[index, 1]]}}));
    setNotification({text: t`Template deleted.`, title: template.title});

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteTemplate();
    setIsConfirmActive(false);
  };

  const getFiles = async () => {
    const res = await fetchTemplatesFiles(integrationId, template.id);

    setUser(prevValue =>
      upd(prevValue, {templates: {[index]: {files: {$set: res || []}}}})
    );
  };

  const handleEdit = () => {
    setIsEditActive(true);

    if (template.files) return;
    getFiles();
  };

  const handleSubmit = async (values: TemplateEditValues) => {
    const res = await postTemplatesUpdate(integrationId, template.id, values);
    if (!res) return true;

    const files: AppEnv.TemplateFile[] = [];

    const promises = values.attachs.map(async attach => {
      if (attach.templateFile) {
        if (attach.isDelete) {
          const {templateFile} = attach;
          if (!templateFile) return;

          const del = await fetchTemplatesFileDelete(
            integrationId,
            template.id,
            templateFile.id
          );

          if (del?.success) return;
          files.push(attach.templateFile);
        } else {
          files.push(attach.templateFile);
        }
      } else if (attach.file) {
        const {file} = attach;

        const link = await postFilesLoadData(file, file.name);
        if (!link) return;

        const templateFile = await postTemplatesFileAdd(
          integrationId,
          template.id,
          {
            link,
            title: file.name,
            type: file.type
          }
        );

        if (!templateFile) return;
        files.push(templateFile);
      }
    });

    await Promise.all(promises);

    setUser(prevValue =>
      upd(prevValue, {
        templates: {[index]: {$set: {...res, file_count: files.length, files}}}
      })
    );

    setIsEditActive(false);
    setNotification({text: t`Template edited.`, title: res.title});

    return true;
  };

  const fileCount = template.file_count || 0;
  const isActive = isConfirmActive || isEditActive;

  const message = template.message.replace(
    /%([^%]+)%/g,
    '<span class="text_color-blue">%$1%</span>'
  );

  return (
    <Card isActive={isActive}>
      <CardHead>
        <Wrapper alignItems="center" gap={8} noWrap style={{minWidth: 0}}>
          <Icons.Note />
          <Title.H3 isNoWrap>{template.title}</Title.H3>
        </Wrapper>

        {fileCount > 0 && (
          <Wrapper alignItems="center" noWrap>
            <Icons.PaperClip color="blue" size={16} />

            <Text color="blue" size={16}>
              {template.file_count}
            </Text>
          </Wrapper>
        )}
      </CardHead>

      <CardBody>
        <Text dangerouslySetInnerHTML={{__html: message}} isPre></Text>
      </CardBody>

      <CardMenu>
        <Button color="transparent" onClick={handleEdit}>
          <Icons.PencilSimple size={20} />
          {t`Edit`}
        </Button>

        <Button color="transparent" onClick={() => setIsConfirmActive(true)}>
          <Icons.TrashSimple size={20} />
        </Button>
      </CardMenu>

      <Confirm
        isActive={isConfirmActive}
        onClick={handleClick}
        title={t`Delete this template?`}
      />

      <TemplateEdit
        isActive={isEditActive}
        onSubmit={handleSubmit}
        setIsActive={setIsEditActive}
        template={template}
      />
    </Card>
  );
};

export default Template;
