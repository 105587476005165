import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useChatSpare} from '../../hooks';
import {useConnectionsMainContext} from '../../connections-main-context';
import * as AppEnv from 'app-env';

import Label from './label/label';
import Name from './name/name';

interface Values {
  label: string;
  name: string;
}

interface Config {
  isConfigActive: boolean;
  setIsConfigActive: AppEnv.SetState<boolean>;
  setVersion: AppEnv.SetState<AppEnv.InstanceVersion | undefined>;
  version: AppEnv.InstanceVersion;
}

const Config = ({
  isConfigActive,
  setIsConfigActive,
  setVersion,
  version
}: Config) => {
  const {setConnectorId} = useConnectionsMainContext();

  const {t} = useTranslation();
  const chatSpare = useChatSpare();

  const handleClick = async () => {
    setIsConfigActive(false);
    await new Promise(resolve => setTimeout(resolve, 150));
    setVersion(undefined);
  };

  const handleSubmit = async (values: Values) => {
    const res = await chatSpare(version, values);
    if (!res) return true;

    setIsConfigActive(false);
    setConnectorId(res.id);
    return true;
  };

  const initialValues: Values = {label: '', name: ''};

  return (
    <Popup
      width={408}
      isActive={isConfigActive}
      onClick={() => setIsConfigActive(false)}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>
              {t`Initial settings`}
            </Title.H3>

            <Wrapper direction="column" gap={16}>
              <Label />
              {version == 'chat' && <Name />}
            </Wrapper>
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Continue`}</SaveButton>

              <Button color="fade" onClick={handleClick}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Config;
