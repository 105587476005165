import React from 'react';
import ReactAvatar from 'react-avatar';
import cn from 'classnames';

import {useDispatchContext} from '../../dispatch-context';

interface ProfileImage {
  id?: number | string | null;
  name?: string | null;
  size?: 36 | 40 | 112;
  src?: string | null;
  tag?: 'online' | 'version';
  visibility?: 'hidden';
}

const colors = [
  '#c2d2ff',
  '#abdcff',
  '#edc8f7',
  '#ffcfbf',
  '#93edbf',
  '#c2d2ff',
  '#abdcff',
  '#edc8f7',
  '#ffcfbf',
  '#93edbf'
];

const getColor = (str: number | string) => {
  let normalizedStr = str;

  if (typeof normalizedStr == 'string' && !parseInt(normalizedStr)) {
    const encoder = new TextEncoder();
    normalizedStr = encoder.encode('Hello').join('');
  }

  const index = parseInt(normalizedStr.toString().slice(-1));
  return colors[index];
};

const ProfileImage = ({
  id,
  name,
  size = 40,
  src,
  tag,
  visibility
}: ProfileImage) => {
  const {instance} = useDispatchContext();
  const color = getColor(id || 0);

  const htmlSrc =
    src ||
    (name?.[0] == '+' ? '/static/img/profile-image/user.svg' : undefined);

  return (
    <div
      className={cn('profile-image', {
        'profile-image_online': tag == 'online',
        [`profile-image_${instance.version}`]: tag == 'version'
      })}
      style={{background: color, height: size, visibility, width: size}}
    >
      <ReactAvatar
        className="profile-image__avatar"
        color={color}
        maxInitials={2}
        name={name || undefined}
        size={size?.toString()}
        src={htmlSrc}
      />
    </div>
  );
};

export {default as useProfileImage} from './hooks/use-profile-image';
export default ProfileImage;
