import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'app-context';

export interface NavItemParams {
  label: string;
  value: string;
}

interface NavItem {
  isActive: boolean;
  navItem: NavItemParams;
}

const NavItem = ({isActive, navItem}: NavItem) => {
  const {isIframe} = useAppContext();

  return (
    <Link
      className={cn('nav__item', {nav__item_active: isActive})}
      replace={isIframe}
      to={navItem.value}
    >
      {navItem.label}
    </Link>
  );
};

export default NavItem;
