import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Summa {
  tariff: AppEnv.Tariff;
}

const Summa = ({tariff}: Summa) => {
  const {user} = useWorkspaceContext();
  const {i18n} = useTranslation();

  const {integration} = user;
  const summa = integration.is_review ? tariff.summa_review : tariff.summa;

  return (
    <Title.H3>
      {parseFloat(summa).toLocaleString(i18n.resolvedLanguage, {
        currency: tariff.currency,
        style: 'currency'
      })}
    </Title.H3>
  );
};

export default Summa;
