import React from 'react';
import {useTranslation} from 'react-i18next';

import {MessageText, UserMessage} from '../components';
import * as AppEnv from 'app-env';

import ChatMessageContext from './chat-message-context';
import File from './file/file';
import Message from './message/message';

interface ChatMessage {
  message: AppEnv.ChatMessage;
}

const ChatMessage = ({message}: ChatMessage) => {
  const {t} = useTranslation();

  return (
    <ChatMessageContext.Provider value={{message}}>
      {message.type == 'file' ? (
        <File />
      ) : message.type == 'message' ? (
        <Message />
      ) : (
        <UserMessage>
          <MessageText isLight>
            {t`The message is not supported on this version.`}
          </MessageText>
        </UserMessage>
      )}
    </ChatMessageContext.Provider>
  );
};

export default ChatMessage;
