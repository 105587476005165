import React from 'react';
import {Wrapper} from 'components';

import InstanceChatKey from './instance-chat-key/instance-chat-key';
import WorkSchedule from './work-schedule/work-schedule';

const General = () => (
  <Wrapper direction="column" gap={16}>
    <InstanceChatKey />
    <WorkSchedule />
  </Wrapper>
);

export default General;
