import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text, Title, Wrapper} from 'components';

import Bottom from './bottom/bottom';
import Right from './right/right';

const Position = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Margins`}</Title.H3>
      <Text size={16}>{t`Desktop version`}</Text>

      <Text size={16} style={{marginBottom: 8}}>
        {t`Defaults to the right 40px, bottom 40px`}
      </Text>

      <Wrapper gap={8}>
        <Bottom />
        <Right />
      </Wrapper>
    </div>
  );
};

export default Position;
