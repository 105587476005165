import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from 'components';
import * as AppEnv from 'app-env';

interface Quantity {
  tariff: AppEnv.Tariff;
}

const Quantity = ({tariff}: Quantity) => {
  const {t} = useTranslation();

  return (
    <Text fontWeight={600} style={{marginBottom: 8}}>
      {tariff.quantity == 30
        ? t`30 days`
        : tariff.quantity == 3
          ? t`3 months`
          : tariff.quantity == 6
            ? t`6 months`
            : tariff.quantity == 12
              ? t`12 months`
              : tariff.quantity}
    </Text>
  );
};

export default Quantity;
