import React, {useEffect, useRef, useState} from 'react';
import {formatPhoneNumber} from 'whatcrm-core';

import {MessageText, UserMessage} from '../../components';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest, useIsVisible} from 'common/hooks';
import {useTelegramMessageContext} from '../telegram-message-context';
import {Wrapper} from 'components';
import * as AppEnv from 'app-env';
import ProfileImage from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';

const Contact = () => {
  const {instance} = useDispatchContext();
  const {message} = useTelegramMessageContext();

  const [contact, setContact] = useState<AppEnv.TelegramUser | null>();
  const [profileImage, setProfileImage] = useState<string>();

  const {fetchRemoteFile, fetchUser} = useRequest();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);

  const getProfileImage = async (id: string) => {
    const res = await fetchRemoteFile(instance.chat_key, id);

    res?.path
      ? setProfileImage(res.path)
      : setTimeout(() => getProfileImage(id), 1000);
  };

  const getContact = async () => {
    if (!message.content.contact?.userId) return;

    const res = await fetchUser(
      instance.chat_key,
      message.content.contact.userId
    );

    setContact(res || null);
    if (res?.profilePhoto) getProfileImage(res.profilePhoto.small.remote.id);
  };

  useEffect(() => {
    if (isVisible && contact == undefined) getContact();
  }, [isVisible]);

  const name = [
    message.content.contact?.firstName,
    message.content.contact?.lastName
  ].join(' ');

  const src =
    profileImage ||
    (contact?.profilePhoto
      ? `data:image/png;base64,${contact.profilePhoto.minithumbnail.data}`
      : undefined);

  return (
    <UserMessage>
      <div ref={ref}>
        <Wrapper alignItems="center" gap={8}>
          <ProfileImage name={name} src={src} />

          <div>
            <MessageText>{name}</MessageText>

            {message.content.contact?.phoneNumber && (
              <MessageText isLight isSmall>
                {formatPhoneNumber(message.content.contact.phoneNumber)}
              </MessageText>
            )}
          </div>
        </Wrapper>
      </div>
    </UserMessage>
  );
};

export default Contact;
