import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

import {Input} from 'components';

const Username = () => {
  const [field, meta, helpers] = useField<string>('username');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      autoCapitalize="off"
      placeholder={t`Username`}
      isValid={isValid}
      onChange={helpers.setValue}
    />
  );
};

export default Username;
