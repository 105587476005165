import React from 'react';
import {IconParams, Icons, Text} from 'components';

export interface ContentParams {
  href?: string;
  label?: string;
  type?: 'ban' | 'copy';
  value: string;
}

const Content = ({href, label, type, value}: ContentParams) => {
  const params: IconParams = {className: 'profile__item-icon', size: 24};

  return (
    <>
      <div>
        {label && <Text size={12}>{label}</Text>}

        <Text size={16} fontWeight={type != 'ban' ? 600 : undefined}>
          {value}
        </Text>
      </div>

      {href ? (
        <Icons.ArrowSquareOut {...params} />
      ) : (
        type == 'copy' && <Icons.Files {...params} />
      )}
    </>
  );
};

export default Content;
