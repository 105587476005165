import React from 'react';

import {Text, TextParams} from 'components';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {getStyledText} from 'common/actions';

interface MessageText {
  children: React.ReactNode;
  isBold?: boolean;
  isLight?: boolean;
  isSmall?: boolean;
  noWrap?: boolean;
  style?: React.CSSProperties;
}

const MessageText = ({
  children,
  isBold,
  isLight,
  isSmall,
  noWrap,
  style
}: MessageText) => {
  const {instance} = useDispatchContext();

  const params: TextParams = {
    className: 'message__text',
    color: isLight ? 'light' : undefined,
    fontWeight: isBold ? 500 : undefined,
    isNoWrap: noWrap,
    size: !isSmall ? 16 : undefined,
    style
  };

  return (
    <>
      {typeof children == 'string' ? (
        <Text
          {...params}
          dangerouslySetInnerHTML={{
            __html: getStyledText(children, instance.version, true)
          }}
        />
      ) : (
        <Text {...params}>{children}</Text>
      )}
    </>
  );
};

export default MessageText;
