import React from 'react';

import {Text} from 'components';
import UserMessage from '../../components/user-message/user-message';
import {useChatMessageContext} from '../chat-message-context';
import {getIsEmoji} from 'common/actions';

const Message = () => {
  const {message} = useChatMessageContext();
  const isEmoji = getIsEmoji(message.content);

  return (
    <UserMessage>
      {isEmoji ? <Text size={16}>{message.content}</Text> : message.content}
    </UserMessage>
  );
};

export default Message;
