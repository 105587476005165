import React from 'react';
import {Navigate} from 'react-router-dom';

import * as AppEnv from 'app-env';

import AuthContext from './auth-context';
import Main from './main/main';
import Side from './side/side';

interface Auth {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
  user: AppEnv.User | null;
}

const Auth = ({setUser, user}: Auth) => (
  <AuthContext.Provider value={{setUser}}>
    {user ? (
      <Navigate to="/" />
    ) : (
      <div className="auth">
        <div className="auth__wrapper">
          <Side />
          <Main />
        </div>
      </div>
    )}
  </AuthContext.Provider>
);

export default Auth;
