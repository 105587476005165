import React from 'react';
import {useField} from 'formik';

import {Chips, Icons} from 'components';
import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

interface Option {
  icon: React.ReactNode;
  label: string;
  value: AppEnv.InstanceVersion;
}

const Version = () => {
  const [field, , helpers] = useField<AppEnv.InstanceVersion>('version');
  const {t} = useTranslation();

  const options: Option[] = [
    {icon: <Icons.WhatsApp />, label: 'WhatsApp', value: 'whatcrm'},
    {icon: <Icons.Telegram />, label: 'Telegram', value: 'telegram'},
    {icon: <Icons.Avito />, label: t`Avito`, value: 'avito'},
    {icon: <Icons.CallCenterOperator />, label: 'Chat', value: 'chat'}
  ];

  return <Chips {...field} options={options} onChange={helpers.setValue} />;
};

export default Version;
