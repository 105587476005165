import React, {useEffect, useRef, useState} from 'react';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/uk';
import {useCountries, useTheme, useTtag} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Notification, Preloader, Tone} from 'components';
import {useGetFfmpeg} from 'common/hooks';
import {useIframeList, useUser} from 'app/hooks';
import * as AppEnv from 'app-env';
import AppContext from 'app-context';
import Router from 'Router';

interface UrlParams {
  chat_id?: string;
  code?: string;
  domain?: string;
  email?: string;
  key?: string;
  phone_numbers?: string;
  theme?: string;
  user?: string;
  usernames?: string;
  version?: string;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const urlParams: UrlParams = Object.fromEntries(urlSearchParams.entries());

const App = () => {
  const [isCanPlayOgg, setIsCanPlayOgg] = useState(false);
  const [notification, setNotification] = useState<AppEnv.Notification>();

  const [user, setUser] = useUser();
  const {chatIdList, phoneNumberList, usernameList} = useIframeList();
  const {i18n} = useTranslation();
  const {theme, setTheme} = useTheme();
  const getFfmpeg = useGetFfmpeg();
  const toneRef = useRef<HTMLAudioElement>(null);
  useCountries(i18n.resolvedLanguage);
  useTtag(i18n.resolvedLanguage || 'en');

  useEffect(() => {
    const audio = new Audio();
    const canPlayType = audio.canPlayType('audio/ogg; codecs=opus');

    setIsCanPlayOgg(!!canPlayType);
  }, []);

  useEffect(() => {
    if (!navigator || !('serviceWorker' in navigator)) return;
    navigator.serviceWorker.register('/service-worker.js');
  }, []);

  useEffect(() => {
    const {theme} = urlParams;

    if (!theme) return;
    else if (theme == '0') setTheme('system');
    else if (theme == '1') setTheme('light');
    else if (theme == '2') setTheme('dark');
  }, []);

  const isIframe = !!(chatIdList || phoneNumberList || usernameList);
  moment.locale(i18n.resolvedLanguage);

  return (
    <>
      {typeof user == 'object' ? (
        <AppContext.Provider
          value={{
            chatIdList,
            getFfmpeg,
            isCanPlayOgg,
            isIframe,
            phoneNumberList,
            setNotification,
            setTheme,
            theme,
            toneRef,
            usernameList
          }}
        >
          <div className="app">
            <Notification notification={notification} />
            <Router setUser={setUser} user={user} />
            <Tone ref={toneRef} />
          </div>
        </AppContext.Provider>
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default App;
