import React, {useId} from 'react';
import {Text} from 'components';

interface Checkbox {
  comment?: string;
  label: React.ReactNode;
  name: string;
  onChange: (arg: boolean) => void;
  style?: React.CSSProperties;
  value: boolean | 0 | 1;
}

const Checkbox = ({comment, label, name, onChange, style, value}: Checkbox) => {
  const id = useId();

  return (
    <label className="checkbox" htmlFor={id} style={style}>
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={!!value}
        hidden
        onChange={e => onChange(e.target.checked)}
      />

      <div className="checkbox__btn" />

      <div className="checkbox__wrapper">
        <Text size={16}>{label}</Text>

        {comment && (
          <Text color="secondary" size={12}>
            {comment}
          </Text>
        )}
      </div>
    </label>
  );
};

export default Checkbox;
