import React from 'react';
import {useFormikContext} from 'formik';
import {useOnKeyDown} from 'whatcrm-core';

import {Wrapper} from 'components';
import {useWhatsAppMessageContext} from '../../../whatsapp-message-context';
import {Values} from '../listing-env';

import Section from './section/section';

interface SectionList {
  isListActive: boolean;
  onSubmit: () => void;
}

const SectionList = ({isListActive, onSubmit}: SectionList) => {
  const {message} = useWhatsAppMessageContext();
  const {isSubmitting, submitForm, values} = useFormikContext<Values>();

  useOnKeyDown(
    e => {
      if (e.code == 'Enter' || e.code == 'NumpadEnter') submitForm();
    },
    [isListActive, !isSubmitting, values.button]
  );

  return (
    <Wrapper direction="column" gap={16}>
      {message._data.list?.sections.map((item, i) => (
        <Section key={i} section={item} onSubmit={onSubmit} />
      ))}
    </Wrapper>
  );
};

export default SectionList;
