import React, {useEffect} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Button, Icons, Text, Vertical, Wrapper} from 'components';
import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useGetMessagePreview, useGetMessageSenderName} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getStyledText} from 'common/actions';

const Reply = () => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg, setQuotedMsg} = useChatContext();

  const [, , helpers] = useField<string>('text');
  const {t} = useTranslation();
  const getMessagePreview = useGetMessagePreview();
  const getMessageSenderName = useGetMessageSenderName();

  const messagePreview = getMessagePreview(quotedMsg?.message);

  useEffect(() => {
    if (!messagePreview || !quotedMsg?.isEditing) return;

    const text = getText();
    helpers.setValue(text);
  }, [quotedMsg]);

  const getText = () => {
    const text = messagePreview || '';
    if (!user.integration.is_manager) return text;

    const index = text.indexOf(':\n');
    if (index < 0) return text;

    return text.slice(index + 2);
  };

  const handleClick = () => {
    if (quotedMsg?.isEditing) helpers.setValue('');
    setQuotedMsg(undefined);
  };

  const messageSenderName = quotedMsg?.message
    ? getMessageSenderName(dialog, quotedMsg?.message)
    : undefined;

  return (
    <div className="footer__reply">
      <Wrapper alignItems="center" gap={12} style={{overflow: 'hidden'}} noWrap>
        {quotedMsg?.isEditing ? (
          <Icons.PencilSimple />
        ) : (
          <Icons.ArrowBendUpLeft />
        )}

        <Vertical color="gray" />

        <div style={{overflow: 'hidden'}}>
          <Text color="black" size={12} fontWeight={600}>
            {quotedMsg?.isEditing
              ? t`Edit`
              : t('Reply to {{messageSenderName}}', {messageSenderName})}
          </Text>

          <Text
            className="footer__reply-text"
            color="black"
            dangerouslySetInnerHTML={{
              __html: getStyledText(messagePreview || '', instance.version)
            }}
            size={12}
            isNoWrap
          />
        </div>
      </Wrapper>

      <Button color="transparent" onClick={handleClick}>
        <Icons.CircleClose />
      </Button>
    </div>
  );
};

export default Reply;
