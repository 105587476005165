import React, {useEffect, useRef, useState} from 'react';
import {findPosition, useOnClickOutside} from 'whatcrm-core';
import cn from 'classnames';

import {Text} from 'components';
import * as AppEnv from 'app-env';

interface Position {
  top: number;
  left: number;
}

export interface ContextMenuOption {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}

interface TriggerPosition {
  left: number;
  top: number;
}

interface ContextMenu {
  children?: React.ReactNode;
  isActive: boolean;
  isCustomPosition?: boolean;
  options: ContextMenuOption[];
  setIsActive: AppEnv.SetState<boolean>;
  triggerPosition?: TriggerPosition;
  triggerRef?: React.RefObject<HTMLElement>;
}

const ContextMenu = ({
  children,
  isActive,
  isCustomPosition,
  options,
  setIsActive,
  triggerPosition,
  triggerRef
}: ContextMenu) => {
  const [position, setPosition] = useState<Position | undefined>(undefined);

  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(targetRef, {
    onClick: () => setIsActive(false),
    trigger: triggerRef
  });

  useEffect(() => {
    if (!isActive || isCustomPosition) return;

    const res = findPosition(targetRef, {containerRef, triggerPosition});
    setPosition(res);
  }, [isActive]);

  const handleClick = (onClick: () => void) => {
    setIsActive(false);
    onClick();
  };

  return (
    <div className="context-menu" ref={containerRef}>
      <div
        className={cn('context-menu__wrapper', {
          'context-menu__wrapper_active': isActive
        })}
        ref={targetRef}
        style={position}
      >
        {children}

        <div className="context-menu__list">
          {options.map((item, i) => (
            <div
              className="context-menu__item"
              key={i}
              onClick={() => handleClick(item.onClick)}
            >
              {item.icon}
              <Text>{item.label}</Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;
