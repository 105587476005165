import React from 'react';

import {MessageText} from '../../../components';
import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

interface Product {
  product: AppEnv.WhatsAppOrderProduct;
}

const Product = ({product}: Product) => {
  const {i18n, t} = useTranslation();

  return (
    <div>
      <MessageText>{product.name}</MessageText>

      <MessageText isLight>
        {typeof product.price == 'number' && (
          <>
            {(product.price / 1000).toLocaleString(i18n.resolvedLanguage, {
              currency: product.currency || undefined,
              style: product.currency ? 'currency' : undefined
            })}

            {' • '}
          </>
        )}

        {product.quantity}
        {' '}
        {t`pc.`}
      </MessageText>
    </div>
  );
};

export default Product;
