import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Toggle} from 'components';

const CallbackIsMessage = () => {
  const [field, , helpers] = useField<boolean>('callback_is_message');
  const {t} = useTranslation();

  return (
    <Toggle
      {...field}
      label={t`Message entry field`}
      onChange={helpers.setValue}
    />
  );
};

export default CallbackIsMessage;
