import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const Label = () => {
  const [field, , helpers] = useField<string>('label');
  const {t} = useTranslation();

  return (
    <Input
      {...field}
      placeholder={t('Name', {context: 'connection'})}
      onChange={helpers.setValue}
    />
  );
};

export default Label;
