import React from 'react';

import {MessageAudio, UserMessage} from '../../components';
import {normalizeWaveform} from 'common/actions';
import {useAppContext} from 'app-context';
import {useConvert} from 'common/hooks';
import {useGetTgRemoteFile} from '../../hooks';
import {useTelegramMessageContext} from '../telegram-message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const VoiceNote = () => {
  const {isCanPlayOgg} = useAppContext();
  const {updateMessageBody, user} = useWorkspaceContext();
  const {message} = useTelegramMessageContext();

  const convert = useConvert();
  const getTgRemoteFile = useGetTgRemoteFile();

  const handleClick = async () => {
    const {integration} = user;

    const {voiceNote} = message.content;
    if (!voiceNote) return;

    const {remote} = voiceNote.voice;
    const remotePath = await getTgRemoteFile(remote.id);

    if (!remotePath) return;
    else if (isCanPlayOgg) return updateMessageBody(message.id, remotePath);

    const res = await convert(remotePath, 'mp3', integration.domain);
    if (!res) return;

    updateMessageBody(message.id, res.url);
  };

  const waveform = message.content.voiceNote?.waveform
    ? normalizeWaveform(message.content.voiceNote?.waveform)
    : Array(100).fill(1);

  return (
    <UserMessage>
      <MessageAudio
        duration={message.content.voiceNote?.duration}
        message={message}
        waveform={waveform}
        onClick={handleClick}
      />
    </UserMessage>
  );
};

export default VoiceNote;
