import React, {useEffect, useRef} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Textarea} from 'components';

const Text = () => {
  const [field, , helpers] = useField<string>('text');
  const ref = useRef<HTMLTextAreaElement>(null);
  const {t} = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current) return;

      ref.current.focus();
      ref.current.selectionStart = field.value.length;
    }, 100);
  }, []);

  return (
    <Textarea
      {...field}
      placeholder={`${t`Caption`}...`}
      ref={ref}
      onChange={helpers.setValue}
    />
  );
};

export default Text;
