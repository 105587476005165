import React from 'react';
import {useTranslation} from 'react-i18next';

interface MessageLocation {
  lat: number | undefined;
  lng: number | undefined;
}

const MessageLocation = ({lat, lng}: MessageLocation) => {
  const {i18n} = useTranslation();

  return (
    <iframe
      className="message-location"
      src={`https://maps.google.com/maps?hl=${i18n.resolvedLanguage}&output=embed&q=${lat},${lng}`}
    />
  );
};

export default MessageLocation;
