import React from 'react';
import {Icons, Text} from 'components';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

interface DropzoneAlt {
  onChange: (files: File[]) => void;
}

const DropzoneAlt = ({onChange}: DropzoneAlt) => {
  const {getInputProps, getRootProps, isDragAccept} = useDropzone({
    onDrop: onChange
  });

  const {t} = useTranslation();

  return (
    <div
      {...getRootProps()}
      className={cn('dropzone-alt', {'dropzone-alt_active': isDragAccept})}
    >
      <input {...getInputProps()} />

      <Text color="blue" size={16}>
        {isDragAccept ? t`Drop files here` : t`File(s)`}
      </Text>

      <Icons.PaperClip color="blue" size={20} />
    </div>
  );
};

export default DropzoneAlt;
