import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PageBody, Wrapper} from 'components';

import ConnectionsMainContext from './connections-main-context';
import InstanceCreator from './instance-creator/instance-creator';
import InstanceList from './instance-list/instance-list';

const ConnectionsMain = () => {
  const [connectorId, setConnectorId] = useState<number>();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Connections`} - Whatcrm Web`;
  }, []);

  return (
    <ConnectionsMainContext.Provider value={{connectorId, setConnectorId}}>
      <PageBody>
        <Wrapper direction="column" gap={16} style={{flexGrow: 1}} noWrap>
          <InstanceCreator />
          <InstanceList />
        </Wrapper>
      </PageBody>
    </ConnectionsMainContext.Provider>
  );
};

export default ConnectionsMain;
