import React from 'react';
import {useTranslation} from 'react-i18next';

import {IconsAlt} from 'components';
import {useDialogContext} from '../dialog-context';
import {getIsDialogPinned, getIsDialogUnread} from 'common/actions';
import {ContextMenuOption} from 'components/context-menu/context-menu';
import * as AppEnv from 'app-env';

import useTogglePin from './use-toggle-pin';
import useToggleRead from './use-toggle-read';

const useOptions = (setIsConfirmationActive: AppEnv.SetState<boolean>) => {
  const {dialog} = useDialogContext();

  const {t} = useTranslation();

  const isPinned = getIsDialogPinned(dialog);
  const isUnread = getIsDialogUnread(dialog);

  const togglePin = useTogglePin();
  const toggleRead = useToggleRead();

  const options: ContextMenuOption[] = [];

  if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    options.push(
      isPinned
        ? {
            icon: <IconsAlt.PushPinSimpleSlash />,
            label: t`Unpin`,
            onClick: () => togglePin(false)
          }
        : {
            icon: <IconsAlt.PushPinSimple />,
            label: t`Pin`,
            onClick: () => togglePin(true)
          }
    );

  if (isUnread)
    options.push({
      icon: <IconsAlt.CheckSquareOffset />,
      label: t`Mark as read`,
      onClick: () => toggleRead(true)
    });
  else if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    options.push({
      icon: <IconsAlt.Notification />,
      label: t`Mark as unread`,
      onClick: () => toggleRead(false)
    });

  if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    options.push({
      icon: <IconsAlt.TrashSimple />,
      label: dialog.version == 'telegram' ? t`Delete` : t`Clear`,
      onClick: () => setIsConfirmationActive(true)
    });

  return options;
};

export default useOptions;
