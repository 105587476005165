import {useState} from 'react';
import {FFmpeg} from '@ffmpeg/ffmpeg';

const useGetFfmpeg = () => {
  const [ffmpeg, setFfmpeg] = useState<FFmpeg>();

  const getFfmpeg = async () => {
    if (ffmpeg) return ffmpeg;

    const ffmpegInstance = new FFmpeg();
    await ffmpegInstance.load();
    setFfmpeg(ffmpegInstance);

    return ffmpegInstance;
  };

  return getFfmpeg;
};

export default useGetFfmpeg;
