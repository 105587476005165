import React, {useState} from 'react';
import ReactCodeInput from 'react-code-input';
import {useField, useFormikContext} from 'formik';

import * as VerificationEnv from '../verification-env';

interface Code {
  submitForm: (values: VerificationEnv.Values) => Promise<boolean>;
}

const Code = ({submitForm}: Code) => {
  const [isAttempted, setIsAttempted] = useState(false);

  const {setSubmitting} = useFormikContext();
  const [field, meta, helpers] = useField<string>('code');

  const handleChange = async (value: string) => {
    helpers.setValue(value);
    if (value.length < 8 || isAttempted) return;

    setIsAttempted(true);
    setSubmitting(true);
    await submitForm({code: value});
    setSubmitting(false);
  };

  const isValid = !(meta.touched && meta.error);

  return (
    <ReactCodeInput
      {...field}
      className="react-code-input_space-between"
      fields={8}
      inputMode="latin"
      inputStyleInvalid={{outlineColor: '#ff334b'}}
      autoFocus
      isValid={isValid}
      onChange={handleChange}
    />
  );
};

export default Code;
