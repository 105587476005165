import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Toggle} from 'components';

const IsCallback = () => {
  const [field, , helpers] = useField<boolean>('is_callback');
  const {t} = useTranslation();

  return (
    <Toggle
      {...field}
      label={t`“Call me back” button`}
      onChange={helpers.setValue}
    />
  );
};

export default IsCallback;
