import React, {useId} from 'react';

interface ColorPicker {
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const ColorPicker = ({name, onChange, value}: ColorPicker) => {
  const id = useId();

  return (
    <label className="color-picker" htmlFor={id} style={{background: value}}>
      <input
        id={id}
        name={name}
        onChange={e => onChange(e.target.value)}
        type="color"
        value={value}
      />
    </label>
  );
};

export default ColorPicker;
