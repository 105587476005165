import React from 'react';

import {Title} from 'components';
import * as AppEnv from 'app-env';

import FeatList from './feat-list/feat-list';
import TariffOptions from './tariff-options/tariff-options';

interface Tariff {
  tariffOptions: AppEnv.Tariff[];
  tariffPlan: AppEnv.TariffPlan;
  tariffs: AppEnv.Tariffs;
}

const Tariff = ({tariffOptions, tariffPlan, tariffs}: Tariff) => (
  <div className="tariff">
    <div className="tariff__header">
      <Title.H3 isNoWrap isUppercase>
        {tariffPlan}
      </Title.H3>
    </div>

    <div className="tariff__body">
      <FeatList tariffPlan={tariffPlan} tariffs={tariffs} />
      <TariffOptions tariffOptions={tariffOptions} />
    </div>
  </div>
);

export default Tariff;
