import React from 'react';
import cn from 'classnames';

import {FolderOption} from '../folders-env';
import {getIsDialogMuted} from 'common/actions';
import {Text} from 'components';
import {useDispatchContext} from '../../../dispatch-context';

import {useUnreadDialogList} from './hooks';

interface Folder {
  folderOption: FolderOption;
}

const Folder = ({folderOption}: Folder) => {
  const {folder, setFolder} = useDispatchContext();
  const unreadDialogList = useUnreadDialogList(folderOption);

  const isMuted = !unreadDialogList.some(dialog => !getIsDialogMuted(dialog));

  return (
    <div
      className={cn('folders__item', {
        folders__item_active: folderOption.value == folder
      })}
      onClick={() => setFolder(folderOption.value)}
    >
      <Text size={12} isNoWrap>
        {folderOption.label}
      </Text>

      {unreadDialogList.length > 0 && (
        <span className={cn('folders__count', {folders__count_muted: isMuted})}>
          <Text color="white" size={12} fontWeight={600} textAlign="center">
            {unreadDialogList.length}
          </Text>
        </span>
      )}
    </div>
  );
};

export default Folder;
