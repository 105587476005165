import React, {forwardRef} from 'react';
import {Player} from '@lottiefiles/react-lottie-player';
import cn from 'classnames';
import Zoom from 'react-medium-image-zoom';

import {getDisplaySize} from './actions';

interface Media {
  controls?: boolean;
  height?: number;
  isAutoPlay?: boolean;
  isBackground?: boolean;
  isCircle?: boolean;
  isPreview?: boolean;
  minithumbnail?: string;
  noSubstrate?: boolean;
  noZoom?: boolean;
  src: string | undefined;
  type?: 'img' | 'lottie' | 'video';
  width?: number;
}

const Media = forwardRef<HTMLDivElement, Media>(
  (
    {
      controls,
      height,
      isAutoPlay,
      isBackground,
      isCircle,
      isPreview,
      minithumbnail,
      noSubstrate,
      noZoom,
      src,
      type = 'img',
      width
    },
    ref
  ) => {
    const getBackground = () => {
      if (minithumbnail && type == 'video') return `url('${minithumbnail}')`;
      else if (src && type == 'img') return `url('${src}')`;

      return '#000';
    };

    const background = getBackground();
    const displaySize = getDisplaySize(width, height, isPreview);
    const imgProps = {src, style: displaySize};
    const isSubstrate = !noSubstrate && type != 'lottie';

    return (
      <div className={cn('media', {media_circle: isCircle})} ref={ref}>
        {isSubstrate && (
          <div className="media__substrate" style={{background}} />
        )}

        {type == 'img' ? (
          noZoom ? (
            <img {...imgProps} />
          ) : (
            <Zoom>
              {isBackground ? (
                <div
                  role="img"
                  style={{
                    ...displaySize,
                    background: `url('${src}') no-repeat center / contain`
                  }}
                />
              ) : (
                <img {...imgProps} />
              )}
            </Zoom>
          )
        ) : type == 'lottie' ? (
          <Player src={src || ''} style={displaySize} autoplay loop />
        ) : (
          type == 'video' && (
            <video
              src={src}
              style={displaySize}
              autoPlay={isAutoPlay}
              controls={controls}
              loop={isAutoPlay}
              muted={isAutoPlay}
            />
          )
        )}
      </div>
    );
  }
);

Media.displayName = 'Media';
export default Media;
