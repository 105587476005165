import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Title, Input} from 'components';

const Body = () => {
  const {t} = useTranslation();
  const [field, meta, helpers] = useField<string>('body');

  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Title.H4 style={{marginBottom: 4, paddingLeft: 12}} isUppercase>
        {t`Question`}
      </Title.H4>

      <Input
        {...field}
        color="gray"
        placeholder={t`Ask a question`}
        isSmall
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Body;
