import {useEffect} from 'react';

import {getIsDialogMuted, getIsDialogUnread} from 'common/actions';
import * as AppEnv from 'app-env';

const useFavicon = (dialogs: AppEnv.Dialogs | undefined) => {
  useEffect(() => {
    const allDialogs = Object.values(dialogs || {}).flat() as AppEnv.Dialog[];

    const isBadge = allDialogs.some(
      dialog => !getIsDialogMuted(dialog) && getIsDialogUnread(dialog)
    );

    const favicon = document.querySelector(
      '#favicon'
    ) as HTMLLinkElement | null;

    if (!favicon) return;
    favicon.href = isBadge ? '/favicon_badge.ico' : '/favicon.ico';
  }, [dialogs]);
};

export default useFavicon;
