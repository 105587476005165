import React, {useRef} from 'react';

import {MessageProduct, UserMessage} from '../../components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import useMedia from '../use-media';

const Product = () => {
  const {message} = useWhatsAppMessageContext();

  const ref = useRef<HTMLDivElement>(null);
  useMedia(ref);

  return (
    <UserMessage>
      <MessageProduct
        currency={message._data.currencyCode}
        description={message._data.description}
        height={message._data.height}
        href={message._data.url}
        price={(message._data.priceAmount1000 || 0) / 1000}
        ref={ref}
        retailerId={message._data.retailerId}
        src={message.body}
        title={message.title}
        width={message._data.width}
      />
    </UserMessage>
  );
};

export default Product;
