import {getFromLocalStorage} from 'whatcrm-core';
import {useNavigate} from 'react-router-dom';

import {
  getDialogId,
  getDialogName,
  getIsDialogMuted,
  getIsMessageOutgoing
} from 'common/actions';
import {useAppContext} from 'app-context';
import {useGetMessagePreview} from 'common/hooks';
import * as AppEnv from 'app-env';

import {getNotificationPermission} from './actions';

const useNotification = () => {
  const {isIframe, toneRef} = useAppContext();

  const getMessagePreview = useGetMessagePreview();
  const navigate = useNavigate();

  const playTone = () => toneRef.current?.play().catch(() => {});

  const notification = async (
    instance: AppEnv.Instance,
    dialog: AppEnv.Dialog,
    message: AppEnv.Message
  ) => {
    const isDialogMuted = getIsDialogMuted(dialog);
    if (isDialogMuted) return;

    const isMessageOutgoing = getIsMessageOutgoing(dialog, message);
    if (isMessageOutgoing) return;

    const textTone = getFromLocalStorage('textTone');

    const notificationPermission = await getNotificationPermission();

    if (notificationPermission != 'granted')
      return textTone ? playTone() : undefined;

    const to = `/messenger/${instance.id}/${getDialogId(dialog)}`;
    if (!document.hidden && to == location.pathname) return;

    const dialogName = getDialogName(instance, dialog) || 'Whatcrm Web';

    const notification = new Notification(dialogName, {
      body: getMessagePreview(message),
      icon: '/static/favicon/icon.png'
    });

    notification.onclick = () => {
      notification.close();
      window.focus();
      navigate(to, {replace: isIframe});
    };

    if (textTone != '1') return;
    notification.onshow = playTone;
  };

  return notification;
};

export default useNotification;
