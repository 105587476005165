import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useField} from 'formik';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useFooterContext} from '../../../../footer-context';
import * as AppEnv from 'app-env';

import Preview from './preview/preview';
import Text from './text/text';

const Form = () => {
  const {isAttachsFormActive, setIsAttachsFormActive} = useFooterContext();

  const [field, , helpers] = useField<AppEnv.Attach[]>('attachs');
  const {t} = useTranslation();

  const handleClick = () => {
    setIsAttachsFormActive(false);
    setTimeout(() => helpers.setValue([]), 150);
  };

  const filesLength = field.value.length.toLocaleString();
  const isValid = !field.value.find(item => !item.isValid);

  return (
    <Popup width={408} isActive={isAttachsFormActive} onClick={handleClick}>
      <PopupWrapper>
        <Wrapper alignItems="stretch" direction="column" gap={16}>
          <Title.H3>
            <Trans count={field.value.length}>
              {'Send '}
              {{filesLength}}
              {' file'}
            </Trans>
          </Title.H3>

          {field.value.map((item, i) => (
            <Preview attach={item} index={i} key={i} />
          ))}

          <Text />
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <SaveButton isDisabled={!isValid}>{t`Send`}</SaveButton>

          <Button color="fade" onClick={handleClick}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default Form;
