import {useEffect} from 'react';
import update from 'immutability-helper';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useMe = (
  activeInstances: AppEnv.Instance[],
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>
) => {
  const {fetchMe} = useRequest();

  const getMe = async (instance: AppEnv.Instance) => {
    if (instance.version == 'chat') return;

    const me = await fetchMe(instance.chat_key);
    if (!me) return;

    setUser(prevValue =>
      update(prevValue, {instances: {[instance.id]: {me: {$set: me}}}})
    );
  };

  useEffect(() => {
    activeInstances.forEach(instance => getMe(instance));
  }, []);
};

export default useMe;
