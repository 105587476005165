import React, {forwardRef} from 'react';
import useHandleFiles from '../../../use-handle-files';

const Input = forwardRef<HTMLInputElement>((props, ref) => {
  const handleFiles = useHandleFiles();

  const handleChange = (files: FileList | null) =>
    handleFiles(files ? Object.values(files) : null);

  return (
    <input
      id="attachs"
      name="attachs"
      ref={ref}
      type="file"
      value=""
      hidden
      multiple
      onChange={e => handleChange(e.target.files)}
    />
  );
});

Input.displayName = 'Input';
export default Input;
