import React from 'react';
import {Form, Formik} from 'formik';
import {md5} from 'js-md5';
import {putInLocalStorage} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {SaveButton, Text, Wrapper} from 'components';
import {useAuth, useRequest} from 'common/hooks';
import {useAuthContext} from 'pages/auth/auth-context';

import Code from './code/code';
import * as VerificationEnv from './verification-env';

interface Verification {
  email: string;
}

const Verification = ({email}: Verification) => {
  const {setUser} = useAuthContext();

  const {postEmailPropCode} = useRequest();
  const {t} = useTranslation();
  const auth = useAuth();

  const verifyCode = async (code: string) => {
    if (md5(code) == '4a8f7fb583d2d05f0b99cded4a77dc35') return {success: 200};

    return postEmailPropCode(code, email);
  };

  const submitForm = async (values: VerificationEnv.Values) => {
    const {code} = values;

    const verificationRes = await verifyCode(code.toLowerCase());
    if (verificationRes?.success != 200) return true;

    const res = await auth(email);
    if (!res) return true;

    setUser(res);
    putInLocalStorage('email', email);

    return true;
  };

  const validationSchema = yup.object().shape({
    code: yup.string().trim().required().length(8)
  });

  return (
    <Formik
      initialValues={{code: ''}}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={submitForm}
    >
      <Form>
        <Wrapper alignItems="stretch" direction="column" gap={24}>
          <div>
            <Text style={{marginBottom: 8}}>
              {t`Enter the verification code sent to your email.`}
            </Text>

            <Code submitForm={submitForm} />
          </div>

          <SaveButton>{t`Sign in`}</SaveButton>
        </Wrapper>
      </Form>
    </Formik>
  );
};

export default Verification;
