const getBaseURL = () => {
  const slice = window.location.host.slice(-2);

  let baseURL = process.env.REACT_APP_API;
  let filesBaseURL = process.env.REACT_APP_API;

  if (slice != 'ru') return {baseURL, filesBaseURL};

  baseURL = process.env.REACT_APP_API_RU;
  filesBaseURL = process.env.REACT_APP_FILES;

  return {baseURL, filesBaseURL};
};

export default getBaseURL;
