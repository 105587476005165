import {getUrlParams} from 'common/actions';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import useGetInstances from './use-get-instances/use-get-instances';

const useAuth = () => {
  const {fetchDashboard, fetchWebAuth} = useRequest();
  const getInstances = useGetInstances();

  const getUser = async (res: AppEnv.User | null) => {
    if (!res) return null;

    const instances = await getInstances(res);
    return {...res, instances};
  };

  const getDashboard = async (domain: string) => {
    const {crm = 'LK'} = getUrlParams();
    const res = await fetchDashboard(crm, domain);
    return getUser(res);
  };

  const getWebAuth = async (email: string) => {
    const params = getUrlParams();
    const res = await fetchWebAuth(email, params);

    return getUser(res);
  };

  const auth = async (email: string): Promise<AppEnv.User | null> => {
    if (process.env.REACT_APP_TYPE == 'app') return getDashboard(email);
    return getWebAuth(email);
  };

  return auth;
};

export default useAuth;
