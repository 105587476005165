import React from 'react';
import cn from 'classnames';

import {Text} from 'components';

interface Toggle {
  isAlt?: boolean;
  label: string;
  onChange: (arg: boolean) => void;
  value: boolean;
}

const Toggle = ({isAlt, label, onChange, value}: Toggle) => (
  <div
    className={cn('toggle', {toggle_alt: isAlt})}
    onClick={() => onChange(!value)}
  >
    <div className={cn('toggle__btn', {toggle__btn_active: value})} />
    <Text>{label}</Text>
  </div>
);

export default Toggle;
