import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Checkbox} from 'components';

const IsManager = () => {
  const [field, , helpers] = useField<0 | 1>('is_manager');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`Show the manager’s name in the message`}
      onChange={value => helpers.setValue(value ? 1 : 0)}
    />
  );
};

export default IsManager;
