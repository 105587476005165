import React, {forwardRef} from 'react';
import cn from 'classnames';

interface Wrapper {
  direction?: 'column' | 'row-reverse';
  alignItems?: 'center' | 'flex-end' | 'stretch';
  alignSelf?: 'flex-start';
  children: React.ReactNode;
  gap?: 2 | 4 | 6 | 8 | 12 | 16 | 24 | 48;
  isFlexGrow?: true;
  justifyContent?: 'center' | 'space-between';
  noWrap?: boolean;
  style?: React.CSSProperties;
}

const Wrapper = forwardRef<HTMLDivElement, Wrapper>(
  (
    {
      alignItems,
      alignSelf,
      children,
      direction,
      gap,
      isFlexGrow,
      justifyContent,
      noWrap,
      style
    },
    ref
  ) => (
    <div
      className={cn('wrapper', {
        'wrapper_flex-grow': isFlexGrow,
        [`wrapper_align-items-${alignItems}`]: alignItems,
        [`wrapper_align-self-${alignSelf}`]: alignSelf,
        [`wrapper_direction-${direction}`]: direction,
        [`wrapper_gap-${gap}`]: gap,
        [`wrapper_justify-content-${justifyContent}`]: justifyContent,
        wrapper_nowrap: noWrap
      })}
      ref={ref}
      style={style}
    >
      {children}
    </div>
  )
);

Wrapper.displayName = 'Wrapper';
export default Wrapper;
