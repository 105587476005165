import React from 'react';
import {useTranslation} from 'react-i18next';

import {MessageText, UserMessage} from '../components';
import * as AppEnv from 'app-env';
import MessagePreview from '../components/message-preview/message-preview';

import AvitoMessageContext from './avito-message-context';
import Image from './image/image';
import Link from './link/link';
import Location from './location/location';
import Text from './text/text';
import Voice from './voice/voice';

interface AvitoMessage {
  message: AppEnv.AvitoMessage;
}

const AvitoMessage = ({message}: AvitoMessage) => {
  const {t} = useTranslation();

  return (
    <AvitoMessageContext.Provider value={{message}}>
      {message.type == 'appCall' ? (
        <MessagePreview />
      ) : message.type == 'call' ? (
        <MessagePreview />
      ) : message.type == 'deleted' ? (
        <MessagePreview isLight />
      ) : message.type == 'image' ? (
        <Image />
      ) : message.type == 'link' ? (
        <Link />
      ) : message.type == 'location' ? (
        <Location />
      ) : message.type == 'system' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'text' ? (
        <Text />
      ) : message.type == 'voice' ? (
        <Voice />
      ) : (
        <UserMessage>
          <MessageText isLight>
            {t`The message is not supported on this version.`}
          </MessageText>
        </UserMessage>
      )}
    </AvitoMessageContext.Provider>
  );
};

export default AvitoMessage;
