import React from 'react';
import cn from 'classnames';

interface Vertical {
  color?: 'blue' | 'gray';
}

const Vertical = ({color = 'blue'}: Vertical) => (
  <div className={cn('vertical', {[`vertical_${color}`]: color})} />
);

export default Vertical;
