import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface TariffListContext {
  selectedTariff: AppEnv.Tariff | undefined;
  setSelectedTariff: AppEnv.SetState<AppEnv.Tariff | undefined>;
  total: number;
}

const TariffListContext = React.createContext<TariffListContext>(
  {} as TariffListContext
);

export const useTariffListContext = () => useContext(TariffListContext);
export default TariffListContext;
