import React from 'react';
import {useField} from 'formik';

import {ChipsAlt, ChipsAltOption} from 'components';
import * as AppEnv from 'app-env';

const Type = () => {
  const [field, , helpers] =
    useField<AppEnv.WidgetCallbackType>('callback_type');

  const options: ChipsAltOption<AppEnv.WidgetCallbackType>[] = [
    {
      img: '/static/widget/callback-type/horizontal.svg',
      value: 'horizontal'
    },
    {img: '/static/widget/callback-type/image.svg', value: 'image'},
    {
      img: '/static/widget/callback-type/vertical.svg',
      value: 'vertical'
    }
  ];

  return <ChipsAlt {...field} options={options} onChange={helpers.setValue} />;
};

export default Type;
