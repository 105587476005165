import React, {useEffect} from 'react';
import update from 'immutability-helper';

import {Popup} from 'components';
import {useConnectionsMainContext} from '../../../connections-main-context';
import {useInstanceContext} from '../instance-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import ConnectorContext from './connector-context';
import LoadingScreen from './loading-screen/loading-screen';
import Setup from './setup/setup';
import WaitCode from './wait-code/wait-code';
import WaitPassword from './wait-password/wait-password';

interface Connector {
  isConnectorActive: boolean;
  setIsConnectorActive: AppEnv.SetState<boolean>;
}

const Connector = ({isConnectorActive, setIsConnectorActive}: Connector) => {
  const {setUser} = useWorkspaceContext();
  const {setConnectorId} = useConnectionsMainContext();
  const {instance} = useInstanceContext();

  const {fetchStatus} = useRequest();

  const updateState = (
    status: AppEnv.InstanceStatus | null,
    condition: AppEnv.InstanceCondition | null
  ) => {
    if (!condition || !status) return;

    setUser(prevValue =>
      update(prevValue, {
        instances: {
          [instance.id]: {condition: {$set: condition}, status: {$set: status}}
        }
      })
    );
  };

  const getStatus = async (full?: 1) => {
    const res = await fetchStatus(instance.chat_key, full);
    updateState(res?.state || null, res);

    if (full) return;
    setTimeout(getFullStatus, 3000);
  };

  const getFullStatus = () => {
    const {condition} = instance;
    if (condition?.qrCode) return;
    getStatus(1);
  };

  useEffect(() => {
    getStatus();
  }, []);

  const handleClick = () => {
    setIsConnectorActive(false);
    setTimeout(() => setConnectorId(undefined), 150);
  };

  return (
    <ConnectorContext.Provider value={{getStatus}}>
      <Popup isActive={isConnectorActive} onClick={handleClick} width={408}>
        {instance.status == 'authorizationStateWaitCode' ? (
          <WaitCode />
        ) : instance.status == 'authorizationStateWaitPassword' ? (
          <WaitPassword />
        ) : instance.status == 'LOADING_SCREEN' ? (
          <LoadingScreen />
        ) : (
          <Setup />
        )}
      </Popup>
    </ConnectorContext.Provider>
  );
};

export default Connector;
