import React from 'react';
import {useTranslation} from 'react-i18next';
import countries from 'i18n-iso-countries';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  Select,
  Title,
  Wrapper
} from 'components';
import {Stage} from '../tariff-list-env';
import * as AppEnv from 'app-env';

interface Location {
  location: string | undefined;
  setLocation: AppEnv.SetState<string | undefined>;
  setStage: AppEnv.SetState<Stage | undefined>;
  stage: Stage | undefined;
}

const Location = ({location, setLocation, setStage, stage}: Location) => {
  const {i18n, t} = useTranslation();

  const lang = i18n.resolvedLanguage || 'en';

  const options = [
    {
      comment: countries.getName('FI', lang),
      label: t`Helsinki`,
      value: 'Helsinki'
    },
    {
      comment: countries.getName('DE', lang),
      label: t`Falkenstein`,
      value: 'Falkenstein'
    },
    {
      comment: countries.getName('DE', lang),
      label: t`Nuremberg`,
      value: 'Nuremberg'
    },
    {label: countries.getName('KZ', lang) || 'Kazakhstan', value: 'Kazakhstan'},
    {
      label: countries.getName('NL', lang) || 'Netherlands',
      value: 'Netherlands'
    },
    {label: countries.getName('PL', lang) || 'Poland', value: 'Poland'},
    {comment: countries.getName('RU', lang), label: t`Moscow`, value: 'Moscow'},
    {
      comment: countries.getName('RU', lang),
      label: t`Saint Petersburg`,
      value: 'Saint Petersburg'
    },
    {
      comment: `${t`Oregon`}, ${countries.getName('US', lang)}`,
      label: t`Hillsboro`,
      value: 'Hillsboro'
    },
    {
      comment: `${t`Virginia`}, ${countries.getName('US', lang)}`,
      label: t`Ashburn`,
      value: 'Ashburn'
    }
  ];

  return (
    <Popup
      width={408}
      isActive={stage == 'location'}
      onClick={() => setStage(undefined)}
    >
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>{t`Server location`}</Title.H3>

        <Select
          options={options}
          placeholder={t`Server location`}
          value={location}
          onChange={setLocation}
        />
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button onClick={() => setStage('payment-gateway')}>
            {t`Continue`}
          </Button>

          <Button color="fade" onClick={() => setStage(undefined)}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default Location;
