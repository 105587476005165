import {useInstanceContext} from '../../instance-context';

export const useInstancePaymentStatus = () => {
  const {instance} = useInstanceContext();
  const {chatInfo} = instance;

  if (!chatInfo) return null;
  else if (chatInfo.is_premium) return 'premium';
  else if (chatInfo.date_subscription * 1000 > Date.now()) return 'paid';
  else if (chatInfo.date_trial * 1000 > Date.now()) return 'trial';

  return 'unpaid';
};
