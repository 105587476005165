import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface WhatsAppMessageContext {
  message: AppEnv.WhatsAppMessage;
}

const WhatsAppMessageContext = React.createContext<WhatsAppMessageContext>(
  {} as WhatsAppMessageContext
);

export const useWhatsAppMessageContext = () =>
  useContext(WhatsAppMessageContext);

export default WhatsAppMessageContext;
