import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const useIsNewChatShown = () => {
  const {isIframe} = useAppContext();
  const {activeInstances} = useWorkspaceContext();

  if (isIframe) return false;

  return !!activeInstances.find(item =>
    ['telegram', 'whatcrm'].includes(item.version)
  );
};

export {useIsNewChatShown};
