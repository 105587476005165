import {UpdateMessageContent} from '../use-whatcrm-ws-env';
import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import * as AppEnv from 'app-env';
import useHandleMsg from '../use-handle-msg/use-handle-msg';

const useHandleUpd = () => {
  const {dialogs} = useWebSocketProviderContext();
  const handleMsg = useHandleMsg();

  const handleUpd = (
    instance: AppEnv.Instance,
    updMsgContent: UpdateMessageContent
  ) => {
    const dialog = dialogs[instance.id]?.find(
      dialog => dialog.id == updMsgContent.chatId
    );

    if (dialog?.version != 'telegram') return;
    const msg = dialog.messages?.find(msg => msg.id == updMsgContent.messageId);

    if (!msg) return;
    else if (!('senderId' in msg)) return;

    const newMsg: AppEnv.TelegramMessage = {
      ...msg,
      body: '',
      content: updMsgContent.newContent,
      editDate: Math.floor(+new Date())
    };

    handleMsg(instance, newMsg);
  };

  return handleUpd;
};

export default useHandleUpd;
