import * as AppEnv from 'app-env';

interface UrlParams {
  key?: string;
  version?: AppEnv.InstanceVersion;
}

const useUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams: UrlParams = Object.fromEntries(urlSearchParams.entries());

  return urlParams;
};

export {useUrlParams};
