import React from 'react';
import cn from 'classnames';

interface Anchor {
  children: React.ReactNode;
  direction?: 'rtl';
  fontWeight?: 500;
  isDisabled?: boolean;
  onClick?: () => void;
  size?: 14 | 16;
  textAlign?: 'center';
}

const Anchor = ({
  children,
  direction,
  fontWeight,
  isDisabled,
  onClick,
  size,
  textAlign
}: Anchor) => (
  <button
    className={cn('anchor', {[`anchor_${size}`]: size})}
    disabled={isDisabled}
    onClick={() => (onClick ? onClick() : undefined)}
    style={{direction, fontWeight, textAlign}}
    type="button"
  >
    {children}
  </button>
);

export default Anchor;
