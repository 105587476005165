import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Title} from 'components';

import Identification from './identification/identification';
import Verification from './verification/verification';
import * as MainEnv from './main-env';

const Main = () => {
  const [email, setEmail] = useState<string>();
  const [stage, setStage] = useState<MainEnv.Stage>('identification');

  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Signing in`} - Whatcrm Web`;
  }, []);

  const isVerificationShown = stage == 'verification' && email;

  return (
    <div className="auth__main">
      <div className="auth__block">
        <Title.H2 style={{marginBottom: 24}}>
          {t('Sign in', {context: 'account'})}
        </Title.H2>

        {stage == 'identification' ? (
          <Identification setEmail={setEmail} setStage={setStage} />
        ) : isVerificationShown ? (
          <Verification email={email} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Main;
