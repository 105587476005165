import React from 'react';

import {SystemMsg} from '../../../../components';
import {useGetMessagePreview} from 'common/hooks';
import {useMessageContext} from '../../message-context';
import MessageText from '../message-text/message-text';
import UserMessage from '../user-message/user-message';

interface MessagePreview {
  isLight?: boolean;
  isSystemMessage?: boolean;
}

const MessagePreview = ({isLight, isSystemMessage}: MessagePreview) => {
  const {message} = useMessageContext();
  const getMessagePreview = useGetMessagePreview();

  const messagePreview = getMessagePreview(message);

  return (
    <>
      {isSystemMessage ? (
        <SystemMsg isAlt={'author_id' in message}>{messagePreview}</SystemMsg>
      ) : (
        <UserMessage>
          <MessageText isLight={isLight}>{messagePreview}</MessageText>
        </UserMessage>
      )}
    </>
  );
};

export default MessagePreview;
