import React from 'react';
import {useTranslation} from 'react-i18next';

import {SaveButton} from 'components';
import Code from './code/code';

const AsideFooter = () => {
  const {t} = useTranslation();

  return (
    <div className="aside__footer">
      <SaveButton>{t`Continue`}</SaveButton>
      <Code />
    </div>
  );
};

export default AsideFooter;
