import {useEffect, useState} from 'react';
import {getFromLocalStorage} from 'whatcrm-core';

import {getUrlParams} from 'common/actions';
import {useAuth} from 'common/hooks';
import * as AppEnv from 'app-env';

const useUser = (): [
  AppEnv.User | null | undefined,
  AppEnv.SetState<AppEnv.User | null | undefined>
] => {
  const [user, setUser] = useState<AppEnv.User | null>();
  const auth = useAuth();

  const getLogin = () => {
    const {code, domain, email, key, user} = getUrlParams();
    const storage = getFromLocalStorage('email');

    if (process.env.REACT_APP_TYPE == 'app')
      return (code ? domain : storage) || null;

    const login = email || key || user;
    return (code ? login : storage) || null;
  };

  const getUser = async () => {
    const login = getLogin();
    if (!login) return setUser(null);

    const res = await auth(login);
    setUser(res);
  };

  useEffect(() => {
    getUser();
  }, []);

  return [user, setUser];
};

export default useUser;
