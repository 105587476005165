import {useState} from 'react';

import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {getIsMessageLimited, getMessageTimestamp} from 'common/actions';
import * as AppEnv from 'app-env';

import useUpdMsgList from './use-upd-msg-list/use-upd-msg-list';

const useMsgList = (setIsAllMsgsReceived: AppEnv.SetState<boolean>) => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(dialog.messages?.length || 0);

  const {fetchMessages} = useRequest();
  const updMsgList = useUpdMsgList();

  const getMsgList = async (isAllMsgsReceived: boolean) => {
    if (isAllMsgsReceived || isFetching) return;
    setIsFetching(true);

    const latestMsg = dialog.messages?.[0];
    const latestMsgId = latestMsg?.id;

    const maxTimestamp = latestMsg ? getMessageTimestamp(latestMsg) : undefined;

    const res = await fetchMessages(
      instance.chat_key,
      dialog.version == 'whatcrm' ? dialog.id._serialized : dialog.id,
      dialog.version,
      typeof latestMsgId == 'number' ? latestMsgId : undefined,
      maxTimestamp || Math.round(Date.now() / 1000),
      offset,
      dialog.version == 'telegram' && !!dialog.username
    );

    const msgs = (Array.isArray(res) ? res : res?.messages)?.filter(
      item => !getIsMessageLimited(instance, item)
    );

    if (dialog.version == 'chat' || !msgs?.length) setIsAllMsgsReceived(true);

    setOffset(prevValue => prevValue + (msgs?.length || 0));
    updMsgList(msgs || []);
    setIsFetching(false);
  };

  return {getMsgList};
};

export default useMsgList;
