import React, {useState} from 'react';
import ReactCodeInput from 'react-code-input';
import {useField, useFormikContext} from 'formik';

import {Values} from '../wait-code-env';

interface Code {
  submitForm: (arg: Values) => Promise<boolean>;
}

const Code = ({submitForm}: Code) => {
  const [isAttempted, setIsAttempted] = useState(false);

  const {setSubmitting} = useFormikContext();
  const [field, meta, helpers] = useField<string>('code');

  const handleChange = async (value: string) => {
    helpers.setValue(value);
    if (value.length < 5 || isAttempted) return;

    setIsAttempted(true);
    setSubmitting(true);
    await submitForm({code: value});
    setSubmitting(false);
  };

  const isValid = !(meta.error && meta.touched);

  return (
    <ReactCodeInput
      {...field}
      className=" "
      fields={5}
      inputMode="latin"
      inputStyleInvalid={{outlineColor: '#ff334b'}}
      autoFocus
      isValid={isValid}
      onChange={handleChange}
    />
  );
};

export default Code;
