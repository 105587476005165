import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Wrapper} from 'components';

interface ProgressBar {
  isAlt?: boolean;
  isMaxWidth?: boolean;
  label?: string;
  percent: number;
  width?: '100%';
}

const ProgressBar = ({
  isAlt,
  isMaxWidth,
  label,
  percent,
  width
}: ProgressBar) => {
  const {i18n} = useTranslation();

  return (
    <div
      className={cn('progress-bar', {
        'progress-bar_alt': isAlt,
        'progress-bar_max-width': isMaxWidth
      })}
      style={{width}}
    >
      {!isAlt && (
        <Wrapper justifyContent="space-between" style={{marginBottom: 8}}>
          <span className="progress-bar__text">{label}</span>

          <span className="progress-bar__text">
            {(percent / 100).toLocaleString(
              i18n.resolvedLanguage?.slice(0, 2),
              {style: 'percent'}
            )}
          </span>
        </Wrapper>
      )}

      <div className="progress-bar__thumb">
        <div className="progress-bar__track" style={{width: `${percent}%`}} />
      </div>
    </div>
  );
};

export default ProgressBar;
