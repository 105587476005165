import React from 'react';
import cn from 'classnames';

interface MiniButton {
  children: React.ReactNode;
  color?: 'green' | 'white';
  onClick: () => void;
}

const MiniButton = ({children, color = 'green', onClick}: MiniButton) => (
  <button
    className={cn('mini-btn', {[`mini-btn_${color}`]: color})}
    type="button"
    onClick={() => onClick()}
  >
    {children}
  </button>
);

export default MiniButton;
