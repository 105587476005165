import React from 'react';

interface CardMenu {
  children: React.ReactNode;
}

const CardMenu = ({children}: CardMenu) => (
  <div className="card__menu">{children}</div>
);

export default CardMenu;
