import {useEffect, useState} from 'react';
import _ from 'lodash';

import * as AppEnv from 'app-env';

type UseSlice = [number, AppEnv.SetState<number>];

const useSlice = (
  dialogList: AppEnv.Dialog[] | undefined,
  ref: React.RefObject<HTMLDivElement>
): UseSlice => {
  const [slice, setSlice] = useState(16);

  const handleScroll = () => {
    if (!dialogList || slice > dialogList.length || !ref.current) return;

    const {clientHeight, scrollHeight, scrollTop} = ref.current;
    if (scrollTop / (scrollHeight - clientHeight) < 0.8) return;

    setSlice(prevValue => prevValue + 16);
  };

  useEffect(() => {
    ref.current?.addEventListener('scroll', _.debounce(handleScroll));

    return () => {
      ref.current?.removeEventListener('scroll', _.debounce(handleScroll));
    };
  }, [ref.current]);

  useEffect(() => {
    if (!dialogList || slice > dialogList.length || !ref.current) return;

    const {clientHeight, scrollHeight} = ref.current;
    if (clientHeight / scrollHeight < 0.8) return;

    setSlice(prevValue => prevValue + 16);
  }, [ref.current, slice]);

  return [slice, setSlice];
};

export default useSlice;
