import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {CoreEnv} from 'whatcrm-core';

import {Radio} from 'components';

interface Option {
  label: string;
  value: CoreEnv.Theme;
}

const Theme = () => {
  const [field, , helpers] = useField('theme');
  const {t} = useTranslation();

  const options: Option[] = [
    {label: t`Light`, value: 'light'},
    {label: t`Dark`, value: 'dark'},
    {label: t`Automatic`, value: 'system'}
  ];

  return <Radio {...field} options={options} onChange={helpers.setValue} />;
};

export default Theme;
