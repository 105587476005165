import React from 'react';

import {MessageProduct, UserMessage} from '../../components';
import {useTelegramMessageContext} from '../telegram-message-context';
import TelegramMessagePhoto from '../components/telegram-message-photo/telegram-message-photo';

const Invoice = () => {
  const {message} = useTelegramMessageContext();

  return (
    <UserMessage>
      <MessageProduct
        currency={message.content.currency}
        description={message.content.description?.text}
        price={(message.content.totalAmount || 0) / 100}
        title={message.content.title}
      >
        <TelegramMessagePhoto />
      </MessageProduct>
    </UserMessage>
  );
};

export default Invoice;
