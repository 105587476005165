import React from 'react';
import {useTranslation} from 'react-i18next';

import {Link} from 'components';

const Label = () => {
  const {i18n, t} = useTranslation();
  const href = `https://whatcrm.${i18n.resolvedLanguage == 'ru' ? 'ru' : 'net'}/${i18n.resolvedLanguage}/license-agreement`;

  return (
    <>
      {t`I accept the`}{' '}
      <Link href={href} isTargetBlank>{t`license agreement`}</Link>
    </>
  );
};

export default Label;
