import React from 'react';
import {useTranslation} from 'react-i18next';

import {AudioPlayer} from 'components';
import {getIsVoice} from 'common/actions';
import {MessageText, UserMessage} from '../../components';
import {useAvitoMessageContext} from '../avito-message-context';

const Text = () => {
  const {message} = useAvitoMessageContext();
  const {t} = useTranslation();

  const {text} = message.content;
  const isVoice = getIsVoice(text || '');

  return (
    <UserMessage>
      {text ? (
        isVoice ? (
          <AudioPlayer src={text} />
        ) : (
          <MessageText>{text}</MessageText>
        )
      ) : (
        <MessageText isLight>
          {t`The message is not supported on this version.`}
        </MessageText>
      )}
    </UserMessage>
  );
};

export default Text;
