import React from 'react';

interface CardHead {
  children: React.ReactNode;
}

const CardHead = ({children}: CardHead) => (
  <div className="card__head">{children}</div>
);

export default CardHead;
