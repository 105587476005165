import {useWebSocketProviderContext} from '../../web-socket-context';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import {Call} from './use-whatcrm-ws-env';
import useHandleMsg from './use-handle-msg/use-handle-msg';

const useHandleCalls = () => {
  const {dialogs} = useWebSocketProviderContext();
  const handleMsg = useHandleMsg();

  const handleCalls = (instance: AppEnv.Instance, calls: Call[]) => {
    const [call] = calls;

    const dialog = dialogs[instance.id]?.find(
      item => getDialogId(item, true) == call.from
    );

    if (dialog?.version != 'whatcrm') return;

    const message: AppEnv.WhatsAppMessage = {
      _data: {id: {}},
      body: '',
      chatId: call.from,
      chatName: dialog.name,
      from: call.from,
      fromMe: false,
      id: `true_${call.from}_${call.id}`,
      isVideo: call.isVideo,
      senderName: dialog.name,
      timestamp: call.timestamp,
      type: 'calls'
    };

    handleMsg(instance, message);
  };

  return handleCalls;
};

export default useHandleCalls;
