import React from 'react';
import * as AppEnv from 'app-env';

import WebSocketProviderContext from './web-socket-context';
import WebSocket from './web-socket/web-socket';

interface WebSocket {
  dialogs: AppEnv.Dialogs;
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const WebSocketProvider = ({dialogs, setDialogs}: WebSocket) => (
  <WebSocketProviderContext.Provider value={{dialogs, setDialogs}}>
    <WebSocket />
  </WebSocketProviderContext.Provider>
);

export default WebSocketProvider;
