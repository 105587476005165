import {useTariffListContext} from '../../tariff-list-context';
import {Stage} from '../../tariff-list-env';

const useNewStage = (): Stage => {
  const {selectedTariff} = useTariffListContext();

  if (selectedTariff?.packet_name == 'Enterprise') return 'location';
  return 'payment-gateway';
};

export {useNewStage};
