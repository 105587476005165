const getDisplaySize = (
  width: number | undefined,
  height: number | undefined,
  isPreview: boolean | undefined
): React.CSSProperties => {
  let aspectRatio = '4 / 3';

  if (isPreview) return {aspectRatio, height: 270};
  else if (!width || !height) return {aspectRatio, height: 240};

  aspectRatio = `${width} / ${height}`;
  const maxHeight = 360;
  const maxWidth = 320;

  if (width >= height)
    return {aspectRatio, width: width < maxWidth ? width / 2 : maxWidth};

  return {
    aspectRatio,
    height: height < maxHeight ? height / 2 : maxHeight,
    width: 'max-content'
  };
};

export default getDisplaySize;
