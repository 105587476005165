import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const CallbackHeader = () => {
  const [field, , helpers] = useField<string>('callback_header');
  const {t} = useTranslation();

  return (
    <Input {...field} placeholder={t`Header`} onChange={helpers.setValue} />
  );
};

export default CallbackHeader;
