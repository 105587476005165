import {FolderOption} from '../../../folders-env';
import {getUnreadDialogList} from 'common/actions';

import useFolderDialogList from './use-folder-dialog-list/use-folder-dialog-list';

const useUnreadDialogList = (folderOption: FolderOption) => {
  const folderDialogList = useFolderDialogList(folderOption);
  if (folderOption.value == 'archive') return [];

  return getUnreadDialogList(folderDialogList);
};

export default useUnreadDialogList;
