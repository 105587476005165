import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import {Icons, Text, Title, Wrapper} from 'components';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface Notification {
  notification: AppEnv.Notification | undefined;
}

const Notification = ({notification}: Notification) => {
  const {setNotification} = useAppContext();
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!notification) return;

    setIsActive(true);
    clearTimeout(ref.current);

    ref.current = setTimeout(() => {
      setIsActive(false);
      setTimeout(() => setNotification(undefined), 5000);
    }, 5000);
  }, [notification]);

  return (
    <>
      {!!notification && (
        <div className={cn('notification', {notification_active: isActive})}>
          <Wrapper alignItems="center" gap={12} noWrap>
            <Icons.WarningCircle color="white" />

            <Wrapper direction="column" gap={2}>
              {notification.title && (
                <Title.H5 isWhite>{notification.title}</Title.H5>
              )}

              {notification.text && (
                <Text color="white" size={12}>
                  {notification.text}
                </Text>
              )}
            </Wrapper>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default Notification;
