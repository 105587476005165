import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ChatMessageContext {
  message: AppEnv.ChatMessage;
}

const ChatMessageContext = React.createContext<ChatMessageContext>(
  {} as ChatMessageContext
);

export const useChatMessageContext = () => useContext(ChatMessageContext);
export default ChatMessageContext;
