import React, {useRef} from 'react';

import {Media} from 'components';
import {useTelegramMessageContext} from '../../telegram-message-context';
import useRemoteFile from '../../common/use-remote-file';
import * as AppEnv from 'app-env';

const getSrc = (message: AppEnv.TelegramMessage) => {
  if (message.body) return message.body;
  else if (message.content.photo?.minithumbnail)
    return `data:image/png;base64,${message.content.photo?.minithumbnail?.data}`;
};

const TelegramMessagePhoto = () => {
  const {message} = useTelegramMessageContext();
  const ref = useRef<HTMLDivElement>(null);

  const size = message.content.photo?.sizes.at(-1);
  useRemoteFile(ref, size?.photo.remote.id);

  const src = getSrc(message);

  return (
    <Media height={size?.height} ref={ref} src={src} width={size?.width} />
  );
};

export default TelegramMessagePhoto;
