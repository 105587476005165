import React from 'react';
import cn from 'classnames';

interface DangerouslySetInnerHTML {
  __html: string;
}

export interface TextParams {
  children?: React.ReactNode;
  className?: string;
  color?:
    | 'black'
    | 'blue'
    | 'green'
    | 'light'
    | 'optional'
    | 'secondary'
    | 'white';
  dangerouslySetInnerHTML?: DangerouslySetInnerHTML;
  direction?: 'rtl';
  fontWeight?: 500 | 600;
  isNoWrap?: boolean;
  isPre?: boolean;
  isSpan?: boolean;
  size?: 12 | 16 | 20 | 24 | 72 | 144;
  style?: React.CSSProperties;
  textAlign?: 'center' | 'right';
  textTransform?: 'capitalize';
}

const Text = ({
  children,
  className,
  color,
  dangerouslySetInnerHTML,
  direction,
  fontWeight,
  isNoWrap,
  isPre,
  isSpan,
  size,
  style,
  textAlign,
  textTransform
}: TextParams) => {
  const content = dangerouslySetInnerHTML
    ? {dangerouslySetInnerHTML}
    : {children};

  const params = {
    ...content,
    className: cn('text', {
      [`${className}`]: className,
      [`text_color-${color}`]: color,
      [`text_size-${size}`]: size,
      text_nowrap: isNoWrap,
      text_pre: isPre
    }),
    style: {...style, direction, fontWeight, textAlign, textTransform}
  };

  return isSpan ? <span {...params} /> : <p {...params} />;
};

export default Text;
