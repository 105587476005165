import React, {useState} from 'react';
import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Confirm} from 'components';
import {getDialogId, getDialogPhoneNumber} from 'common/actions';
import {Item} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const List = () => {
  const {isIframe, setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, telegramUser} = useChatContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {postBlacklistCreate} = useRequest();
  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const navigate = useNavigate();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const blockUser = async () => {
    const {integration} = user;

    const id = getDialogId(dialog);
    if (!id) return true;

    const res = await postBlacklistCreate(integration.id, id);
    if (!res) return true;

    setUser(prevValue => update(prevValue, {blacklist: {$push: [res]}}));
    setNotification({title: t`The user has been blocked`});
    navigate(`/messenger/${instance.id}`, {replace: isIframe});

    return true;
  };

  const handleConfirm = (res: boolean) =>
    res ? blockUser() : setIsConfirmationActive(false);

  const isBulletin = dialog.version == 'avito' && dialog.context.value.url;
  const isLocation = dialog.version == 'avito' && dialog.context.value.location;

  const phoneNumber = getDialogPhoneNumber(dialog, telegramUser);

  return (
    <>
      <div className="profile__list">
        {isBulletin && (
          <Item
            href={dialog.context.value.url}
            label={t`bulletin`}
            type="copy"
            value={`${dialog.context.value.title} • ${dialog.context.value.price_string}`}
          />
        )}

        {isLocation && (
          <Item
            label={t`location`}
            onClick={() =>
              handleClick(dialog.context.value.location?.title || '')
            }
            type="copy"
            value={dialog.context.value.location?.title || ''}
          />
        )}

        {phoneNumber && (
          <Item
            label={t`phone number`}
            onClick={() => handleClick(phoneNumber)}
            type="copy"
            value={formatPhoneNumber(phoneNumber)}
          />
        )}

        {telegramUser?.usernames && (
          <Item
            label={t`username`}
            onClick={() =>
              handleClick(telegramUser.usernames?.editableUsername || '')
            }
            type="copy"
            value={`@${telegramUser.usernames.editableUsername}`}
          />
        )}

        <Item
          onClick={() => setIsConfirmationActive(true)}
          type="ban"
          value={t`Block`}
        />
      </div>

      <Confirm
        isActive={isConfirmationActive}
        onClick={handleConfirm}
        title={t`Are you sure you want to block this user?`}
      />
    </>
  );
};

export default List;
