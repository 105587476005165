import * as AppEnv from 'app-env';

const sortTgMsgs = (a: AppEnv.Message, b: AppEnv.Message) => {
  let {id: aid} = a;
  let {id: bid} = b;

  aid = typeof aid == 'string' ? parseInt(aid) : aid;
  bid = typeof bid == 'string' ? (bid = parseInt(bid)) : bid;

  return aid - bid;
};

export default sortTgMsgs;
