import React from 'react';
import {useFormikContext} from 'formik';

import Button, {ButtonProps} from '../button';

const SaveButton = (props: ButtonProps) => {
  const {isSubmitting} = useFormikContext();

  return (
    <Button
      {...props}
      state={isSubmitting ? 'submitting' : undefined}
      isSubmit
      onClick={props.onClick ? props.onClick : () => {}}
    />
  );
};

export default SaveButton;
