import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Text} from 'components';
import {useInstanceContext} from '../../instance-context';
import {useInstancePaymentStatus} from '../../instance-header/hooks';

const DatePay = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const instancePaymentStatus = useInstancePaymentStatus();

  const {chatInfo} = instance;

  const isShown =
    !!chatInfo && chatInfo.date_pay > 0 && instancePaymentStatus != 'premium';

  return (
    <>
      {isShown && (
        <Text>
          {t`Payment date`}
          {': '}
          {moment.unix(chatInfo.date_pay).utcOffset(3).format('LLL')}
          {' '}
          {'UTC+3'}
        </Text>
      )}
    </>
  );
};

export default DatePay;
