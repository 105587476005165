import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Page, PageBody, Placeholder, Preloader} from 'components';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Dispatch from './dispatch/dispatch';

interface Messenger {
  dialogs: AppEnv.Dialogs | undefined;
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const Messenger = ({dialogs, setDialogs}: Messenger) => {
  const {isIframe} = useAppContext();
  const {activeInstances} = useWorkspaceContext();

  const {instanceId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      instanceId &&
      activeInstances.find(instance => instance.id == parseInt(instanceId))
    )
      return;

    const {id} = activeInstances?.[0] || {};
    if (!id) return;

    navigate(`/messenger/${id}`, {replace: isIframe});
  }, [instanceId]);

  useEffect(() => {
    document.title = 'Whatcrm Web';
  }, []);

  const instance = instanceId
    ? activeInstances.find(instance => instance.id == parseInt(instanceId))
    : undefined;

  return (
    <>
      {dialogs ? (
        instance ? (
          <Dispatch
            dialogs={dialogs}
            instance={instance}
            setDialogs={setDialogs}
          />
        ) : (
          <Body>
            <Page>
              <PageBody>
                <Placeholder
                  text={t`You do not have access to the messenger connections, please contact your system administrator. The necessary changes can be made in the "Access to connections" section of the widget settings.`}
                  title="Whatcrm Web"
                  isDelay
                  isMaxHeight
                />
              </PageBody>
            </Page>
          </Body>
        )
      ) : (
        <Body>
          <Page>
            <PageBody>
              <Preloader isMaxHeight />
            </PageBody>
          </Page>
        </Body>
      )}
    </>
  );
};

export default Messenger;
