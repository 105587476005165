import React from 'react';

interface CardBody {
  children: React.ReactNode;
}

const CardBody = ({children}: CardBody) => (
  <div className="card__body">{children}</div>
);

export default CardBody;
