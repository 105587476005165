import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Text, Vertical} from 'components';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useGetMessageSenderName} from 'common/hooks';
import {useMessageContext} from '../../message-context';

interface MessageQuote {
  children: React.ReactNode;
  isDisabled?: boolean;
  isQuote?: boolean;
  onClick?: () => void;
}

const InnerMessage = ({
  children,
  isDisabled,
  isQuote,
  onClick
}: MessageQuote) => {
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  const {t} = useTranslation();
  const getMessageSenderName = useGetMessageSenderName();

  const getQuotedMessage = () => {
    if ('senderId' in message || '_data' in message) return message.quotedMsg;
  };

  const quotedMessage = isQuote ? getQuotedMessage() : undefined;

  const senderName = quotedMessage
    ? getMessageSenderName(dialog, quotedMessage)
    : undefined;

  return (
    <div
      className={cn('inner-message', {
        'inner-message_clickable': onClick,
        'inner-message_disabled': isDisabled,
        'inner-message_quote': isQuote
      })}
      onClick={onClick}
    >
      <Vertical />

      <div className="inner-message__wrapper">
        <Text
          className="inner-message__sender"
          fontWeight={600}
          size={16}
          style={{marginBottom: 4}}
        >
          {isQuote ? senderName : t`Forwarded message`}
        </Text>

        {children}
      </div>
    </div>
  );
};

export default InnerMessage;
