import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Select} from 'components';
import * as AppEnv from 'app-env';

interface Option {
  label: string;
  value: AppEnv.WidgetCallbackFieldType;
}

const CallbackFieldType = () => {
  const [field, , helpers] = useField<AppEnv.WidgetCallbackFieldType>(
    'callback_field_type'
  );

  const {t} = useTranslation();

  const options: Option[] = [
    {label: t`Nonlinear`, value: 'nonlinear'},
    {label: t`Linear`, value: 'linear'}
  ];

  return (
    <Select
      {...field}
      options={options}
      title={t`Phone number input field`}
      onChange={helpers.setValue}
    />
  );
};

export default CallbackFieldType;
