import update from 'immutability-helper';

import {useWebSocketProviderContext} from '../../web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {File} from './use-whatcrm-ws-env';

const useHandleFiles = () => {
  const {updateMessageBody, updateQuotedMsgBody} = useWorkspaceContext();
  const {dialogs, setDialogs} = useWebSocketProviderContext();

  const handleProfileImage = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    file: File
  ) =>
    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {[dialogIndex]: {profileImage: {$set: file.path}}}
      })
    );

  const handleMessageBody = (instance: AppEnv.Instance, file: File) =>
    dialogs[instance.id]?.forEach(dialog =>
      dialog.messages?.forEach(message => {
        if ('senderId' in message) {
          if (
            [
              message.content.animatedEmoji?.sticker.sticker.id,
              message.content.animation?.animation.id,
              message.content.audio?.audio.id,
              message.content.document?.document.id,
              message.content.photo?.sizes.at(-1)?.photo.id,
              message.content.sticker?.sticker.id,
              message.content.video?.video.id,
              message.content.videoNote?.video.id,
              message.content.voiceNote?.voice.id
            ].includes(file.id)
          ) {
            updateMessageBody(message.id, file.path);
          }

          if (
            [
              message.quotedMsg?.content?.photo?.sizes.at(-1)?.photo.id,
              message.quotedMsg?.content?.video?.video.id
            ].includes(file.id)
          ) {
            updateQuotedMsgBody(message.id, file.path);
          }
        }
      })
    );

  const handleFiles = (instance: AppEnv.Instance, files: File[]) => {
    const [file] = files;

    const dialogIndex = dialogs[instance.id]?.findIndex(
      item => item.version == 'telegram' && item.photo?.small.id == file.id
    );

    if (dialogIndex != undefined && dialogIndex > 0) {
      handleProfileImage(instance, dialogIndex, file);
      return;
    }

    handleMessageBody(instance, file);
  };

  return handleFiles;
};

export default useHandleFiles;
