import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {SaveButton, Text, Wrapper} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';
import * as MainEnv from '../main-env';

import Email from './email/email';

interface Values {
  email: string;
}

interface Identification {
  setEmail: AppEnv.SetState<string | undefined>;
  setStage: AppEnv.SetState<MainEnv.Stage>;
}

const Identification = ({setEmail, setStage}: Identification) => {
  const {postEmailSendMd5} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const res = await postEmailSendMd5(values.email);

    if (res?.success == 200) {
      setEmail(values.email);
      setStage('verification');
    }

    return true;
  };

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required().email()
  });

  return (
    <Formik
      initialValues={{email: ''}}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <Wrapper alignItems="stretch" direction="column" gap={24}>
          <div>
            <Text style={{marginBottom: 8}}>
              {t`Sign in with your Bitrix24, Kommo, monday or Whatcrm account.`}
            </Text>

            <Email />
          </div>
          <SaveButton>{t`Continue`}</SaveButton>
        </Wrapper>
      </Form>
    </Formik>
  );
};

export default Identification;
