import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface InstanceContext {
  instance: AppEnv.Instance;
}

const InstanceContext = React.createContext<InstanceContext>(
  {} as InstanceContext
);

export const useInstanceContext = () => useContext(InstanceContext);
export default InstanceContext;
