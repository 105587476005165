import React from 'react';
import {useField} from 'formik';

import ChatType from './chat-type/chat-type';
import ChatMessageType from './chat-message-type/chat-message-type';

const Chat = () => {
  const [field] = useField<boolean>('is_chat');
  return (
    <>
      {field.value ? (
        <>
          <ChatType />
          <ChatMessageType />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Chat;
