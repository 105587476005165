import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import * as AppEnv from 'app-env';

const Theme = () => {
  const [field, , helpers] = useField<AppEnv.WidgetTheme>('theme');
  const {t} = useTranslation();

  const options: ChipsAltOption<AppEnv.WidgetTheme>[] = [
    {
      img: '/static/widget/theme/light.svg',
      label: t`Light`,
      value: 'light'
    },
    {
      img: '/static/widget/theme/dark.svg',
      label: t`Dark`,
      value: 'dark'
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Theme`}</Title.H3>
      <ChipsAlt {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default Theme;
