import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../../../message-context';
import * as AppEnv from 'app-env';

const useQuotedMsg = () => {
  const {instance} = useDispatchContext();
  const {message} = useMessageContext();

  let isStory = false;

  if ('quotedMsg' in message) {
    const {quotedMsg} = message;

    if (instance.version == 'telegram' && quotedMsg) {
      (quotedMsg as AppEnv.TelegramMessage).senderId = null;
      if ('senderId' in quotedMsg && quotedMsg._ == 'story') isStory = true;
    }

    return {isStory, quotedMsg};
  } else if ('quote' in message) {
    return {isStory, quotedMsg: message.quote};
  }

  return {isStory};
};

export default useQuotedMsg;
