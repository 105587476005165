import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface MessageContext {
  message: AppEnv.Message;
}

const MessageContext = React.createContext<MessageContext>(
  {} as MessageContext
);

export const useMessageContext = () => useContext(MessageContext);
export default MessageContext;
