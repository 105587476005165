import React from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Placeholder} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import {useEventsWs} from './hooks';
import Instance from './instance/instance';

const InstanceList = () => {
  const {user} = useWorkspaceContext();
  const {t} = useTranslation();
  useEventsWs();

  const instances = Object.values(user.instances);

  return (
    <>
      {instances.length ? (
        <Grid>
          {instances.map(item => (
            <Instance instance={item} key={item.id} />
          ))}
        </Grid>
      ) : (
        <Placeholder title={t`No connections`} isMaxHeight />
      )}
    </>
  );
};

export default InstanceList;
