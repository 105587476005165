import React, {forwardRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import cn from 'classnames';

import {Preloader} from 'components';
import {useChatContext} from '../chat-context';
import {useScrollClassName} from 'common/hooks';

import {getEditDate} from './actions';
import {SystemMsg} from './components';
import {useMsgList} from './hooks';
import Sync from './sync/sync';
import Thread from './thread/thread';

const Convo = forwardRef<HTMLDivElement>((props, ref) => {
  const {dialog} = useChatContext();

  const [isAllMsgsReceived, setIsAllMsgsReceived] = useState(false);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);

  const {t} = useTranslation();
  const {getMsgList} = useMsgList(setIsAllMsgsReceived);

  useEffect(() => {
    if (!ref || typeof ref != 'object') return;
    const {clientHeight, scrollHeight, scrollTop} = ref.current || {};

    if (dialog.messages && scrollHeight == prevScrollHeight)
      getMsgList(isAllMsgsReceived);

    if (
      clientHeight == undefined ||
      scrollHeight == undefined ||
      scrollTop == undefined
    )
      return;

    if (scrollTop == 0)
      ref.current?.scrollTo({top: scrollHeight - prevScrollHeight});
    else if (scrollHeight - (scrollTop + clientHeight) < 512)
      ref.current?.scrollTo({top: scrollHeight, behavior: 'smooth'});

    setPrevScrollHeight(scrollHeight);
  }, [dialog.messages?.length]);

  useEffect(() => {
    if (!dialog.messages) getMsgList(isAllMsgsReceived);
  }, []);

  const handleScroll = () => {
    if (!ref || typeof ref != 'object') return;

    const {scrollTop, clientHeight} = ref.current || {};
    if ((scrollTop || 0) < (clientHeight || 0)) getMsgList(isAllMsgsReceived);
  };

  const scrollClassName = useScrollClassName();

  return (
    <div
      className={cn('chat__convo', {[scrollClassName]: scrollClassName})}
      ref={ref}
      onScroll={_.debounce(handleScroll)}
    >
      {dialog.messages ? (
        <div className="chat__convo-wrapper">
          <Sync
            getMsgList={getMsgList}
            isAllMsgsReceived={isAllMsgsReceived}
            setIsAllMsgsReceived={setIsAllMsgsReceived}
          />

          {dialog.messages.length > 0 ? (
            dialog.messages.map((message, i) => (
              <Thread
                msg={message}
                index={i}
                key={`${message.id}-${getEditDate(message)}`}
              />
            ))
          ) : (
            <SystemMsg>{t`No messages`}</SystemMsg>
          )}
        </div>
      ) : (
        <Preloader isMaxHeight />
      )}
    </div>
  );
});

Convo.displayName = 'Convo';
export default Convo;
