import React from 'react';
import {isolateNumbers} from 'whatcrm-core';
import {parseVCards} from 'vcard4-ts';

import UserMessage from '../../components/user-message/user-message';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';

import Contact from './contact/contact';
import {Wrapper} from 'components';

const Vcard = () => {
  const {message} = useWhatsAppMessageContext();

  const {vCards = undefined} = message.vCards?.[0]
    ? parseVCards(message?.vCards?.[0])
    : {};

  const vcard = {
    name: vCards?.[0].FN[0].value,
    phoneNumber: vCards?.[0].TEL?.[0].value
      ? isolateNumbers(vCards[0].TEL[0].value)
      : undefined
  };

  return (
    <UserMessage>
      <Wrapper direction="column" gap={12}>
        <Contact vcard={vcard} />
      </Wrapper>
    </UserMessage>
  );
};

export default Vcard;
