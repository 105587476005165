import React from 'react';

import {Ack} from 'components';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useDialogContext} from '../dialog-context';
import {getDialogLastMessage} from 'common/actions';

import Datetime from './datetime/datetime';

const Metadata = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useDialogContext();

  const lastMessage = getDialogLastMessage(dialog);

  return (
    <div className="dialog__metadata">
      {lastMessage && (
        <Ack dialog={dialog} instance={instance} message={lastMessage} />
      )}

      <Datetime />
    </div>
  );
};

export default Metadata;
