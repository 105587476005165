import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface TelegramMessageContext {
  message: AppEnv.TelegramMessage;
}

const TelegramMessageContext = React.createContext<TelegramMessageContext>(
  {} as TelegramMessageContext
);

export const useTelegramMessageContext = () =>
  useContext(TelegramMessageContext);

export default TelegramMessageContext;
