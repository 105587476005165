import {useState} from 'react';
import * as AppEnv from 'app-env';
import useInitialIsSidebarActive from './use-initial-is-sidebar-active/use-initial-is-sidebar-active';

const useIsSidebarActive = (): [boolean, AppEnv.SetState<boolean>] => {
  const initialIsSidebarActive = useInitialIsSidebarActive();

  const [isSidebarActive, setIsSidebarActive] = useState(
    initialIsSidebarActive
  );

  return [isSidebarActive, setIsSidebarActive];
};

export default useIsSidebarActive;
