import {useState} from 'react';
import update from 'immutability-helper';

import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const useGetMyProfileImage = () => {
  const {setUser} = useWorkspaceContext();
  const {dialogs, instance} = useDispatchContext();

  const [isRequested, setIsRequested] = useState(false);
  const {fetchProfileImage} = useRequest();

  const getMyProfileImage = async () => {
    if (instance.version == 'chat' || isRequested) return;
    setIsRequested(true);

    const dialog = dialogs[instance.id]?.find(
      item =>
        item.version == 'whatcrm' &&
        item.id._serialized == `${instance.phone}@c.us`
    );

    let {profileImage = undefined} =
      dialog && 'profileImage' in dialog ? dialog : {};

    if (!profileImage) {
      const chatId = instance.me ? instance.me.id : `${instance.phone}@.c.us`;

      profileImage =
        (await fetchProfileImage(instance.chat_key, chatId)) || undefined;
    }

    if (!profileImage) return;

    setUser(prevValue =>
      update(prevValue, {
        instances: {[instance.id]: {profileImage: {$set: profileImage}}}
      })
    );
  };

  return getMyProfileImage;
};

export default useGetMyProfileImage;
