import React, {useState, useRef} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import cn from 'classnames';

import {useChatContext} from '../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {getIsNewMessageAvailable} from 'common/actions';
import {
  useEditMessage,
  useSendAttachs,
  useSendPoll,
  useSendText,
  useSendVoice
} from './hooks';
import * as FooterEnv from './footer-env';
import FooterContext from './footer-context';
import MessageInput from './message-input/message-input';
import Reply from './reply/reply';
import ScrollDown from './scroll-down/scroll-down';

const Footer = () => {
  const {user} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg, setQuotedMsg} = useChatContext();

  const [isAttachsFormActive, setIsAttachsFormActive] = useState(false);
  const [isPollActive, setIsPollActive] = useState(false);
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);
  const [progress, setProgress] = useState<number[]>([]);

  const [templateState, setTemplateState] = useState<AppEnv.TemplateState>({
    isActive: false
  });

  const editMessage = useEditMessage();
  const sendAttachs = useSendAttachs(setProgress);
  const sendPoll = useSendPoll();
  const sendText = useSendText();
  const sendVoice = useSendVoice(setIsVoiceRecording);
  const textRef = useRef<HTMLTextAreaElement>(null);

  const sendMessage = (values: FooterEnv.Values) => {
    const processedValues = {...values};

    if (user.integration.is_manager && processedValues.text) {
      const bold = dialog.version == 'whatcrm' ? '*' : '';
      processedValues.text = processedValues.text.replace(/^\*.*:\*\n/, '');

      processedValues.text =
        `${bold}${user.manager.fullname}:${bold}\n` + processedValues.text;
    }

    if (quotedMsg?.isEditing) return editMessage(processedValues);
    else if (values.attachs.length) return sendAttachs(processedValues);
    else if (values.body) return sendPoll(processedValues);
    else if (values.voice) return sendVoice(processedValues);

    return sendText(processedValues);
  };

  const handleSubmit = async (
    values: FooterEnv.Values,
    helpers: FormikHelpers<FooterEnv.Values>
  ) => {
    if (!values.attachs.length && !values.body && !values.text && !values.voice)
      return;

    const res = await sendMessage(values);
    if (!res) return;

    if (isAttachsFormActive) {
      setIsAttachsFormActive(false);
      await new Promise(resolve => setTimeout(resolve, 150));
    } else if (isPollActive) {
      setIsPollActive(false);
      await new Promise(resolve => setTimeout(resolve, 150));
    }

    helpers.resetForm();
    setQuotedMsg(undefined);
  };

  const initialValues: FooterEnv.Values = {
    allowMultipleAnswers: false,
    attachs: [],
    body: '',
    buttons: [{body: ''}, {body: ''}],
    text: '',
    voice: undefined
  };

  const isNewMessageAvailable = getIsNewMessageAvailable(instance, dialog);

  return (
    <FooterContext.Provider
      value={{
        isAttachsFormActive,
        isPollActive,
        isVoiceRecording,
        progress,
        setIsAttachsFormActive,
        setIsPollActive,
        setIsVoiceRecording,
        setTemplateState,
        templateState
      }}
    >
      <div className={cn('footer', {footer_alt: !isNewMessageAvailable})}>
        <div className="footer__container">
          <ScrollDown />
        </div>

        {isNewMessageAvailable && (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
              {quotedMsg && <Reply />}
              <MessageInput ref={textRef} textRef={textRef} />
            </Form>
          </Formik>
        )}
      </div>
    </FooterContext.Provider>
  );
};

export default Footer;
