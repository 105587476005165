import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ChatContext {
  convoRef: React.RefObject<HTMLDivElement>;
  dialog: AppEnv.Dialog;
  isDropzoneActive: boolean;
  quotedMsg: AppEnv.QuotedMsg | undefined;
  setQuotedMsg: AppEnv.SetState<AppEnv.QuotedMsg | undefined>;
  setWallpaperId: AppEnv.SetState<string | undefined>;
  telegramUser: AppEnv.TelegramUser | null | undefined;
  wallpaperId: string | undefined;
}

const ChatContext = React.createContext<ChatContext>({} as ChatContext);

export const useChatContext = () => useContext(ChatContext);
export default ChatContext;
