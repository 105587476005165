import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useMessageContext} from '../../../message-context';
import {useGetMessagePreview} from 'common/hooks';

const useCopyMessage = () => {
  const {setNotification} = useAppContext();
  const {message} = useMessageContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const getMessagePreview = useGetMessagePreview();

  const messagePreview = getMessagePreview(message);

  const copyMessage = async () => {
    if (!messagePreview)
      return setNotification({title: t`Failed to copy the message`});

    const res = await copyToClipboard(messagePreview);

    setNotification({
      title: res.success
        ? t`The message has been copied to clipboard`
        : res.message
    });
  };

  return copyMessage;
};

export default useCopyMessage;
