import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text, Wrapper} from 'components';

import BottomMobile from './bottom-mobile/bottom-mobile';
import RightMobile from './right-mobile/right-mobile';

const PositionMobile = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Text size={16}>{t`Mobile version`}</Text>

      <Text size={16} style={{marginBottom: 8}}>
        {t`Defaults to the right 40px, bottom 40px`}
      </Text>

      <Wrapper gap={8}>
        <BottomMobile />
        <RightMobile />
      </Wrapper>
    </div>
  );
};

export default PositionMobile;
