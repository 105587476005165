import {useEffect, useState} from 'react';
import {isolateNumbers} from 'whatcrm-core';

import {getUrlParams} from 'common/actions';

const filter = (value: string, index: number, array: string[]) =>
  array.indexOf(value) == index;

const useIframeList = () => {
  const [chatIdList, setChatIdList] = useState<string[]>();
  const [phoneNumberList, setPhoneNumberList] = useState<string[]>();
  const [usernameList, setUsernameList] = useState<string[]>();

  const getIframeList = () => {
    const urlParams = getUrlParams();

    const chatIdList = urlParams.chat_id?.split(',').filter(filter);
    const phoneNumbersList = urlParams.phone_numbers?.split(',').filter(filter);
    const usernamesList = urlParams.usernames?.split(',').filter(filter);

    setChatIdList(
      chatIdList?.map(item => item.replaceAll('+', '').replaceAll(' ', ''))
    );

    setPhoneNumberList(phoneNumbersList?.map(item => isolateNumbers(item)));
    setUsernameList(usernamesList?.map(item => item.replaceAll('@', '')));
  };

  useEffect(() => {
    getIframeList();
  }, []);

  return {chatIdList, phoneNumberList, usernameList};
};

export default useIframeList;
