import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Toggle} from 'components';

const IsPulse = () => {
  const [field, , helpers] = useField<boolean>('is_pulse');
  const {t} = useTranslation();

  return (
    <Toggle
      {...field}
      label={t`Button pulsation`}
      onChange={helpers.setValue}
    />
  );
};

export default IsPulse;
