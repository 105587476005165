import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Confirm, ContextMenu} from 'components';
import {useDeleteDialog} from '../../../../../dispatch/hooks';
import {useDialogContext} from '../dialog-context';
import * as AppEnv from 'app-env';

import useOptions from './use-options';

interface TriggerPostion {
  left: number;
  top: number;
}

interface ContextMenuProvider {
  dialogRef: React.RefObject<HTMLDivElement>;
  triggerPosition: TriggerPostion | undefined;
  isContextMenuActive: boolean;
  setIsContextMenuActive: AppEnv.SetState<boolean>;
}

const ContextMenuProvider = ({
  dialogRef,
  triggerPosition,
  isContextMenuActive,
  setIsContextMenuActive
}: ContextMenuProvider) => {
  const {dialog} = useDialogContext();
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {t} = useTranslation();
  const deleteDialog = useDeleteDialog(dialog, setIsConfirmationActive);
  const options = useOptions(setIsConfirmationActive);

  const getTitle = () => {
    if (dialog.version == 'telegram')
      return t`Are you sure you want to delete this chat?`;

    return t`Are you sure you want to clear this chat?`;
  };

  const handleClick = (res: boolean) =>
    res ? deleteDialog() : setIsConfirmationActive(false);

  const title = getTitle();

  return (
    <>
      <ContextMenu
        options={options}
        triggerPosition={triggerPosition}
        triggerRef={dialogRef}
        isActive={isContextMenuActive}
        setIsActive={setIsContextMenuActive}
      />

      <Confirm
        title={title}
        isActive={isConfirmationActive}
        onClick={handleClick}
      />
    </>
  );
};

export default ContextMenuProvider;
