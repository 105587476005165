import React from 'react';
import cn from 'classnames';

import {Icons} from 'components';

interface Preloader {
  isMaxHeight?: boolean;
}

const Preloader = ({isMaxHeight}: Preloader) => (
  <div className={cn('preloader', {'preloader_max-height': isMaxHeight})}>
    <Icons.CircleNotch />
  </div>
);

export default Preloader;
