import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';

import {useAppContext} from 'app-context';
import {useGetInstanceLabel} from 'common/hooks';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import ChatProvider from './chat-provider/chat-provider';
import Dialogs from './dialogs/dialogs';
import DispatchContext from './dispatch-context';
import {useRequestDialogProfileImage} from './hooks';

interface Dispatch {
  dialogs: AppEnv.Dialogs;
  instance: AppEnv.Instance;
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const Dispatch = ({dialogs, instance, setDialogs}: Dispatch) => {
  const {isIframe} = useAppContext();
  const [folder, setFolder] = useState<AppEnv.Folder>(null);

  const {dialogId} = useParams();
  const getInstanceLabel = useGetInstanceLabel();
  const navigate = useNavigate();

  const requestDialogProfileImage = useRequestDialogProfileImage(
    instance,
    setDialogs
  );

  useEffect(() => {
    const instanceLabel = getInstanceLabel(instance);
    document.title = `${instanceLabel} - Whatcrm Web`;
  }, [instance.id]);

  useEffect(() => {
    if (instance.version == 'whatcrm') return;
    if (instance.version == 'telegram' && folder != 'archive') return;

    setFolder(null);
  }, [instance.version]);

  useEffect(() => {
    if (!isIframe) return;

    const dialogList = dialogs[instance.id];
    if (!dialogList || dialogList.length != 1) return;

    const id = getDialogId(dialogList[0]);
    if (!id) return;

    const to = `/messenger/${instance.id}/${id}`;
    setTimeout(() => navigate(to, {replace: isIframe}), 150);
  }, [instance.id]);

  const getDialog = () => {
    if (!dialogId) return;

    const dialog = dialogs[instance.id]?.find(dialog => {
      if (dialog.version == 'avito') return dialog.id == dialogId;
      else if (dialog.version == 'chat') return dialog.id == dialogId;
      else if (dialog.version == 'telegram')
        return dialog.id == parseInt(dialogId);
      else if (dialog.version == 'whatcrm') {
        const {id} = dialog;
        return id.user == dialogId;
      }
    });

    return dialog;
  };

  const dialog = getDialog();

  return (
    <DispatchContext.Provider
      value={{
        dialogs,
        folder,
        instance,
        requestDialogProfileImage,
        setDialogs,
        setFolder
      }}
    >
      <Dialogs />
      <ChatProvider dialog={dialog} />
    </DispatchContext.Provider>
  );
};

export default Dispatch;
