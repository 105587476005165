import React, {useEffect} from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import {
  CoreEnv,
  getFromLocalStorage,
  putInLocalStorage,
  removeFromLocalStorage
} from 'whatcrm-core';
import update from 'immutability-helper';

import {
  Body,
  Nav,
  Page,
  PageBody,
  PageFooter,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import IsManager from './is-manager/is-manager';
import IsTextTone from './is-text-tone/is-text-tone';
import Theme from './theme/theme';

interface Values {
  is_manager: 0 | 1;
  isTextTone: boolean;
  theme: CoreEnv.Theme;
}

const Settings = () => {
  const {setNotification, setTheme, theme} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const {t} = useTranslation();
  const {postIntegrationsUpdate} = useRequest();

  useEffect(() => {
    document.title = `${t`Settings`} - Whatcrm Web`;
  }, []);

  const pushNotification = () => {
    setNotification({title: t`The settings have been saved`});
    return true;
  };

  const updateTextTone = (isTextTone: boolean) => {
    if (isTextTone) return putInLocalStorage('textTone', '1');
    removeFromLocalStorage('textTone');
  };

  const handleSubmit = async (values: Values) => {
    const {integration} = user;

    setTheme(values.theme);
    updateTextTone(values.isTextTone);

    if (values.is_manager == integration.is_manager) return pushNotification();

    const res = await postIntegrationsUpdate(
      user.integration.type,
      user.integration.domain,
      values.is_manager
    );

    if (!res) return true;

    setUser(prevValue =>
      update(prevValue, {integration: {is_manager: {$set: res.is_manager}}})
    );

    return pushNotification();
  };

  const initialValues: Values = {
    is_manager: user.integration.is_manager,
    isTextTone: getFromLocalStorage('textTone') == '1',
    theme
  };

  return (
    <Body>
      <Page>
        <Nav
          options={[{label: t`Settings`, value: '/settings'}]}
          value="/settings"
        />

        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <PageBody>
              <Wrapper direction="column" gap={16}>
                <div>
                  <Title.H4 style={{marginBottom: 8}}>{t`Appearance`}</Title.H4>
                  <Theme />
                </div>

                <Wrapper direction="column" gap={8}>
                  <Title.H4>{t`Chat`}</Title.H4>
                  <IsManager />
                  <IsTextTone />
                </Wrapper>
              </Wrapper>
            </PageBody>

            <PageFooter>
              <SaveButton>{t`Save`}</SaveButton>
            </PageFooter>
          </Form>
        </Formik>
      </Page>
    </Body>
  );
};

export default Settings;
