import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Toggle} from 'components';

const IsChat = () => {
  const [field, , helpers] = useField<boolean>('is_chat');
  const {t} = useTranslation();

  return (
    <Toggle
      {...field}
      label={t`“Chat with manager” button`}
      onChange={helpers.setValue}
    />
  );
};

export default IsChat;
