import React from 'react';
import {LangSelect} from 'components';

const Side = () => (
  <div className="auth__side">
    <div className="logo" />
    <div className="auth__web" />
    <LangSelect />
  </div>
);

export default Side;
