import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import mime from 'mime';

import {ContextMenu, IconsAlt} from 'components';
import {ContextMenuOption} from 'components/context-menu/context-menu';
import {
  downloadFile,
  getBlob,
  getIsLimited,
  getIsNewMessageAvailable
} from 'common/actions';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useMessageContext} from '../../../message-context';
import * as AppEnv from 'app-env';

import {getIsEditingAvailable} from './actions';
import ContextMenuProviderContext from './context-menu-provider-context';
import DeleteMessage from './delete-message/delete-message';
import Forward from './forward/forward';
import ReactionPicker from './reaction-picker/reaction-picker';
import ReactionTrigger from './reaction-trigger/reaction-trigger';
import useCopyMessage from './use-copy-message';

interface TriggerPostion {
  left: number;
  top: number;
}

interface ContextMenuProvider {
  isContextMenuActive: boolean;
  isReactionPickerActive: boolean;
  setIsContextMenuActive: AppEnv.SetState<boolean>;
  setIsReactionPickerActive: AppEnv.SetState<boolean>;
  triggerPosition: TriggerPostion | undefined;
}

const getIsDeletingAvailable = (
  dialog: AppEnv.Dialog,
  instance: AppEnv.Instance,
  message: AppEnv.Message
) => {
  const isNewMessageAvailable = getIsNewMessageAvailable(instance, dialog);
  if (!isNewMessageAvailable) return false;

  const isLimited = getIsLimited(instance);
  if (isLimited) return false;

  const elapsedTimeLimit = 3600;
  if (!('created' in message)) return true;

  const isDueTime = +new Date() / 1000 - message.created < elapsedTimeLimit;
  return isDueTime;
};

const ContextMenuProvider = ({
  isContextMenuActive,
  isReactionPickerActive,
  setIsContextMenuActive,
  setIsReactionPickerActive,
  triggerPosition
}: ContextMenuProvider) => {
  const {instance} = useDispatchContext();
  const {dialog, setQuotedMsg} = useChatContext();
  const {message} = useMessageContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isForwardActive, setIsForwardActive] = useState(false);

  const {t} = useTranslation();
  const copyMessage = useCopyMessage();

  const updateQuotedMsg = (isEditing?: boolean) =>
    setQuotedMsg({isEditing, message});

  const getReplyOption = (): ContextMenuOption | null => {
    if (!['telegram', 'whatcrm', 'chat'].includes(instance.version))
      return null;

    return {
      icon: <IconsAlt.Reply />,
      label: t`Reply`,
      onClick: () => updateQuotedMsg()
    };
  };

  const getCopyOptions = (): ContextMenuOption => ({
    label: t`Copy`,
    icon: <IconsAlt.Files />,
    onClick: copyMessage
  });

  const getEditOption = (): ContextMenuOption | null => {
    if (
      !('sender_id' in message) &&
      !('senderId' in message) &&
      !('_data' in message)
    )
      return null;

    const isEditingAvailable = getIsEditingAvailable(instance, dialog, message);
    if (!isEditingAvailable) return null;

    const isNewMessageAvailable = getIsNewMessageAvailable(instance, dialog);
    if (!isNewMessageAvailable) return null;

    return {
      icon: <IconsAlt.PencilSimple />,
      label: t`Edit`,
      onClick: () => updateQuotedMsg(true)
    };
  };

  const getFileUrl = () => {
    if ('sender_id' in message) return message.file_url;
    else if ('senderId' in message || '_data' in message) return message.body;

    return null;
  };

  const download = async () => {
    const fileUrl = getFileUrl();
    if (!fileUrl) return;

    const blob = await getBlob(fileUrl);
    downloadFile(blob || fileUrl, '', !blob);
  };

  const getDownloadOption = (): ContextMenuOption | null => {
    if (
      !('sender_id' in message) &&
      !('senderId' in message) &&
      !('_data' in message)
    )
      return null;

    const isChatImage =
      'sender_id' in message &&
      message.type == 'file' &&
      mime.getType(message.file_url)?.startsWith('image');

    const isImage =
      (message.type == 'photo' || message.type == 'image') && message.body;

    if (!isChatImage && !isImage) return null;

    return {
      icon: <IconsAlt.DownloadSimple />,
      label: t`Download`,
      onClick: () => download()
    };
  };

  const getForwardOption = (): ContextMenuOption | null => {
    if (!('_data' in message)) return null;

    const {_data} = message;
    if (_data.isViewOnce) return null;

    const isLimited = getIsLimited(instance);
    if (isLimited) return null;

    return {
      icon: <IconsAlt.Forward />,
      label: t`Forward`,
      onClick: () => setIsForwardActive(true)
    };
  };

  const getDeleteOption = (): ContextMenuOption | null => {
    const isDeletingAvailable = getIsDeletingAvailable(
      dialog,
      instance,
      message
    );

    if (!isDeletingAvailable) return null;

    return {
      icon: <IconsAlt.TrashSimple />,
      label: t`Delete`,
      onClick: () => setIsConfirmationActive(true)
    };
  };

  const getOptions = () =>
    [
      getReplyOption(),
      getCopyOptions(),
      getEditOption(),
      getDownloadOption(),
      getForwardOption(),
      getDeleteOption()
    ].filter(item => item != null);

  const isLimited = getIsLimited(instance);

  const isReactions =
    ['chat', 'telegram', 'whatcrm'].includes(dialog.version) && !isLimited;

  const options = getOptions() as ContextMenuOption[];

  return (
    <ContextMenuProviderContext.Provider value={{setIsContextMenuActive}}>
      <ContextMenu
        isActive={isContextMenuActive}
        options={options}
        setIsActive={setIsContextMenuActive}
        triggerPosition={triggerPosition}
      >
        {isReactions && (
          <ReactionTrigger
            isContextMenuActive={isContextMenuActive}
            setIsReactionPickerActive={setIsReactionPickerActive}
          />
        )}
      </ContextMenu>

      <DeleteMessage
        isConfirmationActive={isConfirmationActive}
        setIsConfirmationActive={setIsConfirmationActive}
      />

      <Forward
        isForwardActive={isForwardActive}
        setIsForwardActive={setIsForwardActive}
      />

      {isReactions && (
        <ReactionPicker
          isReactionPickerActive={isReactionPickerActive}
          setIsReactionPickerActive={setIsReactionPickerActive}
          triggerPosition={triggerPosition}
        />
      )}
    </ContextMenuProviderContext.Provider>
  );
};

export default ContextMenuProvider;
