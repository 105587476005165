import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import {
  useActiveInstances,
  useDialogs,
  useFavicon,
  useIsSidebarActive,
  useMe,
  useUpdateMessageBody
} from './hooks';
import Connections from './pages/сonnections/connections';
import Header from './header/header';
import Messenger from './pages/messenger/messenger';
import Settings from './pages/settings/settings';
import Sidebar from './sidebar/sidebar';
import Templates from './pages/templates/templates';
import WebSocketProvider from './web-socket-provider/web-socket-provider';
import Widget from './pages/widget/widget';
import WorkspaceContext from './workspace-context';

interface Workspace {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
  user: AppEnv.User;
}

const Workspace = ({setUser, user}: Workspace) => {
  const {isIframe} = useAppContext();

  const [instanceReferer, setInstanceReferer] = useState<string>();
  const [tariffs, setTariffs] = useState<AppEnv.Tariffs | null>();

  const activeInstances = useActiveInstances(user);
  const [dialogs, setDialogs] = useDialogs(user, activeInstances);

  const [isSidebarActive, setIsSidebarActive] = useIsSidebarActive();

  const {updateMessageBody, updateQuotedMsgBody} = useUpdateMessageBody(
    dialogs,
    setDialogs
  );

  useFavicon(dialogs);
  useMe(activeInstances, setUser);

  return (
    <WorkspaceContext.Provider
      value={{
        activeInstances,
        instanceReferer,
        setInstanceReferer,
        setTariffs,
        setUser,
        tariffs,
        updateMessageBody,
        updateQuotedMsgBody,
        user
      }}
    >
      <div className="workspace">
        <Header
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />

        <Sidebar
          dialogs={dialogs}
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />

        <Routes>
          <Route
            path="/connections/*"
            element={<Connections setDialogs={setDialogs} />}
          />

          <Route
            path="/messenger/:instanceId?/:dialogId?"
            element={<Messenger dialogs={dialogs} setDialogs={setDialogs} />}
          />

          <Route path="/settings" element={<Settings />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/widget/*" element={<Widget />} />

          <Route
            path="*"
            element={<Navigate replace={isIframe} to="/messenger" />}
          />
        </Routes>
      </div>

      {dialogs && (
        <WebSocketProvider dialogs={dialogs} setDialogs={setDialogs} />
      )}
    </WorkspaceContext.Provider>
  );
};

export default Workspace;
