import React from 'react';
import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Item} from '../components';
import {useAppContext} from 'app-context';

import {useGuest} from './hooks';

const Domain = () => {
  const {setNotification} = useAppContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const guest = useGuest();

  const handleClick = async () => {
    if (!guest) return;

    const res = await copyToClipboard(guest.domain);
    setNotification({title: res.message});
  };

  return (
    <>
      {guest && (
        <div className="profile__list">
          <Item
            label={t`Domain`}
            type="copy"
            value={guest.domain}
            onClick={handleClick}
          />
        </div>
      )}
    </>
  );
};

export default Domain;
