import React from 'react';
import NavItem, {NavItemParams} from './nav-item/nav-item';

interface NavProps {
  options: NavItemParams[];
  style?: React.CSSProperties;
  value: string;
}

const Nav = ({options, style, value}: NavProps) => (
  <div className="nav" style={style}>
    {options.map((item, i) => (
      <NavItem key={i} navItem={item} isActive={item.value == value} />
    ))}
  </div>
);

export default Nav;
