import React from 'react';

import {MessageText, UserMessage} from '../../components';
import {Radio} from 'components';
import {useTelegramMessageContext} from '../telegram-message-context';

const Poll = () => {
  const {message} = useTelegramMessageContext();

  const options = message.content.poll?.options.map(item => ({
    label: item.text,
    value: item.isChosen ? 'true' : 'false',
    votePercentage: item.votePercentage
  }));

  const isVoted = options?.find(item => item.value == 'true');

  return (
    <UserMessage>
      <MessageText style={{marginBottom: 12}} isBold>
        {message.content.poll?.question}
      </MessageText>

      <Radio
        options={options || []}
        value="true"
        style={isVoted ? {opacity: 'initial'} : undefined}
        isDisabled
        onChange={() => {}}
      />
    </UserMessage>
  );
};

export default Poll;
