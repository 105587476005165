import React, {useState, useEffect, useRef} from 'react';
import {convert} from 'tgs2lottie';

import {Media, Text} from 'components';
import UserMessage from '../../components/user-message/user-message';
import {useTelegramMessageContext} from '../telegram-message-context';
import useRemoteFile from '../common/use-remote-file';

const AnimatedEmoji = () => {
  const {message} = useTelegramMessageContext();
  const [lottie, setLottie] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);

  useRemoteFile(ref, message.content.animatedEmoji?.sticker.sticker.remote.id);

  useEffect(() => {
    getLottie();
  }, [message.body]);

  const getLottie = async () => {
    if (lottie || !message.body || !message.body.endsWith('.tgs')) return;

    const res = await fetch(message.body).then(res => res.arrayBuffer());
    const buffer = new Uint8Array(res);
    setLottie(convert(buffer));
  };

  const getType = () => {
    if (lottie) return 'lottie';
    else if (message.body.endsWith('.webm')) return 'video';
  };

  const src = lottie || message.body;

  return (
    <UserMessage ref={ref}>
      {src && !src.endsWith('.tgs') ? (
        <Media
          height={144}
          src={lottie || message.body}
          type={getType()}
          width={144}
          isAutoPlay
          noZoom
        />
      ) : (
        <Text size={72}>{message.content.emoji} </Text>
      )}
    </UserMessage>
  );
};

export default AnimatedEmoji;
