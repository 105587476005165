import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface AvitoMessageContext {
  message: AppEnv.AvitoMessage;
}

const AvitoMessageContext = React.createContext<AvitoMessageContext>(
  {} as AvitoMessageContext
);

export const useAvitoMessageContext = () => useContext(AvitoMessageContext);
export default AvitoMessageContext;
