import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, PopupFooter, PopupWrapper, Text, Title} from 'components';
import {Label} from '../../../components';
import {useConnectorContext} from '../../connector-context';
import * as AppEnv from 'app-env';

interface Code {
  code: string;
  setCode: AppEnv.SetState<string | undefined>;
  setIsQrCodeMethod: AppEnv.SetState<boolean>;
}

const Code = ({code, setCode, setIsQrCodeMethod}: Code) => {
  const {getStatus} = useConnectorContext();
  const {t} = useTranslation();

  const handleClick = async () => {
    getStatus(1);
    setCode(undefined);
    setIsQrCodeMethod(true);
  };

  return (
    <>
      <PopupWrapper>
        <Label style={{marginBottom: 12}} />

        <Title.H4 style={{marginBottom: 16}}>
          {t`Enter this code in WhatsApp`}
        </Title.H4>

        <p className="code">
          <Text fontWeight={600} size={20}>
            {code.slice(0, 4)}
            {code.slice(4)}
          </Text>
        </p>
      </PopupWrapper>

      <PopupFooter>
        <Button color="fade" onClick={handleClick}>
          {t`Close`}
        </Button>
      </PopupFooter>
    </>
  );
};

export default Code;
