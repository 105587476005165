import React from 'react';

import {MessageLocation, MessageText, UserMessage} from '../../components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {Wrapper} from 'components';

const Location = () => {
  const {message} = useWhatsAppMessageContext();

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <MessageLocation lat={message._data.lat} lng={message._data.lng} />

        <div>
          <MessageText>{message._data.loc}</MessageText>

          {message._data.clientUrl && (
            <MessageText isSmall>
              <a
                className="message__link"
                href={message._data.clientUrl}
                target="_blank"
                rel="noreferrer"
              >
                {message._data.clientUrl}
              </a>
            </MessageText>
          )}
        </div>
      </Wrapper>
    </UserMessage>
  );
};

export default Location;
