import React, {useState, useEffect, useRef} from 'react';
import {convert} from 'tgs2lottie';

import {Media, Text} from 'components';
import UserMessage from '../../components/user-message/user-message';
import {useTelegramMessageContext} from '../telegram-message-context';
import useRemoteFile from '../common/use-remote-file';

const Sticker = () => {
  const {message} = useTelegramMessageContext();

  const [lottie, setLottie] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);
  useRemoteFile(ref, message.content.sticker?.sticker.remote.id);

  useEffect(() => {
    getLottie();
  }, [message.body]);

  const getLottie = async () => {
    if (lottie || !message.body || !message.body.endsWith('.tgs')) return;

    const res = await fetch(message.body).then(res => res.arrayBuffer());
    const buffer = new Uint8Array(res);
    setLottie(convert(buffer));
  };

  const getType = () => {
    if (lottie) return 'lottie';
    else if (message.body.endsWith('.webm')) return 'video';
  };

  const getSize = () => {
    if (!message.content.sticker) return {height: 288, width: 288};

    const delimiter =
      message.content.sticker.height > message.content.sticker.width
        ? message.content.sticker.height / 288
        : message.content.sticker.width / 288;

    return {
      height: message.content.sticker.height / delimiter,
      width: message.content.sticker.width / delimiter
    };
  };

  const src = lottie || message.body;

  return (
    <UserMessage ref={ref}>
      {src && !src.endsWith('.tgs') ? (
        <Media
          {...getSize()}
          src={src}
          type={getType()}
          isAutoPlay
          noSubstrate
          noZoom
        />
      ) : (
        <Text size={144}>{message.content.sticker?.emoji}</Text>
      )}
    </UserMessage>
  );
};

export default Sticker;
