import {useTranslation} from 'react-i18next';
import axios, {AxiosResponse} from 'axios';

import {useAppContext} from 'app-context';

interface Params {
  data?: object;
  method?: 'post';
  params?: object;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BITRIX24,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Token': process.env.REACT_APP_BITRIX24_TOKEN
  }
});

const useBitrix24Request = () => {
  const {setNotification} = useAppContext();
  const {t} = useTranslation();

  const handleThen = <T>(res: AxiosResponse): T | null => {
    if (!('detail' in res.data) && !('error' in res.data)) return res.data;

    const text = res.data.detail || res.data.detail || t`Something went wrong.`;
    setNotification({title: t`Error`, text});

    return null;
  };

  // eslint-disable-next-line
  const handleCatch = (e: any) => {
    const text = e.response.data?.detail || t`Something went wrong.`;
    setNotification({title: t`Error`, text});

    return null;
  };

  const request = async <T>(
    path: string,
    {data, method, params}: Params
  ): Promise<T | null> => {
    const res = await instance(path, {data, method, params})
      .then(handleThen<T>)
      .catch(handleCatch);

    return res;
  };

  const fetchVoiceMessage = (key: string, src: string) =>
    request<unknown>('voice_message', {
      data: {src},
      method: 'post',
      params: {key}
    });

  return {fetchVoiceMessage};
};

export default useBitrix24Request;
