import React from 'react';

import {getIsEmoji} from 'common/actions';
import {MessageText, UserMessage} from '../../components';
import {Text as CoreText} from 'components';
import {useTelegramMessageContext} from '../telegram-message-context';

const Text = () => {
  const {message} = useTelegramMessageContext();

  const {content} = message;
  const text = content.text?.text || content.caption?.text || '';
  const isEmoji = getIsEmoji(text);

  return (
    <UserMessage>
      <MessageText>
        {isEmoji ? <CoreText size={72}>{text}</CoreText> : text}
      </MessageText>
    </UserMessage>
  );
};

export default Text;
