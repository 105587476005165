import React from 'react';
import cn from 'classnames';

import {useScrollClassName} from 'whatcrm-core';

interface PageBody {
  children: React.ReactNode;
}

const PageBody = ({children}: PageBody) => {
  const scrollClassName = useScrollClassName();

  return (
    <div className={cn('page__body', {[scrollClassName]: scrollClassName})}>
      {children}
    </div>
  );
};

export default PageBody;
