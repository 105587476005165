const getWaveform = (arrayBuffer: ArrayBuffer) => {
  const uint8Array = new Uint8Array(arrayBuffer);
  let result = '';

  for (let i = 0; i < uint8Array.length; i++) {
    const fiveBitValue = uint8Array[i] & 0b11111;
    result += String.fromCharCode(fiveBitValue);
  }

  const base64String = btoa(result);
  return base64String;
};

export default getWaveform;
