import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

const useGetInstanceLabel = () => {
  const {t} = useTranslation();

  const getInstanceLabel = (instance: AppEnv.Instance) =>
    instance.label || `${t`Connection`} ${instance.id}`;

  return getInstanceLabel;
};

export default useGetInstanceLabel;
