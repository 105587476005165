import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface DialogContext {
  dialog: AppEnv.Dialog;
}

const DialogContext = React.createContext<DialogContext>({} as DialogContext);

export const useDialogContext = () => useContext(DialogContext);
export default DialogContext;
