import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ContextMenuProviderContext {
  setIsContextMenuActive: AppEnv.SetState<boolean>;
}

const ContextMenuProviderContext =
  React.createContext<ContextMenuProviderContext>(
    {} as ContextMenuProviderContext
  );

export const useContextMenuProviderContext = () =>
  useContext(ContextMenuProviderContext);

export default ContextMenuProviderContext;
