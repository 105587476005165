import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {Body, Button, Grid, Nav, Page, PageBody, Placeholder} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {TemplateEdit, TemplateEditValues} from './components';
import Guide from './guide/guide';
import Template from './template/template';

const Templates = () => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();
  const [isEditActive, setIsEditActive] = useState(false);

  const {postFilesLoadData, postTemplatesCreate, postTemplatesFileAdd} =
    useRequest();

  const {t} = useTranslation();

  const handleSubmit = async (values: TemplateEditValues) => {
    const {integration} = user;

    const template = await postTemplatesCreate(integration.id, values);
    if (!template) return true;

    const files: AppEnv.TemplateFile[] = [];

    const promises = values.attachs
      .filter(attach => !attach.isDelete)
      ?.map(async attach => {
        const {file} = attach;
        if (!file) return;

        const link = await postFilesLoadData(file, file.name);
        if (!link) return;

        const {integration} = user;

        const addedFile = await postTemplatesFileAdd(
          integration.id,
          template.id,
          {
            ...file,
            link,
            title: file.name
          }
        );

        if (!addedFile) return;
        files.push(addedFile);
      });

    await Promise.all(promises);
    setIsEditActive(false);

    setUser(prevValue =>
      upd(prevValue, {
        templates: {$push: [{...template, file_count: files.length, files}]}
      })
    );

    setNotification({text: t`Template created.`, title: template.title});

    return true;
  };

  return (
    <>
      <Body>
        <Page>
          <Nav
            options={[{label: t`Templates`, value: 'templates'}]}
            value="templates"
          />

          <PageBody>
            <Guide />

            <Button
              color="dashed"
              onClick={() => setIsEditActive(true)}
              style={{marginBottom: 16}}
            >
              {t`Add template`}
            </Button>

            {user.templates.length ? (
              <Grid>
                {user.templates.map((template, i) => (
                  <Template index={i} key={template.id} template={template} />
                ))}
              </Grid>
            ) : (
              <Placeholder isMaxHeight title={t`No templates`} />
            )}
          </PageBody>
        </Page>
      </Body>

      <TemplateEdit
        isActive={isEditActive}
        onSubmit={handleSubmit}
        setIsActive={setIsEditActive}
      />
    </>
  );
};

export default Templates;
