import React from 'react';
import {useChatContext} from '../../../chat-context';
import Participant from './participant/participant';

const Participants = () => {
  const {dialog} = useChatContext();

  return (
    <>
      {'groupMetadata' in dialog && (
        <div className="profile__list">
          {dialog.groupMetadata?.participants.map(item => (
            <Participant key={item.id._serialized} participant={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default Participants;
