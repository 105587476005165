import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const CallbackButtonText = () => {
  const [field, , helpers] = useField<string>('callback_button_text');
  const {t} = useTranslation();

  return (
    <Input
      {...field}
      placeholder={t`Button text`}
      onChange={helpers.setValue}
    />
  );
};

export default CallbackButtonText;
