import React from 'react';

interface PopupWrapper {
  children: React.ReactNode;
}

const PopupWrapper = ({children}: PopupWrapper) => (
  <div className="popup__wrapper">{children}</div>
);

export default PopupWrapper;
