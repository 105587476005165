import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface AuthContext {
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
}

const AuthContext = React.createContext<AuthContext>({} as AuthContext);

export const useAuthContext = () => useContext(AuthContext);
export default AuthContext;
