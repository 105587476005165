import React from 'react';
import {allTimezones, useTimezoneSelect} from 'react-timezone-select';
import {useField} from 'formik';

import {Select} from 'components';

const Timezone = () => {
  const [field, , helpers] = useField('timezone');

  const {options} = useTimezoneSelect({
    currentDatetime: new Date(),
    displayValue: 'UTC',
    timezones: allTimezones
  });

  return <Select {...field} onChange={helpers.setValue} options={options} />;
};

export default Timezone;
