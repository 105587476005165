import React, {RefObject, forwardRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, ContextMenu, Icons, IconsAlt} from 'components';
import {DeleteDialog, Wallpapers} from '../components';
import {useChatContext} from '../../chat-context';
import * as AppEnv from 'app-env';

interface ContextMenuProvider {
  setIsProfileActive: AppEnv.SetState<boolean>;
}

const ContextMenuProvider = forwardRef<HTMLDivElement, ContextMenuProvider>(
  ({setIsProfileActive}, ref) => {
    const {dialog} = useChatContext();

    const [isDeleteDialogActive, setIsDeleteDialogActive] = useState(false);
    const [isContextMenuActive, setIsContextMenuActive] = useState(false);
    const [isWallpapersActive, setIsWallpapersActive] = useState(false);

    const {t} = useTranslation();

    const options = [
      {
        icon: <IconsAlt.Question />,
        label: t`Information`,
        onClick: () => setIsProfileActive(true)
      },
      {
        icon: <IconsAlt.Brush />,
        label: t`Change the wallpaper`,
        onClick: () => setIsWallpapersActive(true)
      }
    ];

    if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
      options.push({
        icon: <IconsAlt.TrashSimple />,
        label: dialog.version == 'telegram' ? t`Delete chat` : t`Clear chat`,
        onClick: () => setIsDeleteDialogActive(true)
      });

    return (
      <div className="chat__header-menu" ref={ref}>
        <Button
          color="transparent"
          onClick={() => setIsContextMenuActive(prevValue => !prevValue)}
        >
          <Icons.Ellipsis />
        </Button>

        <DeleteDialog
          isActive={isDeleteDialogActive}
          setIsActive={setIsDeleteDialogActive}
        />

        <ContextMenu
          options={options}
          triggerRef={ref as RefObject<HTMLDivElement>}
          isActive={isContextMenuActive}
          isCustomPosition
          setIsActive={setIsContextMenuActive}
        />

        <Wallpapers
          isActive={isWallpapersActive}
          setIsActive={setIsWallpapersActive}
        />
      </div>
    );
  }
);

ContextMenuProvider.displayName = 'ContextMenuProvider';
export default ContextMenuProvider;
