import update from 'immutability-helper';

import {useChatContext} from '../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import useGetMyProfileImage from './use-get-my-profile-image/use-get-my-profile-image';
import {sortTgMsgs, sortTimestampMsgs} from './actions';

const useUpdMsgList = () => {
  const {dialogs, instance, setDialogs} = useDispatchContext();
  const {dialog} = useChatContext();

  const getMyProfileImage = useGetMyProfileImage();

  const updMsgList = (msgs: AppEnv.Message[]) => {
    const [msg] = msgs;

    const index = dialogs[instance.id]?.findIndex(
      item => getDialogId(item) == getDialogId(dialog)
    );

    if (index == undefined || index < 0) return;

    const chatName = msg && 'chatName' in msg ? msg.chatName : undefined;

    const sortedMsgs = dialog.messages ? [...msgs, ...dialog.messages] : msgs;

    if (dialog.version == 'avito' || dialog.version == 'whatcrm')
      sortedMsgs?.sort(sortTimestampMsgs);
    else if (dialog.version == 'telegram') sortedMsgs?.sort(sortTgMsgs);

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [index]: {
            messages: {$set: sortedMsgs as any}, // eslint-disable-line
            name: {$apply: target => chatName || target}
          }
        }
      })
    );

    if (!msgs.length) return;

    const isOutgoings = !![...msgs].find(item => {
      if ('direction' in item) return item.direction == 'out';
      else if ('isOutgoing' in item) return item.isOutgoing;
      else if ('fromMe' in item) return item.fromMe;
    });

    if (isOutgoings) getMyProfileImage();
  };

  return updMsgList;
};

export default useUpdMsgList;
