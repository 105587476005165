import React from 'react';

import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import * as AppEnv from 'app-env';

import Reaction from './reaction/reaction';
import {getReactions} from './actions';

interface Reactions {
  message: AppEnv.Message;
}

const Reactions = ({message}: Reactions) => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const reactions = getReactions(instance, dialog, message);

  return (
    <>
      {reactions?.length > 0 && (
        <div className="message__reactions">
          {reactions.map((item, i) =>
            item.emoji ? <Reaction key={i} reaction={item} /> : <></>
          )}
        </div>
      )}
    </>
  );
};

export default Reactions;
