import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ConnectionsMainContext {
  connectorId: number | undefined;
  setConnectorId: AppEnv.SetState<number | undefined>;
}

const ConnectionsMainContext = React.createContext<ConnectionsMainContext>(
  {} as ConnectionsMainContext
);

export const useConnectionsMainContext = () =>
  useContext(ConnectionsMainContext);

export default ConnectionsMainContext;
