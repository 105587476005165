import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Textarea, Text} from 'components';

const UnavailableText = () => {
  const [field, , helpers] = useField<string>('unavailable_text');
  const {t} = useTranslation();

  return (
    <div>
      <Text size={16} style={{marginBottom: 8}}>
        {t`Message text when all managers are unavailable`}
      </Text>

      <Textarea
        {...field}
        onChange={helpers.setValue}
        placeholder={t`Type text`}
      />
    </div>
  );
};

export default UnavailableText;
