import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {BulletList, IconParams, Icons, Text, Tooltip} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useFeatList} from './hooks';

interface FeatureList {
  tariffPlan: AppEnv.TariffPlan;
  tariffs: AppEnv.Tariffs;
}

const limits = {
  other: {Enterprise: 200, Pro: 100, Start: 50},
  photo: {Enterprise: 64, Pro: 32, Start: 16},
  video: {Enterprise: 128, Pro: 64, Start: 16}
};

const FeatureList = ({tariffPlan, tariffs}: FeatureList) => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();
  const {integration} = user;

  const featureList = useFeatList(
    integration.type,
    tariffPlan,
    'Enterprise' in tariffs
  );

  const {other, photo, video} = limits;
  const iconParams: IconParams = {color: 'blue', size: 20};

  return (
    <>
      <ul className="feature-list">
        {featureList.map((feature, i) => (
          <li
            className={cn('feature-list__item', {
              'feature-list__item_inactive': !feature.isChecked,
              'feature-list__item_tooltip':
                (feature.isChecked && feature.tooltip) || feature.isLimits
            })}
            key={i}
          >
            {feature.isChecked ? (
              <Icons.CheckAlt {...iconParams} />
            ) : (
              <Icons.CloseAlt {...iconParams} />
            )}

            {feature.name}

            {feature.isChecked && feature.tooltip && (
              <Tooltip>
                <Text color="white">{feature.tooltip}</Text>
              </Tooltip>
            )}

            {feature.isLimits && (
              <Tooltip>
                <BulletList
                  options={[
                    t('Photos up to {{photo}} MB', {photo: photo[tariffPlan]}),
                    t('Videos up to {{video}} MB after processing', {
                      video: video[tariffPlan]
                    }),
                    t('Other files up to {{other}} MB', {
                      other: other[tariffPlan]
                    })
                  ]}
                />
              </Tooltip>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default FeatureList;
