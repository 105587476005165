import React from 'react';
import cn from 'classnames';

import {
  getIsDialogMuted,
  getIsDialogPinned,
  getIsDialogUnread
} from 'common/actions';
import {Icons, Text} from 'components';
import {useDialogContext} from '../dialog-context';

const Badges = () => {
  const {dialog} = useDialogContext();

  const isMuted = getIsDialogMuted(dialog);
  const isPinned = getIsDialogPinned(dialog);
  const isUnread = getIsDialogUnread(dialog);

  const isVesion = dialog.version == 'telegram' || dialog.version == 'whatcrm';
  const isUnreadCount = isVesion && dialog.unreadCount > 0;

  return (
    <div className="dialog__badges">
      {isPinned && <Icons.Pin color="gray" />}

      {isUnread && (
        <div className={cn('dialog__count', {dialog__count_muted: isMuted})}>
          <Text color="white" size={12} fontWeight={600} textAlign="center">
            {isUnreadCount && dialog.unreadCount}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Badges;
