import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {getDialogId, getDialogName} from 'common/actions';
import {SystemMsg} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Sync {
  getMsgList: (isAllMsgsReceived: boolean) => Promise<void>;
  isAllMsgsReceived: boolean;
  setIsAllMsgsReceived: AppEnv.SetState<boolean>;
}

const Sync = ({getMsgList, isAllMsgsReceived, setIsAllMsgsReceived}: Sync) => {
  const {setNotification} = useAppContext();
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [count, setCount] = useState(0);
  const [isSynced, setIsSynced] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {fetchSyncHistory} = useRequest();
  const {t} = useTranslation();

  const handleClick = async () => {
    const id = getDialogId(dialog, true);
    if (typeof id != 'string') return;

    setIsFetching(true);

    const res = await fetchSyncHistory(instance.chat_key, id);
    const title = getDialogName(instance, dialog) || t`Chat`;

    if (res?.success) {
      setIsAllMsgsReceived(false);
      getMsgList(false);
      setIsSynced(true);
    } else {
      setNotification({
        text: t`Failed to get older messages. Open WhatsApp on your phone and try again.`,
        title
      });

      if (count) setIsSynced(true);
      setCount(prevValue => prevValue + 1);
    }

    setIsFetching(false);
  };

  const isShown = dialog.version == 'whatcrm' && isAllMsgsReceived;

  return (
    <>
      {isShown && (
        <SystemMsg
          isDisabled={isFetching}
          onClick={!isSynced ? handleClick : undefined}
        >
          {isSynced
            ? t`Use WhatsApp on your phone to view older messages.`
            : t`Get older messages from your phone`}
        </SystemMsg>
      )}
    </>
  );
};

export default Sync;
