import React from 'react';

import {MessageText, UserMessage} from '../../components';
import {useTelegramMessageContext} from '../telegram-message-context';
import {Wrapper} from 'components';
import TelegramMessagePhoto from '../components/telegram-message-photo/telegram-message-photo';

const Photo = () => {
  const {message} = useTelegramMessageContext();

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <TelegramMessagePhoto />
        {message.caption && <MessageText>{message.caption}</MessageText>}
      </Wrapper>
    </UserMessage>
  );
};

export default Photo;
