import React, {forwardRef, useEffect} from 'react';
import {useOnKeyDown} from 'whatcrm-core';
import {useField, useFormikContext} from 'formik';

import {useFooterContext} from '../../../footer-context';
import * as AppEnv from 'app-env';

import Dropzone from './dropzone/dropzone';
import Form from './form/form';
import Input from './input/input';

const Attachs = forwardRef<HTMLInputElement>((props, ref) => {
  const {isAttachsFormActive, setIsAttachsFormActive} = useFooterContext();
  const {isSubmitting, submitForm} = useFormikContext();

  const [field, ,] = useField<AppEnv.Attach[]>('attachs');

  useOnKeyDown(
    e => {
      if ((e.code == 'Enter' || e.code == 'NumpadEnter') && !e.shiftKey)
        submitForm();
    },
    [isAttachsFormActive, !isSubmitting]
  );

  useEffect(() => {
    setIsAttachsFormActive(!!field.value.length);
  }, [field.value]);

  return (
    <>
      <Dropzone />
      <Form />
      <Input ref={ref} />
    </>
  );
});

Attachs.displayName = 'Attachs';
export default Attachs;
