import {UpdateMessageContent} from '../use-whatcrm-ws-env';
import * as AppEnv from 'app-env';
import useHandleMsg from '../use-handle-msg/use-handle-msg';
import useHandleUpd from '../use-handle-upd/use-handle-upd';

const useHandleMsgs = () => {
  const handleMsg = useHandleMsg();
  const handleUpd = useHandleUpd();

  const handleMsgs = (
    instance: AppEnv.Instance,
    msgs: AppEnv.Message[] | UpdateMessageContent[]
  ) => {
    const [msg] = msgs;

    if ('id' in msg) return handleMsg(instance, msg);
    handleUpd(instance, msg);
  };

  return handleMsgs;
};

export default useHandleMsgs;
