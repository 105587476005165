import update from 'immutability-helper';

import {getDialogId, getIsMessageOutgoing} from 'common/actions';
import {useAppContext} from 'app-context';
import {
  useGetIsDialogAvailable,
  useNotification,
  useRequest
} from 'common/hooks';
import {useWebSocketProviderContext} from '../../../web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const getDialogLastMsg = (dialog: AppEnv.Dialog) => {
  if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    return dialog.lastMessage;
};

const getIsLastMsg = (dialog: AppEnv.Dialog, msg: AppEnv.Message) => {
  if (!('senderId' in msg) && !('from' in msg)) return false;

  const lastMsg = getDialogLastMsg(dialog);
  if (!lastMsg) return false;

  const lastMsgId = 'senderId' in lastMsg ? lastMsg.id : lastMsg.id._serialized;

  return lastMsgId == msg.id;
};

const getIsNewMsg = (dialog: AppEnv.Dialog, msg: AppEnv.Message) => {
  const lastMsg = getDialogLastMsg(dialog);
  if (!lastMsg) return false;

  if ('senderId' in lastMsg && 'senderId' in msg)
    return lastMsg.date < msg.timestamp;
  else if ('_data' in lastMsg && '_data' in msg)
    return lastMsg.timestamp < msg.timestamp;

  return false;
};

const getOriginalId = (serializedId: string): AppEnv.WhatsAppMessageId => {
  const [fromMe, remote, id] = serializedId.split('_');

  const originalId = {
    _serialized: serializedId,
    fromMe: fromMe == 'true' ? true : false,
    id,
    remote
  };

  return originalId;
};

const useHandleMsg = () => {
  const {isIframe} = useAppContext();
  const {user} = useWorkspaceContext();
  const {dialogs, setDialogs} = useWebSocketProviderContext();

  const {fetchDialog} = useRequest();
  const getIsDialogAvailable = useGetIsDialogAvailable(user);
  const notification = useNotification();

  const getNewTelegramDialog = async (
    instance: AppEnv.Instance,
    chatId: number | string
  ) => {
    const res = await fetchDialog(instance.chat_key, chatId);
    return res;
  };

  const getNewWhatsAppDialog = async (msg: AppEnv.Message) => {
    if (!('_data' in msg)) return null;

    const lastMsg: AppEnv.WhatsAppLastMessage = {
      ...msg,
      id: {_serialized: msg.id, remote: msg.chatId}
    };

    const splitChatId = msg.chatId.split('@');
    const server = splitChatId[1] as 'c.us' | 'g.us';

    const res: AppEnv.WhatsAppDialog = {
      id: {_serialized: msg.chatId, server: server, user: splitChatId[0]},
      isGroup: server == 'g.us',
      lastMessage: lastMsg,
      muteExpiration: 0,
      name: msg.chatName,
      pinned: false,
      timestamp: msg.timestamp,
      unreadCount: msg.fromMe || msg.type == 'e2e_notification' ? 0 : 1,
      version: 'whatcrm'
    };

    return res;
  };

  const handleNewDialog = async (
    instance: AppEnv.Instance,
    chatId: number | string,
    msg: AppEnv.Message
  ) => {
    const res = await (instance.version == 'telegram'
      ? getNewTelegramDialog(instance, chatId)
      : getNewWhatsAppDialog(msg));

    if (!res) return;

    const isDialogAvailable = getIsDialogAvailable(instance, res);
    if (!isDialogAvailable) return;

    setDialogs(prevValue => {
      const dialogIndex = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item, true) == getDialogId(res, true)
      );

      if (typeof dialogIndex == 'number' && dialogIndex > 0) return prevValue;
      return update(prevValue, {[instance.id]: {$push: [res]}});
    });

    notification(instance, res, msg);
  };

  const updateUnreadCount = async (
    instance: AppEnv.Instance,
    dialogIndex: number,
    isOutgoing: boolean
  ) =>
    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {unreadCount: target => (isOutgoing ? 0 : target + 1)}
        }
      })
    );

  const updateMsg = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    msg: AppEnv.Message
  ) => {
    const dialog = dialogs[instance.id]?.[dialogIndex];
    console.log(`d: ${!!dialog}`);
    if (!dialog) return;

    const msgIndex = dialog.messages?.findIndex(
      item => item.id == msg.id || ('senderId' in item && item.oldId == msg.id)
    );

    console.log(`mi: ${msgIndex}`);

    if (msgIndex == undefined || msgIndex > -1) {
      setDialogs(prevValue =>
        update(prevValue, {
          [instance.id]: {
            [dialogIndex]: {
              messages: {[msgIndex || 0]: {$set: msg as any}} // eslint-disable-line
            }
          }
        })
      );

      return;
    }

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {[dialogIndex]: {messages: {$push: [msg as any]}}} // eslint-disable-line
      })
    );
  };

  const updateLastMsg = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    msg: AppEnv.Message
  ) => {
    const originalId =
      typeof msg.id == 'string' ? getOriginalId(msg.id) : msg.id;

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            lastMessage: {$set: {...msg, id: originalId} as any} // eslint-disable-line
          }
        }
      })
    );
  };

  const handleNewMsg = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    msg: AppEnv.Message
  ) => {
    const dialog = dialogs[instance.id]?.[dialogIndex];

    if (
      !dialog ||
      (dialog.version != 'telegram' && dialog.version != 'whatcrm')
    ) {
      return;
    }

    console.log(`ms: ${!!dialog.messages}`);
    if (dialog.messages) updateMsg(instance, dialogIndex, msg);

    const isLastMsg = getIsLastMsg(dialog, msg);
    const isNewMsg = getIsNewMsg(dialog, msg);

    if (isNewMsg) {
      const isOutgoing = getIsMessageOutgoing(dialog, msg);
      updateUnreadCount(instance, dialogIndex, isOutgoing);
      if (!isOutgoing) notification(instance, dialog, msg);
    }

    if (!dialog.lastMessage || isLastMsg || isNewMsg)
      updateLastMsg(instance, dialogIndex, msg);
  };

  const handleMsg = (instance: AppEnv.Instance, msg: AppEnv.Message) => {
    if (!('senderId' in msg) && !('_data' in msg)) return;

    const dialogIndex = dialogs[instance.id]?.findIndex(
      item => getDialogId(item, true) == msg.chatId
    );

    if (dialogIndex == undefined || dialogIndex < 0) {
      if (!isIframe) handleNewDialog(instance, msg.chatId, msg);
      return;
    }

    handleNewMsg(instance, dialogIndex, msg);
  };

  return handleMsg;
};

export default useHandleMsg;
