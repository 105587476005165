import React from 'react';

interface PopupFooter {
  children: React.ReactNode;
}

const PopupFooter = ({children}: PopupFooter) => (
  <div className="popup__footer">{children}</div>
);

export default PopupFooter;
