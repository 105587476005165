import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';

const useReconnectWs = (callback: () => void) => {
  const {setNotification} = useAppContext();
  const [counter, setCounter] = useState(0);
  const {t} = useTranslation();

  const pushNotification = () =>
    setNotification({
      title: t`Error`,
      text: t`For further work with connections please reload the page.`
    });

  const reconnectWs = () => {
    if (counter > 4) return pushNotification();

    setCounter(prevValue => prevValue + 1);
    setTimeout(() => callback(), 1500);
  };

  return reconnectWs;
};

export default useReconnectWs;
