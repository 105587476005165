import React from 'react';

import {Text, Wrapper} from 'components';
import ProfileImage from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useChatContext} from '../../../chat-context';
import {
  getDialogId,
  getDialogName,
  getDialogProfileImage
} from 'common/actions';

const Account = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const name = getDialogName(instance, dialog);

  return (
    <Wrapper alignItems="center" direction="column" gap={8}>
      <ProfileImage
        id={getDialogId(dialog)}
        name={name}
        size={112}
        src={getDialogProfileImage(instance, dialog)}
      />

      <Text size={24} fontWeight={500} textAlign="center">
        {name}
      </Text>
    </Wrapper>
  );
};

export default Account;
