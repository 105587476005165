import React from 'react';
import {useField} from 'formik';

import {RadioAlt, Title} from 'components';
import * as AppEnv from 'app-env';

import Row from './row/row';

interface Section {
  onSubmit: () => void;
  section: AppEnv.WhatsAppListSection;
}

const Section = ({onSubmit, section}: Section) => {
  const [field, , helpers] = useField<string>('button');

  const options = section.rows.map(item => ({
    label: <Row row={item} />,
    value: item.title
  }));

  return (
    <div>
      <Title.H5 style={{marginBottom: 8}}>{section.title}</Title.H5>

      <RadioAlt
        {...field}
        options={options}
        onChange={value => helpers.setValue(value)}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default Section;
