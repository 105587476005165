import React from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

import {Popup, PopupFooter, PopupWrapper, Text, Wrapper} from 'components';
import {useFooterContext} from '../../../footer-context';

import AllowMultipleAnswers from './allow-multiple-answers/allow-multiple-answers';
import Body from './body/body';
import Buttons from './buttons/buttons';
import Send from './send/send';

const Poll = () => {
  const {isPollActive, setIsPollActive} = useFooterContext();

  const {t} = useTranslation();
  const {setFieldValue} = useFormikContext();

  const handleClick = async () => {
    setIsPollActive(false);
    await new Promise(resolve => setTimeout(resolve, 150));

    setFieldValue('body', '');
    setFieldValue('buttons', [{body: ''}, {body: ''}]);
  };

  return (
    <Popup isActive={isPollActive} onClick={handleClick}>
      <PopupWrapper>
        <Text size={16} style={{marginBottom: 12}} textAlign="center">
          {t`Poll`}
        </Text>

        <Wrapper direction="column" gap={24}>
          <Body />
          <Buttons />
          <AllowMultipleAnswers />
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Send />
      </PopupFooter>
    </Popup>
  );
};

export default Poll;
