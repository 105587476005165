import * as AppEnv from 'app-env';

const getBoost = (dialog: AppEnv.Dialog) => {
  const boost = 9999999999;

  if (dialog.version == 'telegram' && dialog.isPinned)
    return dialog.lastMessage?.date || boost;
  else if (dialog.version == 'whatcrm' && dialog.pinned)
    return dialog.lastMessage?.timestamp || boost;

  return 1;
};

const getTimestamp = (dialog: AppEnv.Dialog) => {
  const boost = getBoost(dialog);
  let timestamp = 0;

  if (dialog.version == 'telegram') timestamp = dialog.lastMessage?.date || 0;
  else if (dialog.version == 'whatcrm')
    timestamp = dialog.lastMessage?.timestamp || 0;

  return timestamp ? timestamp * boost : timestamp + boost;
};

const sortDialogs = (a: AppEnv.Dialog, b: AppEnv.Dialog) => {
  const ats = getTimestamp(a);
  const bts = getTimestamp(b);

  return bts - ats;
};

export default sortDialogs;
