import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page} from 'components';
import * as AppEnv from 'app-env';

import ConnectionsContext from './connections-context';
import ConnectionsMain from './connections-main/connections-main';
import Plans from './plans/plans';

interface Connections {
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const Connections = ({setDialogs}: Connections) => {
  const {t} = useTranslation();
  const location = useLocation();

  const options = [
    {label: t`Connections`, value: '/connections'},
    {label: t`Rate plans`, value: '/connections/plans'}
  ];

  return (
    <ConnectionsContext.Provider value={{setDialogs}}>
      <Body>
        <Page>
          <Nav options={options} value={location.pathname} />

          <Routes>
            <Route path="/" element={<ConnectionsMain />} />
            <Route path="/plans" element={<Plans />} />
          </Routes>
        </Page>
      </Body>
    </ConnectionsContext.Provider>
  );
};

export default Connections;
