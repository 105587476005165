import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import * as AppEnv from 'app-env';

import Version from './version/version';

interface Values {
  version: AppEnv.InstanceVersion;
}

interface MessengerSelect {
  isMessengerSelectActive: boolean;
  setIsConfigActive: AppEnv.SetState<boolean>;
  setIsMessengerSelectActive: AppEnv.SetState<boolean>;
  setVersion: AppEnv.SetState<AppEnv.InstanceVersion | undefined>;
}

const MessengerSelect = ({
  isMessengerSelectActive,
  setIsConfigActive,
  setIsMessengerSelectActive,
  setVersion
}: MessengerSelect) => {
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    setVersion(values.version);
    setIsMessengerSelectActive(false);
    setIsConfigActive(true);

    return true;
  };

  const initialValues: Values = {version: 'whatcrm'};

  return (
    <Popup
      width={408}
      isActive={isMessengerSelectActive}
      onClick={() => setIsMessengerSelectActive(false)}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>
              {t`Select a messenger`}
            </Title.H3>

            <Version />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Continue`}</SaveButton>

              <Button
                color="fade"
                onClick={() => setIsMessengerSelectActive(false)}
              >
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default MessengerSelect;
