import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface WidgetContext {
  isCodeActive: boolean;
  setIsCodeActive: AppEnv.SetState<boolean>;
}

const WidgetContext = React.createContext<WidgetContext>({} as WidgetContext);

export const useWidgetContext = () => useContext(WidgetContext);
export default WidgetContext;
