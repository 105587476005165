import {ReactionShape} from '../reaction/reaction';
import * as AppEnv from 'app-env';

export const getReactions = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog,
  message: AppEnv.Message
): ReactionShape[] => {
  if (dialog.version == 'chat' && 'sender_id' in message) {
    const {guest} = dialog;

    return message.reactions.map(reaction => ({
      count: 1,
      emoji: reaction.symbol,
      isActive: guest.id != reaction.user_id
    }));
  } else if ('senderId' in message)
    return (
      message.interactionInfo?.reactions?.reactions.reduce((acc, item) => {
        if (item.type.emoji)
          acc.push({
            count: item.totalCount,
            emoji: item.type.emoji,
            isActive: item.isChosen
          });

        return acc;
      }, [] as ReactionShape[]) || []
    );
  else if ('_data' in message)
    return (
      message.reactions?.map(item => ({
        count: item.senders.length,
        emoji: item.id,
        isActive: !!item.senders.find(
          item => item.senderId == `${instance.phone}@c.us`
        )
      })) || []
    );

  return [];
};
