import React, {useState} from 'react';

import {Button, IconsAlt} from 'components';
import {DeleteDialog, Wallpapers} from '../../components';

const Actions = () => {
  const [isDeleteDialogActive, setIsDeleteDialogActive] = useState(false);
  const [isWallpapersActive, setIsWallpapersActive] = useState(false);

  return (
    <>
      <div className="profile__actions">
        <Button color="transparent" onClick={() => setIsWallpapersActive(true)}>
          <IconsAlt.Brush />
        </Button>

        <Button
          color="transparent"
          onClick={() => setIsDeleteDialogActive(true)}
        >
          <IconsAlt.TrashSimple />
        </Button>
      </div>

      <Wallpapers
        isActive={isWallpapersActive}
        setIsActive={setIsWallpapersActive}
      />

      <DeleteDialog
        isActive={isDeleteDialogActive}
        setIsActive={setIsDeleteDialogActive}
      />
    </>
  );
};

export default Actions;
