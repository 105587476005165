import React, {useRef} from 'react';

import {Media, Wrapper} from 'components';
import {MessageText, UserMessage} from '../../components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import useMedia from '../use-media';

interface Video {
  isCircle?: boolean;
}

const Video = ({isCircle}: Video) => {
  const {message} = useWhatsAppMessageContext();

  const ref = useRef<HTMLDivElement>(null);
  useMedia(ref);

  const {_data} = message;

  const height = isCircle ? 360 : _data.height;
  const width = isCircle ? 360 : _data.width;

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <Media
          height={height}
          ref={ref}
          src={message.body}
          type="video"
          width={width}
          isCircle={isCircle}
          controls
        />

        {message._data.caption && (
          <MessageText>{message._data.caption}</MessageText>
        )}
      </Wrapper>
    </UserMessage>
  );
};

export default Video;
