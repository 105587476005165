import React from 'react';
import cn from 'classnames';

import {ProgressBar} from 'components';

interface Option<T> {
  label: string;
  value: T;
  votePercentage?: number;
}

interface Radio<T> {
  isDisabled?: boolean;
  onChange: (arg: T) => void;
  options: Option<T>[];
  style?: React.CSSProperties;
  value: T;
}

const Radio = <T extends string>({
  isDisabled,
  onChange,
  options,
  style,
  value
}: Radio<T>) => {
  const isVoted = options.find(item => item.value == value);

  return (
    <div className={cn('radio', {radio_disabled: isDisabled})} style={style}>
      {options.map((item, i) => (
        <div
          className={cn('radio__item', {
            radio__item_active: item.value == value
          })}
          key={i}
          onClick={() => onChange(item.value)}
        >
          <div className="radio__button" />

          <div className="radio__grid">
            {isVoted && typeof item.votePercentage == 'number' ? (
              <ProgressBar percent={item.votePercentage} label={item.label} />
            ) : (
              <span className="radio__label">{item.label}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Radio;
