import QRCode from 'qrcode';

import useUpdateInstanceState from '../use-update-instance-state/use-update-instance-state';
import {Event} from '../use-events-ws-env';
import * as AppEnv from 'app-env';

const useHandleQrCode = () => {
  const updateInstanceState = useUpdateInstanceState();

  const handleQrCode = async (instance: AppEnv.Instance, event: Event) => {
    if (!event.code) return;

    const qrCode = await QRCode.toDataURL(event.code);
    const condition: AppEnv.InstanceCondition = {qrCode};

    updateInstanceState(instance, 'got qr code', condition);
  };

  return handleQrCode;
};

export default useHandleQrCode;
