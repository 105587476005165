import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icons} from 'components';
import {useConnectorContext} from '../../../connector-context';

const Pending = () => {
  const {getStatus} = useConnectorContext();
  const [isFetching, setIsFetching] = useState(false);
  const {t} = useTranslation();

  const handleClick = () => {
    setIsFetching(true);
    getStatus(1);
    setTimeout(() => setIsFetching(false), 1500);
  };

  return (
    <div className="qr-code">
      <Button color="transparent" isDisabled={isFetching} onClick={handleClick}>
        <Icons.ArrowClockwise
          behavior={isFetching ? 'rotate-clockwise' : undefined}
          size={20}
        />

        {t`Restart`}
      </Button>
    </div>
  );
};

export default Pending;
