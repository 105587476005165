import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import * as AppEnv from 'app-env';

const ChatType = () => {
  const [field, , helpers] = useField<AppEnv.WidgetChatType>('chat_type');
  const {t} = useTranslation();

  const options: ChipsAltOption<AppEnv.WidgetChatType>[] = [
    {img: '/static/widget/chat-type/max.svg', value: 'max'},
    {img: '/static/widget/chat-type/over.svg', value: 'over'},
    {img: '/static/widget/chat-type/min.svg', value: 'min'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Chat appearance`}</Title.H3>
      <ChipsAlt {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default ChatType;
