import React from 'react';
import * as AppEnv from 'app-env';

import AvitoMessage from './avito-message/avito-message';
import ChatMessage from './chat-message/chat-message';
import MessageContext from './message-context';
import TelegramMessage from './telegram-message/telegram-message';
import WhatsAppMessage from './whatsapp-message/whatsapp-message';

interface Message {
  message: AppEnv.Message;
}

const Message = ({message}: Message) => (
  <MessageContext.Provider value={{message}}>
    {'author_id' in message ? (
      <AvitoMessage message={message} />
    ) : 'sender_id' in message ? (
      <ChatMessage message={message} />
    ) : 'senderId' in message ? (
      <TelegramMessage message={message} />
    ) : (
      'from' in message && <WhatsAppMessage message={message} />
    )}
  </MessageContext.Provider>
);

export default Message;
