import React from 'react';
import {useTranslation} from 'react-i18next';

import {MessageText, UserMessage} from '../../components';

const ViewOnce = () => {
  const {t} = useTranslation();

  return (
    <UserMessage>
      <MessageText isLight>
        {`1️⃣ ${t`A one-time view message. It can only be viewed on your phone.`}`}
      </MessageText>
    </UserMessage>
  );
};

export default ViewOnce;
