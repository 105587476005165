import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {removeFromLocalStorage} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Confirm} from 'components';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import MenuItem from './menu-item/menu-item';

interface Menu {
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Menu = ({setIsSidebarActive}: Menu) => {
  const {isIframe} = useAppContext();
  const {setInstanceReferer} = useWorkspaceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const {t} = useTranslation();
  const location = useLocation();

  const handleClick = () => {
    if (window.innerWidth <= 768) setIsSidebarActive(false);
    if (!location.pathname.startsWith('/messenger/')) return;

    const instanceId = location.pathname.split('/')[2];
    setInstanceReferer(instanceId);
  };

  const signOut = () => {
    removeFromLocalStorage('email');
    window.location.href = '/auth';
  };

  const handleConfirm = (res: boolean) =>
    res ? signOut() : setIsConfirmActive(false);

  return (
    <>
      <div className="sidebar__menu">
        <>
          {process.env.REACT_APP_ENV == 'dev' && (
            <>
              {!isIframe && (
                <Link replace={isIframe} to="/connections">
                  <MenuItem
                    isActive={location.pathname.startsWith('/connections')}
                    onClick={handleClick}
                  >
                    {t`Connections`}
                  </MenuItem>
                </Link>
              )}
            </>
          )}
        </>

        <Link replace={isIframe} to="/settings">
          <MenuItem
            isActive={location.pathname.startsWith('/settings')}
            onClick={handleClick}
          >
            {t`Settings`}
          </MenuItem>
        </Link>

        {process.env.REACT_APP_ENV == 'dev' && (
          <Link replace={isIframe} to="/templates">
            <MenuItem
              isActive={location.pathname.startsWith('/templates')}
              onClick={handleClick}
            >
              {t`Templates`}
            </MenuItem>
          </Link>
        )}

        {process.env.REACT_APP_ENV == 'dev' && (
          <>
            {!isIframe && (
              <Link replace={isIframe} to="/widget">
                <MenuItem
                  isActive={location.pathname.startsWith('/widget')}
                  onClick={handleClick}
                >
                  {t`Widget`}
                </MenuItem>
              </Link>
            )}
          </>
        )}

        {!isIframe && (
          <MenuItem onClick={() => setIsConfirmActive(true)}>
            {t`Sign out`}
          </MenuItem>
        )}
      </div>

      <Confirm
        title={t`Are you sure you want to sign out?`}
        isActive={isConfirmActive}
        onClick={handleConfirm}
      />
    </>
  );
};

export default Menu;
