import React from 'react';

interface Tooltip {
  children: React.ReactNode;
}

const Tooltip = ({children}: Tooltip) => (
  <div className="tooltip">{children}</div>
);

export default Tooltip;
