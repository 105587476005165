import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Checkbox} from 'components';

const Revoke = () => {
  const [field, , helpers] = useField<boolean>('revoke');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`Delete for everyone`}
      onChange={helpers.setValue}
    />
  );
};

export default Revoke;
