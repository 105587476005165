import React from 'react';
import {useTranslation} from 'react-i18next';
import {formatPhoneNumber} from 'whatcrm-core';

import {Icons} from 'components';
import * as AppEnv from 'app-env';

const useInstanceOptions = (instances: AppEnv.Instance[]) => {
  const {t} = useTranslation();

  const icons = {
    avito: <Icons.Avito isCompacted />,
    chat: <Icons.CallCenterOperator isCompacted />,
    telegram: <Icons.Telegram isCompacted />,
    whatcrm: <Icons.WhatsApp isCompacted />
  };

  const options = Object.entries(instances).map(([, item]) => ({
    comment: formatPhoneNumber(item.phone),
    label: item.label || `${t`Connection`} ${item.id}`,
    value: item.id,
    icon: icons[item.version]
  }));

  return options;
};

export default useInstanceOptions;
