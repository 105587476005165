import React, {useEffect} from 'react';

import {useGetTgRemoteFile} from '../../hooks';
import {useIsVisible} from 'common/hooks';
import {useTelegramMessageContext} from '../telegram-message-context';

const useRemoteFile = (
  ref: React.RefObject<HTMLElement>,
  id: string | undefined
) => {
  const {message} = useTelegramMessageContext();

  const getTgRemoteFile = useGetTgRemoteFile();
  const isVisible = useIsVisible(ref);

  const handleIsVisible = () => {
    if (!id) return;
    else if (!isVisible) return;

    const isFile =
      message.body && !message.body.startsWith('data:image/png;base64,');

    if (isFile) return;
    getTgRemoteFile(id, {msgId: message.id});
  };

  useEffect(() => {
    handleIsVisible();
  }, [isVisible]);
};

export default useRemoteFile;
