import React from 'react';

interface TimeInput {
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const TimeInput = ({name, onChange, value}: TimeInput) => (
  <input
    className="time-input"
    name={name}
    onChange={e => onChange(e.target.value)}
    type="time"
    value={value}
  />
);

export default TimeInput;
