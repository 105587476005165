import React from 'react';
import {useField} from 'formik';
import cn from 'classnames';

import {Icons} from 'components';

const Wallpaper = () => {
  const [field, , helpers] = useField<string | undefined>('wallpaper');

  const handleClick = (value: string) =>
    helpers.setValue(value != field.value ? value : undefined);

  const options = [];

  for (let i = 20; i > 0; i--) {
    options.push(
      <div
        className="wallpapers__item"
        key={i}
        style={{backgroundImage: `url('/static/img/wallpapers/${i}-min.jpg')`}}
        onClick={() => handleClick(i.toString())}
      >
        <div
          className={cn('wallpapers__check', {
            wallpapers__check_active: i.toString() == field.value
          })}
        >
          <Icons.Check color="white" size={20} />
        </div>
      </div>
    );
  }

  return <div className="wallpapers">{options}</div>;
};

export default Wallpaper;
