import {isMacOs} from 'react-device-detect';
import cn from 'classnames';

const useScrollClassName = (isMargin?: boolean) => {
  const scrollClassName = cn('scroll', {
    scroll_macos: isMacOs,
    scroll_margin: isMargin
  });

  return scrollClassName;
};

export default useScrollClassName;
