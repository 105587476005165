import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface FooterContext {
  isAttachsFormActive: boolean;
  isPollActive: boolean;
  isVoiceRecording: boolean;
  progress: number[];
  setIsAttachsFormActive: AppEnv.SetState<boolean>;
  setIsPollActive: AppEnv.SetState<boolean>;
  setIsVoiceRecording: AppEnv.SetState<boolean>;
  setTemplateState: AppEnv.SetState<AppEnv.TemplateState>;
  templateState: AppEnv.TemplateState;
}

const FooterContext = React.createContext<FooterContext>({} as FooterContext);

export const useFooterContext = () => useContext(FooterContext);
export default FooterContext;
