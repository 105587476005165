import React from 'react';

import {MessageLocation, MessageText, UserMessage} from '../../components';
import {useAvitoMessageContext} from '../avito-message-context';
import {Wrapper} from 'components';

const Location = () => {
  const {message} = useAvitoMessageContext();

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <MessageLocation
          lat={message.content.location?.lat}
          lng={message.content.location?.lon}
        />

        <MessageText>{message.content.location?.title}</MessageText>
      </Wrapper>
    </UserMessage>
  );
};

export default Location;
