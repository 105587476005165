import React from 'react';
import cn from 'classnames';

export interface ChipsOption<T> {
  icon: React.ReactNode;
  isDisabled?: boolean;
  label: string;
  value: T;
}

interface Chips<T> {
  onChange: (arg: T) => void;
  options: ChipsOption<T>[];
  value: T | undefined;
}

const Chips = <T extends string>({onChange, options, value}: Chips<T>) => (
  <div className="chips">
    {options.map(option => (
      <button
        className={cn('chips__item', {
          chips__item_active: option.value == value
        })}
        key={option.value}
        title={option.label}
        type="button"
        disabled={option.isDisabled}
        onClick={() => onChange(option.value)}
      >
        {option.icon}
      </button>
    ))}
  </div>
);

export default Chips;
