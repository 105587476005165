import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Anchor, Icons} from 'components';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Header {
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Header = ({setIsSidebarActive}: Header) => {
  const {isIframe} = useAppContext();
  const {instanceReferer} = useWorkspaceContext();

  const {t} = useTranslation();
  const location = useLocation();

  return (
    <div className="sidebar__header">
      {location.pathname.startsWith('/messenger') ? (
        <Anchor fontWeight={500} onClick={() => setIsSidebarActive(false)}>
          <Icons.ArrowLeft />
          <span>{t`Back to chats`}</span>
        </Anchor>
      ) : (
        <Link
          replace={isIframe}
          to={instanceReferer ? `/messenger/${instanceReferer}` : '/messenger'}
        >
          <Anchor fontWeight={500} onClick={() => setIsSidebarActive(false)}>
            <Icons.ArrowLeft />
            <span>{t`Back to chats`}</span>
          </Anchor>
        </Link>
      )}
    </div>
  );
};

export default Header;
