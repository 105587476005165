import React from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Icons, Text} from 'components';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import useHandleFiles from '../../../use-handle-files';

const Dropzone = () => {
  const {isDropzoneActive} = useChatContext();

  const handleFiles = useHandleFiles();
  const {t} = useTranslation();

  const {getInputProps, getRootProps, isDragAccept} = useDropzone({
    noClick: true,
    onDrop: handleFiles
  });

  return (
    <div className={cn('dropzone', {dropzone_active: isDropzoneActive})}>
      <div
        {...getRootProps()}
        className={cn('dropzone__wrapper', {
          dropzone__wrapper_active: isDragAccept
        })}
      >
        <input {...getInputProps()} />

        <div className="dropzone__border">
          <Icons.Files />

          <Text color="black" size={24} fontWeight={500}>
            {t`Drop files here`}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
