import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import {Button, Icons} from 'components';

interface Input {
  autoCapitalize?: 'off';
  autoFocus?: boolean;
  color?: 'gray' | 'light-gray';
  isAltClearButton?: boolean;
  isSmall?: boolean;
  isValid?: boolean;
  maxLength?: number;
  name: string;
  onChange: (arg: string) => void;
  onClear?: () => void;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  value: string | number | undefined;
  width?: 80;
}

const Input = ({
  autoCapitalize,
  autoFocus,
  color,

  isAltClearButton,
  isSmall,
  isValid = true,
  maxLength,
  name,
  onChange,
  onClear,
  placeholder,
  style,
  type,
  value,
  width
}: Input) => {
  const [htmlType, setHtmlType] = useState(type || 'text');
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) setTimeout(() => ref.current?.focus(), 150);
  }, []);

  const handleClick = () => {
    onChange('');
    if (onClear) onClear();
  };

  const isCustomPlaceholder = !isSmall && placeholder;

  return (
    <div className="input" style={{...style, width}}>
      {type == 'search' && <Icons.Search />}

      <input
        className={cn('input__field', {
          input__field_error: !isValid,
          [`input__field_${color}`]: color,
          input__field_small: isSmall
        })}
        maxLength={maxLength}
        name={isCustomPlaceholder ? `new-${name}` : name}
        placeholder={isSmall ? placeholder : ' '}
        ref={ref}
        type={htmlType}
        value={value}
        style={type == 'password' ? {paddingRight: 50} : undefined}
        autoCapitalize={autoCapitalize}
        onChange={e => onChange(e.target.value)}
      />

      {isCustomPlaceholder && (
        <span className="input__placeholder">{placeholder}</span>
      )}

      {type == 'password' ? (
        <div
          className="input__btn input__btn_active"
          style={{top: 9, right: 9}}
        >
          <Button
            color="transparent"
            onClick={() => {
              setHtmlType(prevValue =>
                prevValue == 'password' ? 'text' : 'password'
              );

              ref.current?.focus();

              setTimeout(() => {
                if (ref.current)
                  ref.current.selectionStart = value?.toString().length || 0;
              });
            }}
          >
            {htmlType == 'password' ? (
              <Icons.Eye size={24} />
            ) : (
              <Icons.EyeClosed size={24} />
            )}
          </Button>
        </div>
      ) : (
        <div
          className={cn('input__btn', {
            input__btn_active: (isAltClearButton || type == 'search') && value
          })}
        >
          <Button color="transparent" onClick={handleClick}>
            {type == 'search' ? (
              <Icons.CloseCircle color="light" />
            ) : (
              <Icons.Close color="blue" size={24} />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Input;
