import React from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

import {SaveButton} from 'components';
import * as FooterEnv from '../../../../footer-env';

const Send = () => {
  const {t} = useTranslation();
  const {values} = useFormikContext<FooterEnv.Values>();

  const isBodyValid = !!values.body.trim();

  const isButtonsValid =
    values.buttons.filter(item => item.body.trim()).length > 1;

  const isDisabled = !isBodyValid || !isButtonsValid;

  return <SaveButton isDisabled={isDisabled} isMaxWidth>{t`Send`}</SaveButton>;
};

export default Send;
