import React from 'react';
import cn from 'classnames';

interface Card {
  children: React.ReactNode;
  isActive: boolean;
}

const Card = ({children, isActive}: Card) => (
  <div className={cn('card', {card_active: isActive})}>{children}</div>
);

export {default as CardBody} from './card-body/card-body';
export {default as CardHead} from './card-head/card-head';
export {default as CardMenu} from './card-menu/card-menu';
export default Card;
