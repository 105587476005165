import React from 'react';
import {useField} from 'formik';

import CallbackButtonText from './callback-button-text/callback-button-text';
import CallbackFieldType from './callback-field-type/callback-field-type';
import CallbackHeader from './callback-header/callback-header';
import CallbackIsMessage from './callback-is-message/callback-is-message';
import CallbackType from './callback-type/callback-type';

const Callback = () => {
  const [field] = useField<boolean>('is_callback');

  return (
    <>
      {field.value && (
        <>
          <CallbackType />
          <CallbackHeader />
          <CallbackButtonText />
          <CallbackIsMessage />
          <CallbackFieldType />
        </>
      )}
    </>
  );
};

export default Callback;
