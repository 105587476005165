import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

interface GetTgRemoteFileParams {
  isQuoted?: boolean;
  msgId?: number;
}

const useGetTgRemoteFile = () => {
  const {updateMessageBody, updateQuotedMsgBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();

  const {fetchRemoteFile} = useRequest();

  const updateBody = (
    msgId: number,
    path: string,
    isQuoted: boolean | undefined
  ) => {
    if (isQuoted) return updateQuotedMsgBody(msgId, path);
    updateMessageBody(msgId, path);
  };

  const getTgRemoteFile = async (
    remoteFileId: string,
    {isQuoted, msgId}: GetTgRemoteFileParams = {}
  ) => {
    const res = await fetchRemoteFile(instance.chat_key, remoteFileId);

    if (!res?.path) return null;
    else if (msgId) updateBody(msgId, res.path, isQuoted);

    return res.path;
  };

  return getTgRemoteFile;
};

export default useGetTgRemoteFile;
