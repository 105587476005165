import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icons} from 'components';

import Config from './config/config';
import MessengerSelect from './messenger-select/messenger-select';
import {useInitialVersion} from './hooks';

const InstanceCreator = () => {
  const [isConfigActive, setIsConfigActive] = useState(false);
  const [isMessengerSelectActive, setIsMessengerSelectActive] = useState(false);

  const initialVersion = useInitialVersion();
  const [version, setVersion] = useState(initialVersion);

  const {t} = useTranslation();

  return (
    <>
      <Button
        className="instance-creator"
        color="dashed"
        onClick={() => setIsMessengerSelectActive(true)}
      >
        {t`Add connection`}
        <Icons.Plus color="blue" size={24} />
      </Button>

      <MessengerSelect
        isMessengerSelectActive={isMessengerSelectActive}
        setIsConfigActive={setIsConfigActive}
        setIsMessengerSelectActive={setIsMessengerSelectActive}
        setVersion={setVersion}
      />

      {version && (
        <Config
          version={version}
          isConfigActive={isConfigActive}
          setIsConfigActive={setIsConfigActive}
          setVersion={setVersion}
        />
      )}
    </>
  );
};

export default InstanceCreator;
