import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {Title} from 'components';
import {useInstanceContext} from '../../instance-context';
import {useInstancePaymentStatus} from '../hooks';

const Date = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const instancePaymentStatus = useInstancePaymentStatus();

  const getDate = () => {
    const {chatInfo} = instance;

    if (instancePaymentStatus == 'trial') return chatInfo?.date_trial;
    else if (instancePaymentStatus == 'paid')
      return chatInfo?.date_subscription;
  };

  const date = getDate();

  return (
    <>
      {!!date && (
        <Title.H3 style={{flexShrink: 0}} isNoWrap>
          {t`until`} {moment.unix(date).format('L')}
        </Title.H3>
      )}
    </>
  );
};

export default Date;
