import React from 'react';
import {Text} from 'components';

interface DocIcon {
  fileName: string | undefined;
}

const DocIcon = ({fileName}: DocIcon) => {
  const ext = fileName
    ?.substring(fileName.lastIndexOf('.') + 1)
    .substring(0, 4);

  return (
    <div className="doc-icon">
      <Text color="white" fontWeight={500} textAlign="center">
        {ext}
      </Text>
    </div>
  );
};

export default DocIcon;
