import {RefObject} from 'react';

const insertSubstr = (
  ref: RefObject<HTMLTextAreaElement>,
  str: string,
  substr: string
) => {
  if (!ref.current) return str;
  ref.current.focus();

  const selectionStart = ref.current.selectionStart;
  const selectionEnd = ref.current.selectionEnd;

  const res =
    str.substring(0, selectionStart) + substr + str.substring(selectionEnd);

  ref.current.selectionEnd = selectionEnd + substr.length;
  return res;
};

export default insertSubstr;
