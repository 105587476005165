import moment from 'moment';
import * as AppEnv from 'app-env';

const getIsMsgEdited = (msg: AppEnv.Message) => {
  if ('sender_id' in msg) return moment(msg.edited_at).unix() > 0;
  else if ('senderId' in msg) return msg.editDate > 0;
  else if ('_data' in msg) return (msg.latestEditSenderTimestampMs || 0) > 0;

  return false;
};

export default getIsMsgEdited;
