import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import * as AppEnv from 'app-env';

const ChatMessageType = () => {
  const [field, , helpers] =
    useField<AppEnv.WidgetMessageType>('chat_message_type');

  const {t} = useTranslation();

  const options: ChipsAltOption<AppEnv.WidgetMessageType>[] = [
    {
      img: '/static/widget/chat-message-type/minimalism.svg',
      value: 'minimalism'
    },
    {img: '/static/widget/chat-message-type/retro.svg', value: 'retro'},
    {
      img: '/static/widget/chat-message-type/classic.svg',
      value: 'classic'
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Message appearance`}</Title.H3>
      <ChipsAlt {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default ChatMessageType;
