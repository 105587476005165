import React from 'react';
import cn from 'classnames';

import {Icons, Text} from 'components';

export interface ChipsAltOption<T> {
  img: string;
  label?: string;
  value: T;
}

interface ChipsAlt<T> {
  onChange: (arg: T) => void;
  options: ChipsAltOption<T>[];
  value: T;
}

const ChipsAlt = <T,>({onChange, options, value}: ChipsAlt<T>) => (
  <div className="chips-alt">
    {options.map((option, i) => (
      <div key={i}>
        {option.label && (
          <Text size={16} style={{margin: '0 0 4px 6px'}}>
            {option.label}
          </Text>
        )}

        <button
          className={cn('chips-alt__option', {
            'chips-alt__option_active': option.value == value
          })}
          onClick={() => onChange(option.value)}
          type="button"
        >
          <img src={option.img} />

          <div className="chips-alt__checkbox">
            <Icons.Check color="white" size={16} />
          </div>
        </button>
      </div>
    ))}
  </div>
);

export default ChipsAlt;
