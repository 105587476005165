import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useIntegrationId = (template?: AppEnv.Template) => {
  const {user} = useWorkspaceContext();

  const {integration} = user;
  const integrationId = template?.integration_id || integration.id;

  return integrationId;
};

export default useIntegrationId;
