import React, {useState} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {
  Button,
  Chips,
  ChipsOption,
  Popup,
  PopupFooter,
  PopupWrapper,
  Text,
  Title,
  Wrapper
} from 'components';
import * as AppEnv from 'app-env';

interface SocialAdder {
  isSocialAdderActive: boolean;
  options: ChipsOption<AppEnv.WidgetSocialVersion>[];
  setIsSocialAdderActive: AppEnv.SetState<boolean>;
}

const SocialAdder = ({
  isSocialAdderActive,
  options,
  setIsSocialAdderActive
}: SocialAdder) => {
  const [version, setVersion] = useState<AppEnv.WidgetSocialVersion>();

  const [field, , helpers] = useField<AppEnv.WidgetSocial[]>('socials');
  const {i18n, t} = useTranslation();

  const handleClick = () => {
    if (!version) return;

    const newValue = update(field.value, {$push: [{url: '', version}]});
    helpers.setValue(newValue);
    setIsSocialAdderActive(false);
    setVersion(undefined);
  };

  return (
    <Popup
      isActive={isSocialAdderActive}
      width={408}
      onClick={() => setIsSocialAdderActive(false)}
    >
      <PopupWrapper>
        <Wrapper direction="column" gap={16}>
          <Title.H3>{t`Select a social network`}</Title.H3>

          <Chips options={options} value={version} onChange={setVersion} />

          {i18n.resolvedLanguage == 'ru' && (
            <Text>
              {`*Facebook и Instagram принадлежат организации Meta, которая признана экстремистской и запрещена на территории Российской Федерации.`}
            </Text>
          )}
        </Wrapper>
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button isDisabled={!version} onClick={handleClick}>{t`Add`}</Button>

          <Button color="fade" onClick={() => setIsSocialAdderActive(false)}>
            {t`Close`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default SocialAdder;
