import {useGetIsDialogAvailable} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetAvailableDialogList = (user: AppEnv.User) => {
  const getIsDialogAvailable = useGetIsDialogAvailable(user);

  const getAvailableDialogList = (
    instance: AppEnv.Instance,
    dialogList: AppEnv.Dialog[]
  ) => dialogList.filter(item => getIsDialogAvailable(instance, item));

  return getAvailableDialogList;
};

export default useGetAvailableDialogList;
