import React from 'react';

import {MessageAudio, UserMessage} from '../../components';
import {useGetTgRemoteFile} from '../../hooks';
import {useTelegramMessageContext} from '../telegram-message-context';

const Audio = () => {
  const {message} = useTelegramMessageContext();
  const getTgRemoteFile = useGetTgRemoteFile();

  const handleClick = () => {
    const {audio} = message.content;
    if (!audio) return;

    const {remote} = audio.audio;
    getTgRemoteFile(remote.id, {msgId: message.id});
  };

  return (
    <UserMessage>
      <MessageAudio
        duration={message.content.audio?.duration}
        fileName={message.content.audio?.fileName}
        message={message}
        size={message.content.audio?.audio.size}
        onClick={handleClick}
      />
    </UserMessage>
  );
};

export default Audio;
