import React, {useEffect, useState} from 'react';
import {getEmojiDataFromNative} from 'emoji-mart';
import {getFromLocalStorage} from 'whatcrm-core';
import cn from 'classnames';
import update from 'immutability-helper';

import {Icons, Text} from 'components';
import {useContextMenuProviderContext} from '../context-menu-provider-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useScrollClassName} from 'common/hooks';
import {useSendReaction} from '../hooks';
import * as AppEnv from 'app-env';

interface ReactionTrigger {
  isContextMenuActive: boolean;
  setIsReactionPickerActive: AppEnv.SetState<boolean>;
}

const initialReactions = ['❤', '👌', '👍', '👎', '😁'];

const ReactionTrigger = ({
  isContextMenuActive,
  setIsReactionPickerActive
}: ReactionTrigger) => {
  const {instance} = useDispatchContext();
  const {setIsContextMenuActive} = useContextMenuProviderContext();

  const [reactions, setReactions] = useState(initialReactions);

  const scrollClassName = useScrollClassName(true);
  const sendReaction = useSendReaction();

  const getRecentReactions = async () => {
    const emojiMartFrequently = getFromLocalStorage('emoji-mart.frequently');
    if (!emojiMartFrequently) return;

    const list = JSON.parse(emojiMartFrequently);

    const keys = Object.keys(list)
      .sort((a, b) => list[b] - list[a])
      .slice(0, 5);

    const frequently: string[] = [];

    const promises = keys.map(async key => {
      const emoji = await getEmojiDataFromNative(key);
      frequently.push(emoji.native);
    });

    await Promise.all(promises);
    setReactions(frequently);
  };

  const getReactions = () => {
    if (instance.version != 'telegram') return getRecentReactions();

    setReactions(prevValue =>
      update(prevValue, {
        $push: [
          '❤‍🔥',
          '🎉',
          '👏',
          '🔥',
          '🤔',
          '🤩',
          '🤬',
          '🤮',
          '🤯',
          '🥰',
          '😢',
          '😱'
        ]
      })
    );
  };

  useEffect(() => {
    if (!isContextMenuActive) return;
    getReactions();
  }, [isContextMenuActive]);

  const handleTrigger = () => {
    setIsContextMenuActive(false);
    setIsReactionPickerActive(true);
  };

  return (
    <div
      className={cn('reaction-trigger', {[scrollClassName]: scrollClassName})}
    >
      {reactions.map((reaction, i) => (
        <div
          className="reaction-trigger__item"
          key={i}
          onClick={() => sendReaction(reaction)}
        >
          <Text size={24}>{reaction}</Text>
        </div>
      ))}

      {instance.version != 'telegram' && (
        <button className="reaction-trigger__btn" onClick={handleTrigger}>
          <Icons.CaretDown color="black" size={16} />
        </button>
      )}
    </div>
  );
};

export default ReactionTrigger;
