import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {downloadFile, getBlob} from 'common/actions';
import {MessageDocument, UserMessage} from '../../components';
import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Document = () => {
  const {setNotification} = useAppContext();
  const {updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {message} = useWhatsAppMessageContext();

  const [blob, setBlob] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const {fetchDownloadMedia} = useRequest();
  const {t} = useTranslation();

  const download = (href: string) =>
    downloadFile(href, message._data.filename || '');

  const throwError = () => {
    setNotification({title: t`Failed to download the file`});
    setIsFetching(false);
  };

  const handleClick = async () => {
    if (blob) return download(blob);
    setIsFetching(true);

    let url = message.body;

    if (!url) {
      if (!message.mediaKey) return throwError();

      const media = await fetchDownloadMedia(
        instance.chat_key,
        message.id,
        message.mediaKey
      );

      if (Array.isArray(media) || !media?.url) return throwError();

      updateMessageBody(message.id, media.url);
      url = media.url;
    }

    const res = await getBlob(message.body);
    if (!res) return throwError();

    setIsFetching(false);
    download(res);
    setBlob(res);
  };

  const caption =
    message._data.caption != message._data.filename
      ? message._data.caption
      : undefined;

  return (
    <UserMessage>
      <MessageDocument
        caption={caption}
        fileName={message._data.filename}
        size={message._data.size}
        isLoading={isFetching}
        onClick={handleClick}
      />
    </UserMessage>
  );
};

export default Document;
