import React from 'react';
import cn from 'classnames';

interface MenuItem {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

const MenuItem = ({children, isActive, onClick}: MenuItem) => (
  <div
    className={cn('sidebar__menu-item', {
      'sidebar__menu-item_active': isActive
    })}
    onClick={() => (onClick ? onClick() : undefined)}
  >
    <span>{children}</span>
  </div>
);

export default MenuItem;
