import {
  getIsDialogBlacklisted,
  getIsDialogGroup,
  getIsLimited
} from 'common/actions';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

const useGetIsDialogAvailable = (user: AppEnv.User) => {
  const {isIframe} = useAppContext();

  const getIsDialogAvailable = (
    instance: AppEnv.Instance,
    dialog: AppEnv.Dialog
  ) => {
    const isBlacklisted = getIsDialogBlacklisted(user, dialog);

    if (isBlacklisted) return false;
    else if (isIframe) return true;

    const isGroup = getIsDialogGroup(dialog);
    if (!isGroup) return true;

    const isLimited = getIsLimited(instance);
    return !isLimited;
  };

  return getIsDialogAvailable;
};

export default useGetIsDialogAvailable;
