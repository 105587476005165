import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {downloadFile, getBlob} from 'common/actions';
import {MessageDocument, UserMessage} from '../../components';
import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useTelegramMessageContext} from '../telegram-message-context';

const Document = () => {
  const {setNotification} = useAppContext();
  const {instance} = useDispatchContext();
  const {message} = useTelegramMessageContext();

  const [blob, setBlob] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {fetchRemoteFile} = useRequest();
  const {t} = useTranslation();

  const throwError = () => {
    setNotification({title: t`Failed to download the file`});
    setIsFetching(false);
    setIsLoading(false);
  };

  const download = (href: string) => {
    downloadFile(href, message.content.document?.fileName || '');
    setIsFetching(false);
    setIsLoading(false);
  };

  const updateBlob = async (path: string) => {
    const res = await getBlob(path);
    if (!res) return throwError();

    download(res);
    setBlob(res);
  };

  const getPath = async () => {
    if (message.content.document?.document) {
      const res = await fetchRemoteFile(
        instance.chat_key,
        message.content.document.document.remote.id
      );

      if (res?.path) {
        updateBlob(res.path);
        return;
      }

      setIsLoading(true);
      return;
    }

    throwError();
  };

  useEffect(() => {
    if (message.body && isLoading) updateBlob(message.body);
  }, [message.body]);

  const handleClick = () => {
    setIsFetching(true);

    if (blob) {
      download(blob);
    } else if (message.body) {
      updateBlob(message.body);
    } else {
      getPath();
    }
  };

  return (
    <UserMessage>
      <MessageDocument
        caption={message.caption}
        fileName={message.content.document?.fileName}
        size={message.content.document?.document.size}
        isLoading={isFetching}
        onClick={handleClick}
      />
    </UserMessage>
  );
};

export default Document;
