import React, {useRef, useState} from 'react';
import {Anchor} from 'components';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  getAvitoDialogName,
  getAvitoProductImage,
  getDialogId,
  getDialogName,
  getDialogProfileImage
} from 'common/actions';
import {Icons, Text} from 'components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../chat-context';
import {useDispatchContext} from '../../../dispatch-context';
import {useIsOnline} from '../hooks';
import ProfileImage from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';

import ContextMenuProvider from './context-menu-provider/context-menu-provider';
import Profile from './profile/profile';

const Header = () => {
  const {isIframe} = useAppContext();
  const {instance} = useDispatchContext();
  const {dialog, telegramUser} = useChatContext();

  const [isProfileActive, setIsProfileActive] = useState(false);

  const {t} = useTranslation();
  const isOnline = useIsOnline();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = e => {
    const target = e.target as HTMLDivElement;
    if (!menuRef.current?.contains(target)) setIsProfileActive(true);
  };

  const [name, src] =
    dialog.version == 'avito'
      ? [getAvitoDialogName(instance, dialog), getAvitoProductImage(dialog)]
      : [
          getDialogName(instance, dialog),
          getDialogProfileImage(instance, dialog)
        ];

  return (
    <>
      <div className="chat__header">
        <div className="chat__header-user" onClick={handleClick}>
          <Link
            className="chat__header-link"
            replace={isIframe}
            to={`/messenger/${instance.id}`}
          >
            <Anchor fontWeight={500}>
              <Icons.ArrowLeft />
              {t`Back`}
            </Anchor>
          </Link>

          <ProfileImage
            id={getDialogId(dialog)}
            name={name}
            size={36}
            src={src}
            tag={isOnline ? 'online' : undefined}
          />

          <div>
            <Text className="chat__title" fontWeight={500} size={16}>
              {name}
            </Text>

            {isOnline ? (
              <Text color="green" fontWeight={500} size={16}>
                {t`online`}
              </Text>
            ) : (
              telegramUser && (
                <Text color="light" fontWeight={500} size={16}>
                  {telegramUser.status._ == 'userStatusRecently'
                    ? t`last seen recently`
                    : t`offline`}
                </Text>
              )
            )}
          </div>
        </div>

        <ContextMenuProvider
          ref={menuRef}
          setIsProfileActive={setIsProfileActive}
        />
      </div>

      {isProfileActive && <Profile setIsProfileActive={setIsProfileActive} />}
    </>
  );
};

export default Header;
