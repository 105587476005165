import React, {useState} from 'react';
import {getFromLocalStorage, putInLocalStorage} from 'whatcrm-core';

import {Button, Icons} from 'components';

interface Advice {
  children: React.ReactNode;
  id: string;
  style?: React.CSSProperties;
}

const Advice = ({children, id, style}: Advice) => {
  const [isShown, setIsShown] = useState(!getFromLocalStorage(id));

  const handleClick = () => {
    setIsShown(false);
    putInLocalStorage(id, '1');
  };

  return (
    <>
      {isShown && (
        <div className="advice" style={style}>
          <div className="advice__content">{children}</div>

          <Button color="transparent" onClick={handleClick}>
            <Icons.Close color="blue" />
          </Button>
        </div>
      )}
    </>
  );
};

export default Advice;
