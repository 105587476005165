import * as AppEnv from 'app-env';

const getEditDate = (msg: AppEnv.Message): number => {
  if ('sender_id' in msg) return +msg.edited_at || 0;
  else if ('senderId' in msg) return msg.editDate;
  else if ('_data' in msg) return msg.latestEditSenderTimestampMs || 0;

  return 0;
};

export default getEditDate;
