import React from 'react';
import cn from 'classnames';

import * as AppEnv from 'app-env';

interface Waveform {
  audioRef: React.RefObject<HTMLAudioElement>;
  currentTime: number;
  duration: number;
  waveform: number[];
  setCurrentTime: AppEnv.SetState<number>;
}

const Waveform = ({
  audioRef,
  currentTime,
  duration,
  waveform,
  setCurrentTime
}: Waveform) => {
  const getIsActive = (index: number) =>
    index / waveform.length < currentTime / duration;

  const handleClick = (index: number) => {
    if (audioRef.current?.currentTime != undefined) {
      const newCurrentTime = (index / waveform.length) * duration;

      audioRef.current.currentTime = newCurrentTime;
      setCurrentTime(newCurrentTime);
    }
  };

  return (
    <div className="audio-player__waveform">
      {waveform.map((item, i) => (
        <div
          className="audio-player__waveform-item"
          key={i}
          onClick={() => handleClick(i)}
        >
          <div
            className={cn('audio-player__waveform-scale', {
              'audio-player__waveform-scale_active': getIsActive(i)
            })}
            style={{height: `${item}%`}}
          />
        </div>
      ))}
    </div>
  );
};

export default Waveform;
