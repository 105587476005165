import {useTranslation} from 'react-i18next';

const useConvertBytes = () => {
  const {i18n, t} = useTranslation();
  const sizes = [t`B`, t`KB`, t`MB`, t`GB`, t`TB`, t`PB`, t`EB`, t`ZB`, t`YB`];

  const convertBytes = (bytes: number, k: 1000 | 1024 = 1024) => {
    if (!bytes) return t`0 bytes`;

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const fd = i > 1 ? 1 : 0;
    const number = parseFloat((bytes / Math.pow(k, i)).toFixed(fd));

    return `${number.toLocaleString(i18n.resolvedLanguage)} ${sizes[i]}`;
  };

  return convertBytes;
};

export default useConvertBytes;
