import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {PageBody, Placeholder, Preloader} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {useRequest} from 'common/hooks';

import TariffList from './tariff-list/tariff-list';

const Plans = () => {
  const {setTariffs, tariffs, user} = useWorkspaceContext();

  const {i18n, t} = useTranslation();
  const {fetchTariffs} = useRequest();

  useEffect(() => {
    document.title = `${t`Rate plans`} - Whatcrm Web`;
  }, []);

  const getCurrency = () => {
    const {integration} = user;

    return i18n.resolvedLanguage == 'ru' && integration.country != 'UA'
      ? 'RUB'
      : 'USD';
  };

  const getTariffList = async () => {
    const {integration} = user;

    const currency = getCurrency();

    const tariffs = await fetchTariffs(
      integration.type,
      integration.domain,
      currency
    );

    setTariffs(tariffs);
  };

  useEffect(() => {
    getTariffList();
  }, []);

  return (
    <>
      {typeof tariffs == 'object' ? (
        tariffs ? (
          <TariffList tariffs={tariffs} />
        ) : (
          <PageBody>
            <Placeholder title={t`No rate plans`} isMaxHeight />
          </PageBody>
        )
      ) : (
        <PageBody>
          <Preloader isMaxHeight />
        </PageBody>
      )}
    </>
  );
};

export default Plans;
