import React from 'react';
import moment from 'moment';

import {Text} from 'components';
import {useDialogContext} from '../../dialog-context';
import {getDateDepth} from 'common/actions';

const Datetime = () => {
  const {dialog} = useDialogContext();

  const getLastMessageTimestamp = () => {
    if (dialog.version == 'avito') {
      const {last_message} = dialog;
      return last_message.created;
    } else if (dialog.version == 'chat') {
      const {last_message} = dialog;
      return moment(last_message.timestamp).unix();
    } else if (dialog.version == 'telegram') {
      const {lastMessage} = dialog;
      return lastMessage?.date || null;
    } else if (dialog.version == 'whatcrm') {
      const {lastMessage} = dialog;
      return lastMessage?.timestamp || null;
    }

    return null;
  };

  const lastMessageTimestamp = getLastMessageTimestamp();

  const datetime = lastMessageTimestamp
    ? moment.unix(lastMessageTimestamp)
    : undefined;

  const depth = lastMessageTimestamp
    ? getDateDepth(lastMessageTimestamp)
    : undefined;

  return (
    <>
      {datetime && (
        <Text className="dialog__datetime" color="black" size={12} isNoWrap>
          {depth == 'day'
            ? datetime.format('LT')
            : depth == 'week'
              ? datetime.format('dd')
              : depth == 'year'
                ? datetime.format('L').slice(0, 5)
                : datetime.format('L')}
        </Text>
      )}
    </>
  );
};

export default Datetime;
