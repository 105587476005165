import React from 'react';

import {Icons} from 'components';

interface Button {
  isLoading: boolean;
  isPlaying: boolean;
  onClick: () => void;
}

const Button = ({isLoading, isPlaying, onClick}: Button) => (
  <button
    className="audio-player__btn"
    disabled={isLoading}
    type="button"
    onClick={onClick}
  >
    {isPlaying ? <Icons.Pause color="white" /> : <Icons.Play color="white" />}
  </button>
);

export default Button;
