import React, {useContext} from 'react';

interface ConnectorContext {
  getStatus: (arg?: 1) => Promise<void>;
}

const ConnectorContext = React.createContext<ConnectorContext>(
  {} as ConnectorContext
);

export const useConnectorContext = () => useContext(ConnectorContext);
export default ConnectorContext;
