import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title} from 'components';
import {useInstanceContext} from '../../instance-context';
import {useInstancePaymentStatus} from '../hooks';

const PaymentStatus = () => {
  const {instance} = useInstanceContext();

  const {t} = useTranslation();
  const instancePaymentStatus = useInstancePaymentStatus();

  const {chatInfo} = instance;

  const statuses = {
    paid: t`Paid`,
    premium: t`Premium`,
    trial: t`Trial`,
    unpaid: t`Unpaid`
  };

  return (
    <Title.H3 isNoWrap isUppercase>
      {instancePaymentStatus && (
        <>
          {instancePaymentStatus == 'paid'
            ? chatInfo?.tariff_plane
            : statuses[instancePaymentStatus]}
        </>
      )}
    </Title.H3>
  );
};

export default PaymentStatus;
