interface UrlParams {
  chat_id?: string;
  code?: string;
  crm?: string;
  domain?: string;
  email?: string;
  key?: string;
  phone_numbers?: string;
  theme?: string;
  user?: string;
  usernames?: string;
  version?: string;
}

const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams: UrlParams = Object.fromEntries(urlSearchParams.entries());

  return urlParams;
};

export default getUrlParams;
