import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  Preloader,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import * as AppEnv from 'app-env';

import {Attach} from './template-edit-env';
import Attachs from './attachs/attachs';
import Message from './message/message';
import TitleField from './title/title';

interface Template {
  message: string;
  title: string;
  files?: AppEnv.TemplateFile[];
}

interface TemplateEditValues extends Omit<Template, 'files'> {
  attachs: Attach[];
}

interface TemplateEdit {
  isActive: boolean;
  onSubmit: (values: TemplateEditValues) => Promise<boolean>;
  setIsActive: AppEnv.SetState<boolean>;
  template?: Template;
}

const TemplateEdit = ({
  isActive,
  onSubmit,
  setIsActive,
  template
}: TemplateEdit) => {
  const {t} = useTranslation();

  const getInitialValues = (): TemplateEditValues | null => {
    if (!template) return {attachs: [], message: '', title: ''};
    else if (!template.files) return null;

    return {
      ...template,
      attachs: template.files?.map(file => ({templateFile: file})) || []
    };
  };

  const initialValues = getInitialValues();

  const validationSchema = yup
    .object()
    .shape({title: yup.string().trim().required()});

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          <Form>
            <PopupWrapper>
              <Wrapper direction="column" gap={16}>
                <Title.H3>{t`Template`}</Title.H3>
                <TitleField />
                <Message />
                <Attachs />
              </Wrapper>
            </PopupWrapper>

            <PopupFooter>
              <Wrapper gap={12}>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="fade" onClick={() => setIsActive(false)}>
                  {t`Close`}
                </Button>
              </Wrapper>
            </PopupFooter>
          </Form>
        </Formik>
      ) : (
        <>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`Template`}</Title.H3>
            <Preloader />
          </PopupWrapper>

          <PopupFooter>
            <Button color="fade" onClick={() => setIsActive(false)}>
              {t`Close`}
            </Button>
          </PopupFooter>
        </>
      )}
    </Popup>
  );
};

export {type TemplateEditValues};
export default TemplateEdit;
