import React from 'react';

import {Text} from 'components';
import {useInstanceContext} from '../../instance-context';

const Id = () => {
  const {instance} = useInstanceContext();

  return (
    <Text>
      {'ID'}
      {': '}
      {instance.id}
    </Text>
  );
};

export default Id;
