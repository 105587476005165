import React from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {
  getDateDepth,
  getIsMessageOutgoing,
  getMessageTimestamp
} from 'common/actions';
import {SystemMsg} from '../components';
import {useChatContext} from '../../chat-context';
import * as AppEnv from 'app-env';

import Message from './message/message';
import ThreadСontext from './thread-context';

interface Thread {
  index: number;
  msg: AppEnv.Message;
}

const Thread = ({index, msg}: Thread) => {
  const {dialog} = useChatContext();
  const {t} = useTranslation();

  const timestamp = getMessageTimestamp(msg);
  const date = timestamp ? moment.unix(timestamp).format('L') : undefined;
  const depth = timestamp ? getDateDepth(timestamp) : undefined;
  const isMsgOut = getIsMessageOutgoing(dialog, msg);
  const nextMsg = dialog.messages?.[index + 1];
  const isNextMsgOut = nextMsg ? getIsMessageOutgoing(dialog, nextMsg) : false;
  const nextMsgTimestamp = nextMsg ? getMessageTimestamp(nextMsg) : undefined;

  const nextMsgTime = nextMsgTimestamp
    ? moment.unix(nextMsgTimestamp).format('LT')
    : undefined;

  const time = timestamp ? moment.unix(timestamp).format('LT') : undefined;
  const isThread = nextMsgTime == time && isMsgOut == isNextMsgOut;

  const prevMsg = dialog.messages?.[index - 1];
  const prevMsgTimestamp = prevMsg ? getMessageTimestamp(prevMsg) : undefined;
  const prevMsgDate = moment.unix(prevMsgTimestamp || 0).format('L');

  return (
    <ThreadСontext.Provider value={{isThread}}>
      {date != prevMsgDate && (
        <SystemMsg>
          {depth == 'day'
            ? t`Today`
            : timestamp && moment.unix(timestamp).format('LL')}
        </SystemMsg>
      )}

      <Message message={msg} />
    </ThreadСontext.Provider>
  );
};

export default Thread;
