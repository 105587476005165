import React, {useContext} from 'react';

interface ThreadContext {
  isThread: boolean;
}

const ThreadContext = React.createContext<ThreadContext>({} as ThreadContext);

export const useThreadContext = () => useContext(ThreadContext);
export default ThreadContext;
