import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface WebSocketProviderContext {
  dialogs: AppEnv.Dialogs;
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>;
}

const WebSocketProviderContext = React.createContext<WebSocketProviderContext>(
  {} as WebSocketProviderContext
);

export const useWebSocketProviderContext = () =>
  useContext(WebSocketProviderContext);

export default WebSocketProviderContext;
