import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title, Wrapper} from 'components';

import Timezone from './timezone/timezone';
import UnavailableText from './unavailable-text/unavailable-text';
import WorkingHours from './working-hours/working-hours';

const WorkSchedule = () => {
  const {t} = useTranslation();

  return (
    <div style={{maxWidth: '100%'}}>
      <Title.H3 style={{marginBottom: 8}}>{t`Chat work schedule`}</Title.H3>

      <Wrapper direction="column" gap={16}>
        <Timezone />
        <WorkingHours />
        <UnavailableText />
      </Wrapper>
    </div>
  );
};

export default WorkSchedule;
