import React from 'react';

import {Text} from 'components';
// eslint-disable-next-line
const {version} = require('../../../../../package.json');

const Version = () => (
  <div className="sidebar__version">
    <Text color="secondary" size={12} textAlign="center">
      {version}
    </Text>
  </div>
);

export default Version;
