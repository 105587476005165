import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title, Wrapper} from 'components';
import Color from './color/color';

const Colors = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Colors`}</Title.H3>

      <Wrapper gap={8}>
        <Color name="primary" />
        <Color name="secondary" />
      </Wrapper>
    </div>
  );
};

export default Colors;
