import React from 'react';
import {Icons} from 'components';

interface Closer {
  onClick: () => void;
}

const Closer = ({onClick}: Closer) => (
  <button className="profile__closer" onClick={onClick}>
    <Icons.Close />
  </button>
);

export default Closer;
