import {useField} from 'formik';

import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {getSizeLimit} from 'common/actions';
import * as AppEnv from 'app-env';

const useHandleFiles = () => {
  const {instance} = useDispatchContext();
  const [, , helpers] = useField<AppEnv.Attach[]>('attachs');

  const getIsValid = (file: File) => {
    const limit = getSizeLimit(instance, file);
    return file.size > 0 && file.size <= limit;
  };

  const getSrc = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onerror = reject;

      fileReader.onloadend = () =>
        resolve(typeof fileReader.result == 'string' ? fileReader.result : '');
    });

  const handleFiles = async (values: File[] | null) => {
    if (!values) return helpers.setValue([]);

    const promises: Promise<AppEnv.Attach>[] = values.map(async item => {
      const src =
        item.type.includes('image') || item.type.includes('video')
          ? await getSrc(item)
          : '';

      return {
        file: item,
        isValid: getIsValid(item),
        src,
        type: item.type
      };
    });

    const res = await Promise.all(promises);
    helpers.setValue(res);
  };

  return handleFiles;
};

export default useHandleFiles;
