import moment from 'moment';
import * as AppEnv from 'app-env';

const getDate = (quantity: AppEnv.TariffQuantity) => {
  const date = new Date();

  if (quantity != 30) return date.setMonth(date.getMonth() + quantity);
  return date.setDate(date.getDate() + 30);
};

const getNextPaymentDate = (quantity: AppEnv.TariffQuantity) => {
  const date = getDate(quantity);
  return moment(date).format('L');
};

export {getNextPaymentDate};
