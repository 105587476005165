import {useEffect} from 'react';

import {getIsDialogBlacklisted} from 'common/actions';
import * as AppEnv from 'app-env';

const useBlacklist = (
  user: AppEnv.User,
  dialogs: AppEnv.Dialogs | undefined,
  setDialogs: AppEnv.SetState<AppEnv.Dialogs | undefined>
) => {
  if (!('blacklist' in user)) return;

  useEffect(() => {
    if (!dialogs) return;

    const filter = (dialog: AppEnv.Dialog) =>
      !getIsDialogBlacklisted(user, dialog);

    const map = ([key, dialogList]: [string, AppEnv.Dialog[]]) => [
      key,
      dialogList.filter(filter)
    ];

    const newDialogs = Object.fromEntries(Object.entries(dialogs).map(map));
    setDialogs(newDialogs);
  }, [user.blacklist]);
};

export default useBlacklist;
