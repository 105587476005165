import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import {normalizePhoneNumber} from 'whatcrm-core';

import {Input} from 'components';

const Phone = () => {
  const [field, meta, helpers] = useField<string>('phone');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      placeholder={t`Phone number`}
      type="tel"
      autoFocus
      isValid={isValid}
      onChange={value => helpers.setValue(normalizePhoneNumber(value))}
    />
  );
};

export default Phone;
