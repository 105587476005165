import {useState, useEffect /* useRef */} from 'react';

import {useDispatchContext} from '../../dispatch-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useTelegramUser = (dialog: AppEnv.Dialog) => {
  const {instance} = useDispatchContext();
  const [telegramUser, setTelegramUser] =
    useState<AppEnv.TelegramUser | null>();

  const {fetchUser} = useRequest();
  // const timer = useRef<NodeJS.Timeout>();

  const getTelegramUser = async () => {
    if (typeof dialog.id != 'number' || dialog.id < 0) return;

    const res = await fetchUser(instance.chat_key, dialog.id, true);
    setTelegramUser(res);

    // if (res) timer.current = setTimeout(() => getTelegramUser(), 5 * 60 * 1000);
  };

  useEffect(() => {
    getTelegramUser();

    // return () => {
    //   clearTimeout(timer.current);
    // };
  }, []);

  return telegramUser;
};

export default useTelegramUser;
