import React from 'react';
import {Wrapper} from 'components';

import Callback from './callback/callback';
import Chat from './chat/chat';
import Colors from './colors/colors';
import Icon from './icon/icon';
import IsCallback from './is-callback/is-callback';
import IsChat from './is-chat/is-chat';
import IsPulse from './is-pulse/is-pulse';
import Position from './position/position';
import PositionMobile from './position-mobile/position-mobile';
import Socials from './socials/socials';
import Theme from './theme/theme';
import Type from './type/type';

const Appearance = () => (
  <Wrapper direction="column" gap={16}>
    <Type />
    <Icon />
    <IsPulse />
    <Position />
    <PositionMobile />
    <Socials />
    <IsCallback />
    <Callback />
    <IsChat />
    <Chat />
    <Theme />
    <Colors />
  </Wrapper>
);

export default Appearance;
