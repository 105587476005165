import React from 'react';
import {useField} from 'formik';
import update from 'immutability-helper';

import {ColorPicker} from 'components';
import * as AppEnv from 'app-env';

interface Color {
  name: 'primary' | 'secondary';
}

const Color = ({name}: Color) => {
  const [field, , helpers] = useField<AppEnv.WidgetColors>('colors');

  const handleChange = (value: string) => {
    const newValue = update(field.value, {[name]: {$set: value}});
    helpers.setValue(newValue);
  };

  const {value} = field;

  return (
    <ColorPicker name={name} onChange={handleChange} value={value[name]} />
  );
};

export default Color;
