import React, {useRef} from 'react';

import {Text, Wrapper} from 'components';
import ProfileImage, {
  useProfileImage
} from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {
  getDialogId,
  getDialogName,
  getDialogProfileImage
} from 'common/actions';
import * as AppEnv from 'app-env';

interface Dialog {
  dialog: AppEnv.Dialog;
}

const Dialog = ({dialog}: Dialog) => {
  const {instance} = useDispatchContext();

  const ref = useRef<HTMLDivElement>(null);
  useProfileImage(dialog, ref);

  const id = getDialogId(dialog);
  const name = getDialogName(instance, dialog);
  const profileImage = getDialogProfileImage(instance, dialog);

  return (
    <Wrapper alignItems="center" gap={12} ref={ref} noWrap>
      <ProfileImage id={id} name={name} src={profileImage} />
      <Text fontWeight={600}>{name}</Text>
    </Wrapper>
  );
};

export default Dialog;
