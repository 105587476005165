import {useEffect, useRef, useState} from 'react';
import {IMessageEvent, w3cwebsocket} from 'websocket';

import {useReconnectWs} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getMessageEventData} from 'common/actions';

import useHandleConnected from './use-handle-connected/use-handle-connected';
import useHandleQrCode from './use-handle-qr-code/use-handle-qr-code';
import useUpdateInstanceState from './use-update-instance-state/use-update-instance-state';

import {Event} from './use-events-ws-env';

interface Data {
  chat_key: string;
  events: Event[];
}

const useWs = () => {
  const {user} = useWorkspaceContext();

  const [channels, setChannels] = useState<string[]>([]);

  const handleConnected = useHandleConnected();
  const handleQrCode = useHandleQrCode();
  const updateInstanceState = useUpdateInstanceState();
  const webSocket = useRef<w3cwebsocket | null>(null);

  const handleMessage = (message: IMessageEvent) => {
    const data = getMessageEventData<Data>(message);
    if (!data) return;

    const instanceList = Object.values(user.instances);

    const instance = instanceList.find(
      instance => instance.chat_key == data.chat_key
    );

    if (!instance) return;
    const [event] = data.events;

    if (
      ['CHAT_FREE', 'LOGOUT', 'NAVIGATION', 'PENDING', 'REBOOT'].includes(
        event.name
      )
    )
      updateInstanceState(instance, 'PENDING', undefined);
    else if (['CONNECTED', 'READY'].includes(event.name))
      handleConnected(instance, event);
    else if (event.name == 'LOADING_SCREEN')
      updateInstanceState(instance, 'LOADING_SCREEN', {percent: event.percent});
    else if (event.name == 'QR_CODE') handleQrCode(instance, event);
    else if (event.name == 'WAIT_CODE')
      updateInstanceState(instance, 'authorizationStateWaitCode', {
        codeInfo: event.codeInfo
      });
    else if (event.name == 'WAIT_PASSWORD')
      updateInstanceState(instance, 'authorizationStateWaitPassword', {
        authorizationStateWaitPassword: event.authorizationStateWaitPassword
      });
  };

  const updateChannels = () => {
    const add: string[] = [];
    const instanceList = Object.values(user.instances);
    const newChannels = [...channels];
    const remove: string[] = [];

    instanceList.forEach(instance => {
      if (newChannels.includes(instance.chat_key)) return;

      add.push(instance.chat_key);
      newChannels.push(instance.chat_key);
    });

    newChannels.forEach((channel, i) => {
      if (instanceList.find(instance => instance.chat_key == channel)) return;

      remove.push(channel);
      newChannels.splice(i, 1);
    });

    setChannels(newChannels);

    if (add.length)
      webSocket.current?.send(JSON.stringify({chat_key: add, type: 'add'}));

    if (remove.length)
      webSocket.current?.send(
        JSON.stringify({chat_key: remove, type: 'remove'})
      );
  };

  const connectWs = () => {
    try {
      webSocket.current = new w3cwebsocket('wss://events.whatcrm.net');
      webSocket.current.binaryType = 'arraybuffer';
      webSocket.current.onclose = reconnectWs;
      webSocket.current.onmessage = handleMessage;
      webSocket.current.onopen = updateChannels;
    } catch {
      //
    }
  };

  useEffect(() => {
    updateChannels();

    if (!webSocket.current) return;
    webSocket.current.onmessage = handleMessage;
  }, [Object.values(user.instances).length]);

  useEffect(() => {
    connectWs();

    return () => {
      webSocket.current?.close();
    };
  }, []);

  const reconnectWs = useReconnectWs(connectWs);
};

export default useWs;
