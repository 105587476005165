import React, {useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from 'whatcrm-core';
import cn from 'classnames';

import {useScrollClassName} from 'common/hooks';
import * as AppEnv from 'app-env';

import Account from './account/account';
import Actions from './actions/actions';
import Closer from './closer/closer';
import Domain from './domain/domain';
import List from './list/list';
import Participants from './participants/participants';

interface Profile {
  setIsProfileActive: AppEnv.SetState<boolean>;
}

const Profile = ({setIsProfileActive}: Profile) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsActive(false);
    setTimeout(() => setIsProfileActive(false), 150);
  };

  useOnClickOutside(ref, {onClick: handleClose});

  useEffect(() => {
    setIsActive(true);
  }, []);

  const scrollClassName = useScrollClassName();

  return (
    <div
      className={cn('profile', {
        [scrollClassName]: scrollClassName,
        profile_active: isActive
      })}
      ref={ref}
    >
      <Closer onClick={handleClose} />
      <Account />
      <Actions />
      <Domain />
      <List />
      <Participants />
    </div>
  );
};

export default Profile;
