import React from 'react';

import {AudioPlayer} from 'components';
import {useAvitoMessageContext} from '../avito-message-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {UserMessage} from '../../components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const Voice = () => {
  const {updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {message} = useAvitoMessageContext();

  const {fetchVoiceInfo} = useRequest();

  const handleClick = async () => {
    const {voice} = message.content;
    if (!voice) return;

    const res = await fetchVoiceInfo(instance.chat_key, voice.voice_id);
    if (!res) return;

    updateMessageBody(message.id, res.voice_link);
  };

  return (
    <UserMessage>
      <AudioPlayer onClick={handleClick} src={message.body} />
    </UserMessage>
  );
};

export default Voice;
