import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';
import {useInstanceContext} from '../../../instance-context';

const Password = () => {
  const {instance} = useInstanceContext();

  const [field, meta, helpers] = useField<string>('password');
  const {t} = useTranslation();

  const {authorizationStateWaitPassword} = instance.condition || {};
  const isValid = !(meta.error && meta.touched);

  return (
    <Input
      {...field}
      placeholder={authorizationStateWaitPassword?.passwordHint || t`Password`}
      type="password"
      autoFocus
      isValid={isValid}
      onChange={helpers.setValue}
    />
  );
};

export default Password;
