import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {ChipsAlt, ChipsAltOption, Title} from 'components';
import * as AppEnv from 'app-env';

const Type = () => {
  const [field, , helpers] = useField<AppEnv.WidgetType>('type');
  const {t} = useTranslation();

  const options: ChipsAltOption<AppEnv.WidgetType>[] = [
    {img: '/static/widget/type/radial.svg', value: 'radial'},
    {img: '/static/widget/type/vertical.svg', value: 'vertical'},
    {img: '/static/widget/type/horizontal.svg', value: 'horizontal'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Button type`}</Title.H3>
      <ChipsAlt {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default Type;
