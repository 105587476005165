import React, {useEffect} from 'react';
import {useField} from 'formik';

import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Phone from './phone/phone';
import Type from './type/type';
import Username from './username/username';

const Search = () => {
  const {activeInstances} = useWorkspaceContext();
  const [{value: instanceId}] = useField<number | undefined>('instanceId');

  const [{value: type}, , {setValue: setType}] = useField<'phone' | 'username'>(
    'type'
  );

  const instance = activeInstances.find(item => item.id == instanceId);

  useEffect(() => {
    if (instance?.version == 'whatcrm') setType('phone');
  }, [instance?.version]);

  return (
    <>
      {instance?.version == 'telegram' && <Type />}
      {type == 'phone' ? <Phone /> : type == 'username' && <Username />}
    </>
  );
};

export default Search;
