import React, {useEffect, useRef, useState} from 'react';
import {Story} from 'react-insta-stories/dist/interfaces';
import {useOnClickOutside} from 'whatcrm-core';
import cn from 'classnames';
import ReactInstaStories from 'react-insta-stories';

import {Icons} from 'components';
import * as AppEnv from 'app-env';

interface Stories {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
  stories: Story[];
}

const Stories = ({isActive, setIsActive, stories}: Stories) => {
  const [isStoriesActive, setIsStoriesActive] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, {onClick: () => setIsActive(false)});

  useEffect(() => {
    if (isActive) return setIsStoriesActive(true);
    setTimeout(() => setIsStoriesActive(false), 150);
  }, [isActive]);

  return (
    <>
      {isStoriesActive && (
        <div
          className={cn('stories', {stories_active: isActive})}
          onContextMenu={e => e.stopPropagation()}
        >
          <div className="stories__modal" ref={ref}>
            <ReactInstaStories
              height="100%"
              loader={<Icons.CircleNotch color="white" />}
              onAllStoriesEnd={() => setIsActive(false)}
              stories={stories}
              width="100%"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Stories;
