import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Fuse from 'fuse.js';

import {Placeholder, Preloader} from 'components';
import {
  useGetFolderDialogList,
  useScrollClassName,
  useSlice
} from 'common/hooks';
import {useDispatchContext} from '../../dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getUnreadDialogList, sortDialogs} from 'common/actions';
import * as AppEnv from 'app-env';

import Dialog from './dialog/dialog';

interface DialogList {
  search: string;
  isUnreadFilter: boolean;
}

const DialogList = ({search, isUnreadFilter}: DialogList) => {
  const {user} = useWorkspaceContext();
  const {dialogs, folder, instance} = useDispatchContext();

  const [dialogList, setDialogList] = useState<AppEnv.Dialog[]>();

  const {t} = useTranslation();
  const getFolderDialogList = useGetFolderDialogList();
  const ref = useRef<HTMLDivElement>(null);
  const [slice, setSlice] = useSlice(dialogList, ref);

  useEffect(() => {
    let fusedDialogList = getFolderDialogList(
      dialogs[instance.id] || [],
      folder
    );

    if (isUnreadFilter) fusedDialogList = getUnreadDialogList(fusedDialogList);

    const fuse = new Fuse(fusedDialogList, {
      keys: ['id.user', 'id', 'name'],
      threshold: 0.25
    });

    if (search) fusedDialogList = fuse.search(search).map(item => item.item);
    fusedDialogList.sort(sortDialogs);
    setDialogList(fusedDialogList);
  }, [dialogs, folder, instance, isUnreadFilter, search, user]);

  useEffect(() => {
    if (dialogList?.length) ref.current?.scrollTo(0, 0);
  }, [dialogList?.length]);

  useEffect(() => {
    setSlice(16);
  }, [instance.id]);

  const scrollClassName = useScrollClassName();

  const isLoaded = !!(dialogs && dialogList);

  return (
    <div
      ref={ref}
      className={cn('dialogs__list', {[scrollClassName]: scrollClassName})}
    >
      {isLoaded ? (
        dialogList.length ? (
          dialogList
            .slice(0, slice)
            .map(item => (
              <Dialog
                key={item.version == 'whatcrm' ? item.id._serialized : item.id}
                dialog={item}
              />
            ))
        ) : (
          <Placeholder title={t`No chats`} isMaxHeight />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </div>
  );
};

export default DialogList;
