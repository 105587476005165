import React from 'react';

interface PageFooter {
  children: React.ReactNode;
}

const PageFooter = ({children}: PageFooter) => (
  <div className="page__footer">{children}</div>
);

export default PageFooter;
