import {getIsInstanceActive} from 'common/actions';
import * as AppEnv from 'app-env';

const useActiveInstances = (user: AppEnv.User) => {
  const activeInstances = Object.values(user.instances).filter(instance =>
    getIsInstanceActive(user, instance)
  );

  return activeInstances;
};

export default useActiveInstances;
