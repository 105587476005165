import React from 'react';
import {useField, useFormikContext} from 'formik';

import {RadioAlt} from 'components';
import {useSlice} from 'common/hooks';
import {getDialogId} from 'common/actions';

import Dialog from './dialog/dialog';
import {useFusedDialogList} from './hooks';

interface ChatId {
  popupRef: React.RefObject<HTMLDivElement>;
  search: string;
}

const ChatId = ({popupRef, search}: ChatId) => {
  const {submitForm} = useFormikContext();

  const [field, , helpers] = useField<string>('chatId');
  const fusedDialogList = useFusedDialogList(search);
  const [slice] = useSlice(fusedDialogList, popupRef);

  const options = fusedDialogList.slice(0, slice).map(item => {
    const id = getDialogId(item, true);

    return {
      label: <Dialog dialog={item} />,
      value: id?.toString() || ''
    };
  });

  return (
    <RadioAlt
      {...field}
      options={options || []}
      onChange={helpers.setValue}
      onSubmit={submitForm}
    />
  );
};

export default ChatId;
