import {useChatContext} from '../chat-context';

const useIsOnline = () => {
  const {dialog, telegramUser} = useChatContext();

  const {guest = undefined} = dialog.version == 'chat' ? dialog : {};
  const {status} = telegramUser || {};

  if (guest?.status == 'online' || status?._ == 'userStatusOnline') return true;
  return false;
};

export {useIsOnline};
