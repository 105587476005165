import React, {useRef} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Button, Icons, Input, Text, Title} from 'components';

interface Button {
  body: string;
}

const Buttons = () => {
  const [field, , helpers] = useField<Button[]>('buttons');
  const {t} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleChange = (input: string, index: number) => {
    const value = update(field.value, {[index]: {body: {$set: input}}});
    helpers.setValue(value);
  };

  const handleClear = (index: number) => {
    const value = update(field.value, {$splice: [[index, 1]]});
    helpers.setValue(value);
  };

  const handleClick = () => {
    const value = update(field.value, {$push: [{body: ''}]});
    helpers.setValue(value);

    setTimeout(() => {
      const input = ref.current?.querySelector('.input:last-of-type');

      const inputField = input?.querySelector('.input__field') as
        | HTMLDivElement
        | null
        | undefined;

      inputField?.focus();
    });
  };

  const count = 12 - field.value.length;

  return (
    <div>
      <Title.H4 style={{marginBottom: 4, paddingLeft: 12}} isUppercase>
        {t`Answer options`}
      </Title.H4>

      <div className="poll" ref={ref} style={{marginBottom: 8}}>
        {field.value.map((item, i) => (
          <Input
            color="gray"
            key={i}
            name={`${field.name}-${i}`}
            placeholder={t`Type an answer`}
            value={item.body}
            isAltClearButton={field.value.length > 2}
            isSmall
            onChange={value => handleChange(value, i)}
            onClear={() => handleClear(i)}
          />
        ))}
      </div>

      <Button
        color="dashed"
        style={{marginBottom: 8}}
        isDisabled={!count}
        isMaxWidth
        onClick={handleClick}
      >
        {t`Add answer`}
        <Icons.Plus />
      </Button>

      <Text color="secondary" size={12} style={{paddingLeft: 12}}>
        {t('{{count}} more options can be added', {count})}
      </Text>
    </div>
  );
};

export default Buttons;
