import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Wrapper
} from 'components';
import {Label as InstanceLabel} from '../../components';
import {useAppContext} from 'app-context';
import {useGetInstanceLabel, useRequest} from 'common/hooks';
import {useInstanceContext} from '../../instance-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Label from './label/label';
import Webhook from './webhook/webhook';

interface Values {
  label: string;
  webhook: string;
}

interface Editor {
  isEditActive: boolean;
  setIsEditActive: AppEnv.SetState<boolean>;
}

const Editor = ({isEditActive, setIsEditActive}: Editor) => {
  const {setNotification} = useAppContext();
  const {user, setUser} = useWorkspaceContext();
  const {instance} = useInstanceContext();

  const {postChatUpdate} = useRequest();
  const {t} = useTranslation();
  const getInstanceLabel = useGetInstanceLabel();

  const handleSubmit = async (values: Values) => {
    const res = await postChatUpdate(instance.chat_key, values);
    if (!res) return true;

    setUser(prevValue =>
      update(prevValue, {
        instances: {
          [instance.id]: {
            chatInfo: {webhook: {$set: res.webhook || ''}},
            label: {$set: res.label || ''}
          }
        }
      })
    );

    setIsEditActive(false);

    const title = getInstanceLabel(instance);
    setNotification({title, text: t`The settings have been saved.`});

    return true;
  };

  const {chatInfo} = instance;
  const {integration} = user;

  const initialValues: Values = {
    label: instance.label,
    webhook: chatInfo?.webhook || ''
  };

  return (
    <Popup
      isActive={isEditActive}
      onClick={() => setIsEditActive(false)}
      width={408}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <InstanceLabel style={{marginBottom: 12}} />

            <Wrapper direction="column" gap={16}>
              <Label />
              {integration.widget_code && <Webhook />}
            </Wrapper>
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="fade" onClick={() => setIsEditActive(false)}>
                {t`Close`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
