import React from 'react';
import {useTranslation} from 'react-i18next';

import {MessageText, UserMessage} from '../../components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {Wrapper} from 'components';

import Product from './product/product';

const Order = () => {
  const {message} = useWhatsAppMessageContext();
  const {i18n, t} = useTranslation();

  const {order} = message;

  return (
    <UserMessage>
      <div className="message-order">
        <Wrapper direction="column" gap={8}>
          {order?.products.map(product => (
            <Product product={product} key={product.id} />
          ))}

          {typeof order?.total == 'number' && (
            <MessageText isBold isLight>
              {t`Total`}
              {': '}
              {(order.total / 1000).toLocaleString(i18n.resolvedLanguage, {
                currency: order.currency || undefined,
                style: order.currency ? 'currency' : undefined
              })}
            </MessageText>
          )}
        </Wrapper>
      </div>
    </UserMessage>
  );
};

export default Order;
