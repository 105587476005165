import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Checkbox} from 'components';

const IsTextTone = () => {
  const [field, , helpers] = useField<boolean>('isTextTone');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`Notification sound`}
      onChange={helpers.setValue}
    />
  );
};

export default IsTextTone;
