import React from 'react';

interface BulletList {
  options: string[];
}

const BulletList = ({options}: BulletList) => (
  <ul className="bullet-list">
    {options.map((item, i) => (
      <li key={i}>{item}</li>
    ))}
  </ul>
);

export default BulletList;
