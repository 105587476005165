import _ from 'lodash';
import update from 'immutability-helper';

import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface ChatSpareData {
  label?: string;
  name?: string;
}

const useChatSpare = () => {
  const {setUser, user} = useWorkspaceContext();
  const {postChatSpare, postChatUpdate} = useRequest();

  const chatSpare = async (
    version: AppEnv.InstanceVersion,
    data?: ChatSpareData
  ) => {
    const {integration} = user;

    const res = await postChatSpare(
      integration.type,
      integration.domain,
      version,
      data?.label
    );

    if (!res) return null;
    else if (data?.name)
      await postChatUpdate(res.chat_key, {name: data.name, phone: '9428276'});

    const chatInfo: AppEnv.ChatInfo = _.pick(res, [
      'date_pay',
      'date_subscription',
      'date_trial',
      'is_premium',
      'tariff_plane',
      'webhook'
    ]);

    const instance: AppEnv.Instance = {
      ...res,
      chatInfo,
      instanceId: res.chat_id,
      name: data?.name || res.name
    };

    setUser(prevValue =>
      update(prevValue, {instances: {[res.id]: {$set: instance}}})
    );

    return instance;
  };

  return chatSpare;
};

export {useChatSpare};
