import React, {useEffect, useRef} from 'react';
import cn from 'classnames';

export interface Option<Value> {
  label: React.ReactNode;
  value: Value;
}

interface RadioAltItem<T> {
  index: number;
  isFocus: boolean | undefined;
  onChange: (arg: T) => void;
  onSubmit: () => void;
  radioAltItem: Option<T>;
  radioAltRef: React.RefObject<HTMLDivElement | null>;
  value: T;
}

type Target = HTMLDivElement | null | undefined;

const RadioAltItem = <T extends number | string | undefined>({
  index,
  isFocus,
  onChange,
  onSubmit,
  radioAltItem,
  radioAltRef,
  value
}: RadioAltItem<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFocus && index == 0)
      setTimeout(() => {
        ref.current?.click();
        ref.current?.focus();
      }, 150);
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    let target: Target;

    if (e.code == 'ArrowDown') {
      target = ref.current?.nextElementSibling as Target;
      if (!target) target = radioAltRef.current?.firstElementChild as Target;
    } else if (e.code == 'ArrowUp') {
      target = ref.current?.previousElementSibling as Target;
      if (!target) target = radioAltRef.current?.lastElementChild as Target;
    }

    if (!target) return;

    target.click();
    target.focus();
  };

  return (
    <div
      className={cn('radio-alt__item', {
        'radio-alt__item_active': radioAltItem.value == value
      })}
      ref={ref}
      tabIndex={1}
      onClick={() => onChange(radioAltItem.value)}
      onDoubleClick={onSubmit}
      onKeyDown={handleKeyDown}
    >
      {radioAltItem.label}
    </div>
  );
};

export default RadioAltItem;
