import React, {useRef} from 'react';

import RadioAltItem, {Option} from './radio-alt-item/radio-alt-item';

interface RadioAlt<T> {
  isFocus?: boolean;
  onChange: (arg: T) => void;
  onSubmit: () => void;
  options: Option<T>[];
  style?: React.CSSProperties;
  value: T;
}

const RadioAlt = <T extends number | string | undefined>({
  isFocus,
  onChange,
  onSubmit,
  options,
  style,
  value
}: RadioAlt<T>) => {
  const radioAltRef = useRef<HTMLDivElement>(null);

  return (
    <div className="radio-alt" ref={radioAltRef} style={style}>
      {options.map((item, i) => (
        <RadioAltItem
          index={i}
          key={i}
          radioAltItem={item}
          radioAltRef={radioAltRef}
          value={value}
          isFocus={isFocus}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      ))}
    </div>
  );
};

export default RadioAlt;
