import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title} from 'components';
import {useTariffListContext} from '../../tariff-list-context';

const Total = () => {
  const {selectedTariff, total} = useTariffListContext();
  const {i18n, t} = useTranslation();

  return (
    <Title.H3>
      {t`Total`}
      {`: `}

      {total > 0 &&
        total.toLocaleString(i18n.resolvedLanguage, {
          currency: selectedTariff?.currency,
          style: selectedTariff ? 'currency' : undefined
        })}
    </Title.H3>
  );
};

export default Total;
