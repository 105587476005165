import React, {useState, useEffect} from 'react';
import _ from 'lodash';

import {Stories} from 'components';
import {useChatContext} from 'pages/workspace/pages/messenger/dispatch/chat-provider/chat/chat-context';
import {useGetMessagePreview} from 'common/hooks';
import {useGetTgRemoteFile} from '../../../hooks';
import * as AppEnv from 'app-env';
import InnerMessage from '../../inner-message/inner-message';
import MessageText from '../../message-text/message-text';

import {useQuotedMsg} from './hooks';

interface QuotedMessage {
  message: AppEnv.Message;
}

const QuotedMessage = ({message}: QuotedMessage) => {
  const {convoRef} = useChatContext();

  const [isFetching, setIsFetching] = useState(false);
  const [isStoryActive, setIsStoryActive] = useState(false);
  const [target, setTarget] = useState<HTMLDivElement>();

  const {isStory, quotedMsg} = useQuotedMsg();
  const getMessagePreview = useGetMessagePreview();
  const getTgRemoteFile = useGetTgRemoteFile();

  useEffect(() => {
    if (!quotedMsg) return;

    const el: HTMLDivElement | null = document.querySelector(
      `[data-id="${quotedMsg.id}" ]`
    );

    if (!el) return;
    setTarget(el);
  }, []);

  const handleStory = async () => {
    if (!quotedMsg) return;
    else if (!('senderId' in quotedMsg)) return;

    const {photo, video} = quotedMsg.content;

    const size = photo?.sizes.at(-1);
    const {remote: photoRemote} = size?.photo || {};
    const {remote: videoRemote} = video?.video || {};

    const id = photoRemote?.id || videoRemote?.id;
    if (!id) return;

    setIsFetching(true);
    await getTgRemoteFile(id, {isQuoted: true, msgId: +message.id});

    setIsFetching(false);
    setIsStoryActive(true);
  };

  const handleScroll = _.debounce(() => {
    convoRef.current?.removeEventListener('scroll', handleScroll);
    if (!target) return;

    target.classList.add('message_active');
    setTimeout(() => target.classList.remove('message_active'), 150);
  }, 150);

  const handleTarget = () => {
    if (!target) return;

    convoRef.current?.addEventListener('scroll', handleScroll);
    target.scrollIntoView({behavior: 'smooth', block: 'center'});
  };

  const handleClick = () => {
    if (isStory) handleStory();
    else if (target) handleTarget();
  };

  const isTg = quotedMsg && 'senderId' in quotedMsg;
  const {content = undefined} = isTg ? quotedMsg : {};
  const messagePreview = getMessagePreview(quotedMsg);

  return (
    <>
      {messagePreview && (
        <InnerMessage
          isDisabled={isFetching}
          isQuote
          onClick={isStory || target ? handleClick : undefined}
        >
          <MessageText>{messagePreview}</MessageText>
        </InnerMessage>
      )}

      {isTg && (
        <Stories
          stories={[
            {
              duration: 5000,
              type: content?.video ? 'video' : undefined,
              url: quotedMsg.body
            }
          ]}
          isActive={isStoryActive}
          setIsActive={setIsStoryActive}
        />
      )}
    </>
  );
};

export default QuotedMessage;
