import {useTranslation} from 'react-i18next';
import axios from 'axios';

import {useAppContext} from 'app-context';

interface Data {
  url: string;
}

interface Error {
  code: string;
  message: string;
}

interface Response {
  data: Data | Error;
}

const useConvert = () => {
  const {setNotification} = useAppContext();
  const {t} = useTranslation();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_CONVERT,
    headers: {
      'Content-Type': 'application/json',
      'X-Converter-Token': process.env.REACT_APP_CONVERT_TOKEN
    }
  });

  const convert = async (url: string, ext: 'mp3' | 'mp4', domain: string) => {
    const res = await instance('file', {
      data: {domain, ext, url},
      method: 'post'
    })
      .then((response: Response) => {
        if ('url' in response.data && response.data.url) return response.data;
        notify();

        return null;
      })
      .catch(() => {
        notify();
        return null;
      });

    return res;
  };

  const notify = () =>
    setNotification({
      title: t`Error`,
      text: 'Failed to convert the voice message.'
    });

  return convert;
};

export default useConvert;
